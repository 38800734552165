import { setGlobalKeyEvent } from "../store/globalKeyEvent/action";
import store from "../store/store";

export const keyDown = (e) => {
    store.dispatch(setGlobalKeyEvent(e.altKey, e.ctrlKey));
    setTimeout(function () {
        store.dispatch(setGlobalKeyEvent(false, false));
      }, 2000);
};

export const keyUp = (e) => {
    store.dispatch(setGlobalKeyEvent(false, false));
};

export const setKeyEvents = () => {
    document.addEventListener("keydown", keyDown)
    document.addEventListener("keyup", keyUp)
}