import { logger } from "../../logger";
import Connection from "../Database/connection";
import getThemesQuery from './getThemes.query';
import jwt from 'jsonwebtoken'

export const getThemes = (storeName: string,) => {
  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { user: local_connection.user }
    let connection = new Connection()
    return connection.runQuery(storeName, getThemesQuery, parameters);
  } catch (error) {
    logger.error("API getThemes error:  ", error)
  }

}

