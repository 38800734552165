const query = `
with permissions, widget, widget_map, element, id, user
  
  with permissions, widget, widget_map, element, id, user
  where widget_map.widget = 'Context'

  optional match (element)-[raises_context:raises{type: "onLoad"}]->(event_context:System:Workflow:Event)
  optional match (event_context)-[:enabledBy]->(query_enabledBy:System:Workflow:Query)
  optional match (event_context)-[:gets]->(parameter:System:Workflow:Action:Parameter)

  with
    permissions,
    widget,
    element,
    raises_context,
    event_context,
    coalesce(query_enabledBy.cypher, "return {enabled: true} as response") as cypher_enabled

  call apoc.cypher.run(cypher_enabled, {key: id(event_context), id: $id, user: $user}) yield value
  
  with
    permissions,
    widget,
    element,
    raises_context,
    event_context,
    any(permission in permissions where (permission)-[:for]->(event_context)) and apoc.convert.toBoolean(value.response.enabled) as enabled_context

  optional match (event_context)-[loads:loads]->(context:System:Workflow:Context)
  optional match (event_context)-[:runs|gets]->(action:System:Workflow:Action)
  optional match (action)-[:from]->(relationType:System:Schema:RelationType)
  optional match (action)-[:from]->(nodeType:System:Schema:NodeType)

  optional match path=(nodeType_start:System:Schema:NodeType)<-[:extends*0..]-(nodeType)
  where not (:System:Schema:NodeType)<-[:extends]-(nodeType_start)

  with
    widget,
    element,
    raises_context,
    event_context,
    loads,
    context,
    enabled_context,
    action{
      .*,
      filter: case when apoc.convert.toBoolean(apoc.text.clean(action.filter) =~ '(?i)(match|optional match|where)') then action.filter else '' end
    },
    relationType{
      id: id(relationType),
      name: relationType.name,
      cypher: case when action.direction = "out" then "-[relation:" + relationType.name + "]->" else "<-[relation:" + relationType.name + "]-" end,
      apoc: case when action.direction = "out" then relationType.name + ">" else "<" + relationType.name end,
      direction: case when action.direction is null then "out" else action.direction end
    },
    nodeType{
      name: nodeType.name,
      labels: apoc.text.join([] + [node in nodes(path) | node.name], ':')
    }

  with
    widget,
    element,
    raises_context,
    event_context,
    loads,
    context,
    enabled_context,
    action,
    case event_context.type
      when 'count' then 'match (source) where id(source) = ' + toInteger($id) + ' optional match (source)' + relationType.cypher + '(node:' + nodeType.labels + ') ' + coalesce(action.filter, '') + ' with count(node) as count return {count: count} as response'
      when 'context' then 'return {source: {id: ' + case when $id is null then 'null' else toInteger($id) end + '}, relation: {type: "' + coalesce(relationType.name, "undefined") + '", direction: "' + coalesce(relationType.direction, "out") + '"}, target: {type: "' + nodeType.name + '", labels: ":' + nodeType.labels + '", filter: "' + coalesce(action.filter, '') + '"}} as response'
      when 'unfiltered' then 'return {source: {id: null}, relation: {type: "' + coalesce(relationType.name, "undefined") + '", direction: "' + coalesce(relationType.direction, "out") + '"}, target: {type: "' + nodeType.name + '", labels: ":' + nodeType.labels + '", filter: "' + coalesce(action.filter, '') + '"}} as response'
      else action.cypher
    end as query,
    coalesce(relationType.direction, "out") as direction

  optional match (context)-[:hasLabel]->(label:Label)
  optional match (label)-[:withName]->(term:Apps:Term)
  optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)
  optional match (context)-[raises_event:raises]->(event:System:Workflow:Event)
  where not raises_event.type in ["onLoad", "onClose"]

  call apoc.cypher.run(query, {id: $id, user: $user}) yield value

  with distinct
    widget,
    context,
    context{
      key: id(context),
      label: coalesce(case when not term is null then label.ID else label.name end, ""),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      name: context.name,
      direction: direction,
      sequence: coalesce(toInteger(raises_context.sequence), 100),
      query: query,
      count: value.response.count,
      disabled: not enabled_context,
      events: collect(distinct event{
        key: id(event),
        type: raises_event.type
      })
    } as context_map
  
  optional match (context)-[:raises{type: "onLoad"}]->(:System:Workflow:Event)-[:loads]->(menu:System:Workflow:Menu)
  optional match (menu)-[raises_button:raises{type: "onLoad"}]->(event_button:System:Workflow:Event)-[:loads]->(button:System:Workflow:Button)
  optional match (event_button)-[:enabledBy]->(query_enabledBy:System:Workflow:Query)
  
  with
    widget,
    context,
    context_map,
    menu,
    raises_button,
    button,
    event_button,
    coalesce(query_enabledBy.cypher, "return {enabled: true} as response") as cypher_enabled

  call apoc.cypher.run(cypher_enabled, {key: id(event_button), id: $id, user: $user}) yield value
  
  with
    widget,
    context,
    context_map,
    menu,
    raises_button,
    button,
    apoc.convert.toBoolean(value.response.enabled) as button_enabled

  optional match (menu)-[:hasLabel]->(label_menu:Label)
  optional match (menu)-[:hasIcon]->(icon_menu:System:UserInterface:Icon)
  optional match (label_menu)-[:withName]->(term_menu:Term)
  optional match (button)-[:hasLabel]->(label_button:Label)
  optional match (button)-[:hasIcon]->(icon:System:UserInterface:Icon)
  optional match (label_button)-[:withName]->(term_button:Term)
  
  optional match (button)-[raises_event:raises]->(event:System:Workflow:Event)
  where not raises_event.type in ["onLoad", "onClose"]

  with distinct
    widget,
    context_map,
    menu{
      key: id(menu),
      type: menu.type,
      label: coalesce(case when not term_menu is null then label_menu.ID else label_menu.name end, ""),
      icon: icon_menu{.name, .code},
      name: menu.name,
      sequence: 1,
      events: [null],
      elements: [null]
    } as menu_map,
    button{
      key: id(button),
      type: button.type,
      breadcrumb: coalesce(button.breadcrumb, "ignore"),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      label: coalesce(case when not term_button is null then label_button.ID else label_button.name end, ""),
      name: button.name,
      sequence: coalesce(toInteger(raises_button.sequence), 100),
      disabled: not button_enabled,
      events: collect(distinct event{
        key: id(event),
        type: raises_event.type,
        mode: coalesce(raises_event.mode, "load")
      }),
      elements: [null]
    } as button_map

  with distinct
    widget,
    context_map{
      .*,
      menu: menu_map{
        .*,
        elements: apoc.coll.sortMaps(collect(distinct button_map), "^sequence")
      }
    }
  
  with distinct
    widget{
      elements: apoc.coll.sortMaps(
        collect(distinct context_map),
        "^sequence"
      )
    } as widget_subquery

  return widget_subquery
`

export {query};