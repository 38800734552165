import { GET_CONTEXT_MENU_PENDING,
    GET_CONTEXT_MENU_SUCCESS, 
    GET_CONTEXT_MENU_ERROR,
    INIT_GET_CONTEXT_MENU } from './types'
import { getMenuForm } from '../../services/api/Widgets/MenuForm/getMenuForm.api'

const getContextMenuPending = () => {
  return {
    type: GET_CONTEXT_MENU_PENDING,
  }
}

const getContextMenuReceived = (data: any) => {
  return {
    type: GET_CONTEXT_MENU_SUCCESS,
    data,
  }
}

const getContextMenuError = (err: any) => {
  return {
    type: GET_CONTEXT_MENU_ERROR,
    err,
  }
}

export const initGetContextMenu = () => {
    return {
      type: INIT_GET_CONTEXT_MENU,
    }
  }

export const fetchContextMenu = (value: number) => {
  return (dispatch) => {
    dispatch(getContextMenuPending())
    getMenuForm(value)
      .then((resp) => {
        if(resp.records[0].get('contextMenu')){
          dispatch(getContextMenuReceived(resp.records[0].get('contextMenu')))
        } else {
          dispatch(getContextMenuError(null))
        }
      })
      .catch((err: any) => {
        console.error(err)
        dispatch(getContextMenuError(err))
      })
  }
}
