import { OPEN_UPLOAD_MODAL, CLOSE_UPLOAD_MODAL } from './types'
  
export const openUploadModal = () => {
    return {
      type: OPEN_UPLOAD_MODAL,
    }
}

export const closeUploadModal = () => {
    return {
      type: CLOSE_UPLOAD_MODAL
    }
}