import * as React from 'react';
import './styles.css'
import { connect } from 'react-redux';
import { withRouterHooks } from '../../navigation/router.hooks';
import jwt from 'jsonwebtoken';
import fetchTreeGridData from './services/fetchTreeGridData';
import MenuForm from '../../components/MenuForm/MenuForm';
import { run as runActions } from '../../store/actions/run';
/* syncfusion imports*/
import {
  GroupSettingsModel,
  Edit,
  Group,
  Selection,
  CommandColumn,
  RowSelectEventArgs
} from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  Resize,
  RowDD,
  Sort,
  TreeGridComponent,
  Toolbar,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective
} from '@syncfusion/ej2-react-treegrid';
import { withTranslation } from 'react-i18next';



class TreeGridWidget extends React.Component<any, any>{

  constructor(props) {
    super(props);

    this.state = {
      treeData_data: null,
      treeData_pending: false,
      treeData_success: false,
      treeData_error: false,

      gridEdit_data: [],
      gridEdit_pending: false,
      gridEdit_success: false,
      gridEdit_error: false,

      selectedDataCheckbox: [],
      deselectElement: null,
      rowClickedData: null,
    }
  }
  ctrlKeyPressed = false;
  flagDatabound = true;
  flagClickEvent = false


  treegrid: TreeGridComponent | null;
  groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  customAttributes: any = { class: 'customcss' };
  filter: any = {
    type: 'CheckBox',
    operator: "contains"
  }
  pageOptions: PageSettingsModel = {
    pageSize: 20,
    pageSizes: ["5", "10", "20", "50"]
  };
  filterSettings: any = {
    mode: 'Immediate',
    hierarchyMode: 'Both',
    immediateModeDelay: 400,
    type: 'FilterBar',
    operator: "contains"
  }
  toolbarOptions: any = [
    'ExpandAll',
    'CollapseAll',
    { text: 'Level 1', id: '1', prefixIcon: 'e-time' },
    { text: 'Level 2', id: '2', prefixIcon: 'e-time' },
    { text: 'Level 3', id: '3', prefixIcon: 'e-time' }]


  componentDidMount() {
    const { navData, oldActionsParams, runActionsState } = this.props;

    fetchTreeGridData(this, process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams, navData)
      .then((response) => {
        console.log(
          `%c ${navData.widgetData.widget}.${navData.widgetData.type} (key: ${navData.widgetData.key}) %c responded: `,
          'background: #f7f414;',
          'background: transparent;',
          {
            params: { ...navData.params, ...runActionsState?.data?.params, id: navData.widgetData.dataID },
            position: {
              column: navData.widgetData.position.column,
              row: navData.widgetData.position.row
            },
            response: response
          }
        );
      })
  }

  componentDidUpdate(prevProps) {
    const { navData, oldActionsParams, runActionsState } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.clearData();
      fetchTreeGridData(this, process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
        .then((response) => {
          console.log(
            `%c ${navData.widgetData.widget}.${navData.widgetData.type} (key: ${navData.widgetData.key}) %c responded: `,
            'background: #f7f414;',
            'background: transparent;',
            {
              params: { ...navData.params, ...runActionsState?.data?.params, id: navData.widgetData.dataID },
              position: {
                column: navData.widgetData.position.column,
                row: navData.widgetData.position.row
              },
              response: response
            }
          );
        })
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  clearData() {
    this.setState({
      treeData_data: null,
      treeData_pending: false,
      treeData_success: false,
      treeData_error: false,
    })
  }

  rowselect(args: RowSelectEventArgs) {
    const { navData, oldActionsParams, globalKeyEvent } = this.props

    let rowInfo: any = this.treegrid.getRowInfo(args.target)
    let rowData: any = rowInfo.rowData
    let eventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key;

    if (globalKeyEvent.altKey === true) {
      this.treegrid.copy()
      return null
    }

    if (globalKeyEvent.ctrlKey === true) {
      const urlParam = {
        key: eventKey,
        id: rowData.id,
        params: { ...oldActionsParams },
        label: rowData.name
      }
      const jwtParams = jwt.sign(urlParam, 'pdm');
      window.open(window.location.href + '/' + jwtParams)
      return null
    }
    if (globalKeyEvent.ctrlKey === false || globalKeyEvent.altKey === false) {
      if (!rowData || Object.keys(rowData).length === 0) return null
      runActions(eventKey, rowData?.id, { ...oldActionsParams })
    }
  }

  toolbarClick(args) {
    if (this.treegrid && args.item.text === 'Level 1') {
      this.treegrid.expandAtLevel(0)
      this.treegrid.collapseAtLevel(1)
      this.treegrid.collapseAtLevel(2)
    }
    if (this.treegrid && args.item.text === 'Level 2') {
      this.treegrid.expandAtLevel(0)
      this.treegrid.expandAtLevel(1)
      this.treegrid.collapseAtLevel(2)
    }
    if (this.treegrid && args.item.text === 'Level 3') {
      this.treegrid.expandAtLevel(0)
      this.treegrid.expandAtLevel(1)
      this.treegrid.expandAtLevel(2)
    }
  }

  initial = true

  dataBound(args: any) {
    const { navData } = this.props

    if (this.treegrid && this.initial === true) {
      if (navData.widgetData.level === '1') {
        this.treegrid.expandAtLevel(0)
      }
      if (navData.widgetData.level === '2') {
        this.treegrid.expandAtLevel(0)
        this.treegrid.expandAtLevel(1)
      }
      if (navData.widgetData.level === '3') {
        this.treegrid.expandAtLevel(0)
        this.treegrid.expandAtLevel(1)
        this.treegrid.expandAtLevel(2)
      }
      this.initial = false;
    }
    // this.treegrid.element.addEventListener("click", this.onClick.bind(this));
  }

  footerItemCounter(props) {
    return (<span>Items: {props.count}</span>);
  }

  render() {
    const { navData, oldActionsParams } = this.props
    const { treeData_data, gridEdit_data } = this.state;

    if (navData.widgetData === null || treeData_data === null) {
      return null
    }

    return (
      <div className='widget-container'>
        {/* <div className='control-section'> */}
        {
          navData.widgetData.menu
            ? <MenuForm
              key={`menu-${navData.widgetData.menu.id}`}
              menu={navData.widgetData.menu}
              currentDataID={navData.widgetData.dataID}
              properties={gridEdit_data}
              oldActionsParams={oldActionsParams}
              viewType={navData.widgetData.type}
              widgetType={navData.widgetData.widget}
            />
            : null
        }
        <h3 className='widget-label'>{this.props.t(navData.label)} </h3>
        <div className="widget-content" id="syncfusionTreeGrid">
          <TreeGridComponent
            key={`treegrid-${navData.widgetData.id}`}
            height={"100%"}
            rowHeight={28}
            ref={g => this.treegrid = g}
            dataSource={treeData_data?.data}
            dataBound={this.dataBound.bind(this)}
            idMapping={treeData_data?.options?.id}
            parentIdMapping={treeData_data?.options?.parentId}
            pageSettings={this.pageOptions}
            toolbar={this.toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={this.filterSettings}
            allowResizing={true}
            allowSelection={true}
            enableCollapseAll={navData.widgetData.level === 0 ? true : false}
            treeColumnIndex={1}
            toolbarClick={this.toolbarClick.bind(this)}
            rowSelected={this.rowselect.bind(this)}
          >
            <ColumnsDirective>
              {navData.widgetData.columns.map((column) => {
                return (
                  <ColumnDirective
                    key={`field-${column.id}`}
                    field={column.name}
                    headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                    filter={this.filter}
                    visible={column.hide === false ? true : false}
                    disableHtmlEncode={false}
                    width={column.width}
                    minWidth={column.width}
                  // maxWidth={column.maxWidth}
                  />
                )
              })}
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective showChildSummary={false}>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective columnName='ID' type='count' footerTemplate={this.footerItemCounter} />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, Aggregate]} />
          </TreeGridComponent>
        </div>
      </div>
      // </div>
    )
  }
};

const mapStateToProps = (state: any) => ({
  getState: state.getState,
  globalKeyEvent: state.keyEvent
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(TreeGridWidget)))