const query = `
unwind [
  object in apoc.coll.flatten([$objects]) |
  case apoc.meta.type(object)
    when 'NODE' then id(object)
    when 'MAP' then toInteger(object.id)
    when 'FLOAT' then toInteger(object)
    when 'INTEGER' then object
    else null
  end
] as id

match (source)-[relation]->(target)
where id(relation) = id

with distinct
  source,
  relation,
  target,
  apoc.convert.toBoolean(relation.delete) as delete

optional match path=(:System:Schema:NodeType)<-[:extends*0..]-(:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(source))

with
  source,
  relation,
  target,
  delete,
  path
order by length(path) desc

with distinct
  source,
  relation,
  target,
  delete,
  apoc.convert.toBoolean(nodes(collect(path)[-1])[-1].log) as log_source

optional match path=(:System:Schema:NodeType)<-[:extends*0..]-(:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(target))

with
  source,
  relation,
  target,
  delete,
  log_source,
  path
order by length(path) desc

with distinct
  source,
  relation,
  target,
  delete,
  log_source,
  apoc.convert.toBoolean(nodes(collect(path)[-1])[-1].log) as log_target

call apoc.do.case([
  log_source and not log_target,
  '
    with $source as source, $relation as relation, $target as target
    
    optional match (source)-[:has]->(log_last:System:Log)<-[current:current]-(source)

    delete current

    with distinct
      source,
      relation,
      target,
      apoc.map.merge(
        {
          user: $user.uuid,
          timestamp: datetime(),
          action: "link",
          type: type(relation),
          labels: ":" + apoc.text.join(labels(target), ":"),
          properties: apoc.convert.toJson(properties(relation))
        },
        apoc.map.fromPairs([[
          case when startNode(relation) = source then "target" else "source" end,
          case when startNode(relation) = source then coalesce(target.uuid, id(target)) else coalesce(source.uuid, id(source)) end
        ]])
      ) as properties,
      log_last
    
    create (source)-[:has]->(log_current:System:Log)<-[:current]-(source)
    set log_current += properties

    merge (log_current)-[:next]->(log_last)
  
    return distinct true as response
  ',
  not log_source and log_target,
  '
    with $source as source, $relation as relation, $target as target
    
    optional match (target)-[:has]->(log_last:System:Log)<-[current:current]-(target)

    delete current

    with distinct
      source,
      relation,
      target,
      apoc.map.merge(
        {
          user: $user.uuid,
          timestamp: datetime(),
          action: "link",
          type: type(relation),
          labels: ":" + apoc.text.join(labels(source), ":"),
          properties: apoc.convert.toJson(properties(relation))
        },
        apoc.map.fromPairs([[
          case when startNode(relation) = target then "target" else "source" end,
          case when startNode(relation) = target then coalesce(target.uuid, id(target)) else coalesce(source.uuid, id(source)) end
        ]])
      ) as properties,
      log_last
    
    create (target)-[:has]->(log_current:System:Log)<-[:current]-(target)
    set log_current += properties

    merge (log_current)-[:next]->(log_last)

    return distinct true as response
  ',
  log_source and log_target,
  '
    with $source as source, $relation as relation, $target as target
    
    optional match (source)-[:has]->(log_last:System:Log)<-[current:current]-(source)

    delete current

    with distinct
      source,
      relation,
      target,
      apoc.map.merge(
        {
          user: $user.uuid,
          timestamp: datetime(),
          action: "link",
          type: type(relation),
          labels: ":" + apoc.text.join(labels(target), ":"),
          properties: apoc.convert.toJson(properties(relation))
        },
        apoc.map.fromPairs([[
          case when startNode(relation) = source then "target" else "source" end,
          case when startNode(relation) = source then coalesce(target.uuid, id(target)) else coalesce(source.uuid, id(source)) end
        ]])
      ) as properties,
      log_last
    
    create (source)-[:has]->(log_current:System:Log)<-[:current]-(source)
    set log_current += properties

    merge (log_current)-[:next]->(log_last)

    with distinct
      source,
      relation,
      target

    optional match (target)-[:has]->(log_last:System:Log)<-[current:current]-(target)

    delete current

    with distinct
      source,
      relation,
      target,
      apoc.map.merge(
        {
          user: $user.uuid,
          timestamp: datetime(),
          action: "link",
          type: type(relation),
          labels: ":" + apoc.text.join(labels(source), ":"),
          properties: apoc.convert.toJson(properties(relation))
        },
        apoc.map.fromPairs([[
          case when startNode(relation) = target then "target" else "source" end,
          case when startNode(relation) = target then coalesce(target.uuid, id(target)) else coalesce(source.uuid, id(source)) end
        ]])
      ) as properties,
      log_last
    
    create (target)-[:has]->(log_current:System:Log)<-[:current]-(target)
    set log_current += properties

    merge (log_current)-[:next]->(log_last)
  
    return distinct true as response
  '],
  '
    return distinct false as response
  ',
  {
    source: source,
    relation: relation,
    target: target,
    user: $user
  }
)
yield value

return distinct
  true as response
`;

export { query };
