import * as React from "react";
import { ColorPickerComponent, ColorPickerEventArgs } from '@syncfusion/ej2-react-inputs';
import { withTranslation } from "react-i18next";

class ColorPickerField extends React.Component<any, any> {
  private colorPickerInstance: ColorPickerComponent;
  
  onChange = (args: ColorPickerEventArgs): void => {
    const { setNewValue } = this.props
    setNewValue(args.currentValue.hex)
  }

  render() {
    const { field, value, hiddenClass } = this.props

    return (
      <div className={`FormInputContainer ${hiddenClass}`}>
        <label className='FormInputLabel'>{this.props.t(field.label)}</label>
        <div className='FormInputElement '>
          <ColorPickerComponent
          ref={(scope) => { (this.colorPickerInstance as ColorPickerComponent | null) = scope; }}
            id='color-picker'
            value={ value }
            change={this.onChange.bind(this)}
            style={{ marginTop: '20px' }}
          />
        </div>
      </div>
    )
  }
}
export default (withTranslation()(ColorPickerField));