import React from "react";
import GridWidgetSync from "./Grid";
import jwt from "jsonwebtoken";
import { run as runActions } from "../../store/actions/run";
import { connect } from "react-redux";
import { withRouterHooks } from "../../navigation/router.hooks";
import { setGlobalKeyEvent } from "../../store/globalKeyEvent/action";
import { checkConnection } from "../../services/api/driver";
import {
  CommandClickEventArgs,
  RowSelectEventArgs,
  SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";
import fetchGridDataData from "./services/fetchGridData";
import { logger } from "../../services/logger";
import store from "../../store/store";

class GridWidgetView extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      gridData: null
    }
  }

  selectionSettingsCell: SelectionSettingsModel = {
    cellSelectionMode: "Box",
    mode: "Cell",
    type: "Multiple",
  };


  componentDidMount() {
    const { navData, oldActionsParams, runActionsState, $this } = this.props

    fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
      .then((response) => {
        this.setState({ gridData: response.targets })
        logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
      }
      );
  }

  componentDidUpdate(prevProps) {
    const { navData, oldActionsParams, runActionsState, $this } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.clearData();
      this.setState({ gridRenderData: navData.widgetData })
      fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
        .then((response) => {
          this.setState({ gridData: response.targets })
          logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
        })
    }
  }

  cellSelected = (args: RowSelectEventArgs) => {
    const { navData, globalKeyEvent, setGlobalKeyEvent, oldActionsParams } = this.props;

    checkConnection(localStorage.getItem("connection"))

    const rowData: any = args
    if (rowData.currentCell.classList.contains('code-cell')) return null
    const eventKey = navData.widgetData.events.find((event) => event.type === "onClick")?.key;

    if (globalKeyEvent.altKey === true) {
      const currentCell: any = rowData.currentCell
      navigator.clipboard.writeText(currentCell.innerText)
      setGlobalKeyEvent(false, false);
      return null;
    }

    if (globalKeyEvent.ctrlKey === true) {
      setGlobalKeyEvent(false, false);
      const urlParam = {
        key: eventKey,
        id: rowData.data.id,
        params: { ...oldActionsParams },
        label: rowData.data.name,
      };
      const jwtParams = jwt.sign(urlParam, "pdm");
      window.open(window.location.href + "/" + jwtParams);
      return null;
    }
    if (globalKeyEvent.ctrlKey === false && globalKeyEvent.altKey === false) {
      runActions(eventKey, rowData?.data.id, { ...oldActionsParams });
    }
  };

  commandClick(args: CommandClickEventArgs): void {
    const row = (args.target.closest("tr"))
    const cell = row.querySelector("td.id-cell");
    const id = Number.parseFloat(cell.innerHTML);
    const eventKey: any = args.commandColumn.title

    if (!eventKey) return null

    if (store.getState().keyEvent.ctrlKey === true) {
      const urlParam = {
        key: eventKey,
        id: id,
        params: null,
        label: null
      }
      const jwtParams = jwt.sign(urlParam, 'pdm');
      window.open(window.location.href + '/' + jwtParams);
    } else {
      runActions(eventKey, id, null)
    }
  }

  clearData() {
    this.setState({
      gridRenderData: null,
      gridData: null
    })
  }


  render() {
    const { navData, runActionsState, oldActionsParams } = this.props;

    return (
      <GridWidgetSync
        $this={this}
        navData={navData}
        runActionsState={runActionsState}
        oldActionsParams={oldActionsParams}
        cellSelected={this.cellSelected}
        selectionSettings={this.selectionSettingsCell}
        gridData={this.state.gridData}
        gridRenderData={navData.widgetData}
        commandClick={this.commandClick}
      />
    );
  }
}


const mapStateToProps = (state: any) => ({
  globalKeyEvent: state.keyEvent
})

const mapDispatchToProps = (dispatch: any) => ({
  setGlobalKeyEvent: (altKey: any, ctrlKey: any) => dispatch(setGlobalKeyEvent(altKey, ctrlKey))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks((GridWidgetView)))
