const query = `
match (user:User)
where user.uuid = $user.uuid
  and apoc.convert.toBoolean(user.deactivated) = false

optional match (user)-[:has]->(:System:Setting)-[uses:uses]->(language:System:UserInterface:Language)
optional match (language_fallback:System:UserInterface:Language{iso639_1: 'en'})

with distinct
  user,
  case when language is null then 0 else toInteger(uses.sequence) end as sequence,
  case when language is null then language_fallback else language end as language

with
  [l in apoc.coll.sortMaps(collect({language: language, sequence: sequence}), '^sequence') | l.language][0] as language

match (node)
where id(node) = toInteger($id)

optional match path=(nodeType_start:System:Schema:NodeType)<-[:extends*0..]-(nodeType_end:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(node))
  and not (nodeType_start)-[:extends]->(:System:Schema:NodeType)

unwind nodes(path) as nodeType
unwind [map in [] + $data | {id: toInteger(map.\`_key\`), value: map[keys(map)[1]]}] as attribute_map

optional match (attribute:System:Schema:Attribute)
where id(attribute) = attribute_map.id

optional match (node)-[:hasApproval]->(:System:AccessControl:Approval)-[:hasState]->(state:Apps:State)<-[:nextState*0..]-(:Apps:StateMachine)-[:forNodeType]->(nodeType)
optional match (state)<-[:current]-(validation:Apps:Validation)-[:for]->(attribute)<-[:hasAttribute]-(nodeType)

optional match (attribute)<-[:for]-(validation:Apps:Validation)-[:hasType]->(type:Apps:Type)
optional match (validation)-[:hasLabel]->(label:System:UserInterface:Label)
optional match (label)-[:withName]->(term:Apps:Term)
optional match (term)-[:hasTranslation]->(translation:Apps:Translation)-[:in]->(language)
where any(label in labels(translation) where label = toUpper(language.iso639_1)) 

with distinct
  collect(distinct
    {
      id: attribute_map.id,
      value: attribute_map.value,
      type: type.code,
      name: attribute.name,
      message: coalesce(translation.name, label.name),
      isValid: case type.code
        when 'required' then not coalesce(attribute_map.value, '') = ''
        when 'regex' then apoc.convert.toBoolean(size(apoc.text.regexGroups(attribute_map.value, validation.regex)[0]) > 0)
        else true
      end
    }
  ) as attribute_maps

with distinct
  attribute_maps,
  all(map in attribute_maps where map.isValid = true) as isValid

unwind attribute_maps as attribute_map

with distinct
  apoc.map.merge(
    {
      \`_key\`: attribute_map.id,
      validations: [validation in [map in attribute_maps where map.id = attribute_map.id and not map.type is null] | validation{.type, .isValid, .message}],
      isValid: all(validation in [map in attribute_maps where map.id = attribute_map.id and not map.type is null] where validation.isValid = true)
    },
    apoc.map.fromPairs([[attribute_map.name, attribute_map.value]])
  ) as field_map,
  isValid

with
  {
    data: collect(field_map),
    isValid: isValid
  } as response

return response
  `;
 
export {query};
