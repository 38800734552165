import React from "react";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { withTranslation } from "react-i18next";

class FloatField extends React.Component<any, any> {

  private textboxInstance: TextBoxComponent;


  onChange = (args: any) => {
    const { setNewValue } = this.props

    if (args.value) {
      setNewValue(args.value.toString().replace(/[^0-9.-]+/, ""))
    }
  }

  render() {
    const { field, isLocked, value, hiddenClass } = this.props

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label) || "blueberry"}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element '
          // style={{ backgroundColor: setColorField() }}
        >
          <TextBoxComponent
            ref={(scope) => { (this.textboxInstance as TextBoxComponent | null) = scope; }}
            className='form-input-element'
            value={value}
            change={this.onChange.bind(this)}
            enabled={!isLocked}
            type="number"
          />
        </div>
      </div>
    )
  }
}
export default (withTranslation()(FloatField));