import * as React from 'react'
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import history from './history'
import LoginPage from '../pages/loginPage/loginPage'
import ModulePage from '../pages/modulePage/modulePage'
import NotFoundPage from '../pages/notFoundPage/notFoundPage'
import { getThemes } from '../store/getThemes/action'
import { run as runActions } from '../store/actions/run'
import RequestPasswordPage from '../pages/resetPassword/requestPasswordPage'
import SetNewPasswordPage from '../pages/resetPassword/setNewPasswordPage'
import store from '../store/store'

const RoutesCostum: React.FC<any> = ({ }) => {

  const getSessionToken = () => {
    let connectTrue = true;

    if (localStorage.getItem('connection') === null || localStorage.getItem('connection') === undefined)
      connectTrue = false

    return connectTrue;
  }


  const jwtString = window.location.pathname.split('app/')[1]

  const ProtectedRoute = ({ children }) => {
    let params;

    if (jwtString) {
      params = jwt.verify(jwtString, 'pdm')
    }

    if (!getSessionToken()) {
      return <Navigate to='/login' />
    } else {
      store.dispatch<any>(getThemes());
      if (params) {
        runActions(params.key, params.id, params)
        return children
      } else {
        return children
      }
    }
  }

  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginPage history={history} />} />
        <Route path="/request-password" element={<RequestPasswordPage />} />
        <Route path="/reset-password" element={<SetNewPasswordPage />} />
        <Route path="/" element={
          <ProtectedRoute>
            <Navigate to='/app' />
          </ProtectedRoute>}
        />
        <Route path="/app" element={
          <ProtectedRoute>
            <ModulePage />
          </ProtectedRoute>}
        />
        <Route path="/app/:jwtString" element={
          <ProtectedRoute>
            <Navigate to='/app' />
          </ProtectedRoute>}
        />
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default connect(null,
)(RoutesCostum);
