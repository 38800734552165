import * as React from "react";
//import { FormValidator } from "@syncfusion/ej2-inputs";
import { Icon } from "semantic-ui-react";
import PasswordStrengthBar from "react-password-strength-bar";

export class PasswordEditField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordHidden: true,
      newPassword: ""
    };
  }
  componentDidMount() {
    // let options = {
    //   rules: {
    //     Password: { required: true }
    //   }
    // };
    // let formObj = new FormValidator("#formId", options);
  }

  render() {
    const { setNewValue } = this.props;
    const { newPassword } = this.state;

    return (
      <div className="col-lg-12 control-section">
        <div className="content-wrapper check1">
          <div className="form-title"></div>
          <div id="formId" className="form-horizontal" noValidate="">
            <div className="form-group form-input-container">
              <label className="form-input-label">New Password</label>
              <div className="form-input-element ">
                <input
                  className="form-input-element"
                  type={this.state.isPasswordHidden ? "password" : "text"}
                  id="pass"
                  name="Pass"
                  required
                  data-msg-containerid="passError"
                  data-required-message="*Password Required"
                  onChange={e => {
                    this.setState({ newPassword: e.target.value });
                    setNewValue(e.target.value);
                  }}
                />
                <Icon
                  name="lock"
                  onClick={() =>
                    this.setState({
                      isPasswordHidden: !this.state.isPasswordHidden
                    })
                  }
                  inverted
                  circular
                  link
                />
                <span className="e-float-line" />
              </div>
              <div id="passError" />
            </div>
            <div>
              <PasswordStrengthBar password={newPassword} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordEditField;
