export function query(params: any = {}) {
  return `
match (translation:Apps:Section:Translation)
where id(translation) = toInteger($id)

match (image:Apps:Image)<-[:is]-(file:File)-[:hasType]-(:Type{code: 'thumbnail'})
where image.name =~ '(?i).*?${ params.search }.*?'
  or image.ID =~ '(?i).*?${ params.search }.*?'
  or image.keywords =~ '(?i).*?${ params.search }.*?'

optional match (translation)<-[:to]-(link:Apps:Link)-[:from]->(image)

with
  translation,
  image,
  file,
  case when link is null then apoc.create.uuid() else link.uuid end as uuid

with
  collect(distinct {
    text: image.name,
    uuid: uuid,
    thumbnail: "<img style='max-height:30px' src='/api/services/file/download?absolutePath=" + file.absolutePath+ "'/>",
    image: '/api/services/link/from/image/view?type=' + $type + '&uuid=' + uuid,
    source: {id: id(translation), name: translation.name},
    target: {id: id(image), name: image.name}
  }) as elements

return
  {
    elements: apoc.coll.sortMaps(elements, '^sequence')
  } as response
  `;
};
