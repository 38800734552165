import {
  SET_GLOBAL_KEY_EVENT
} from "./types";
import initialState from "./initialState";

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // case SET_GLOBAL_KEY_EVENT_PENDING:
    //   return {
    //     ...state,
    //     pending: true,
    //     success: false,
    //     error: false
    //   };

    // case SET_GLOBAL_KEY_EVENT_ERROR:
    //   return {
    //     ...state,
    //     pending: false,
    //     success: false,
    //     error: true
    //   };

    case SET_GLOBAL_KEY_EVENT:
      return {
        ...state,
        altKey: action.altKey,
        ctrlKey: action.ctrlKey
      };

    default:
      return state;
  }
};

export default reducer;
