const query = `
with permissions, widget, widget_map, element, id, user
      
      with widget, widget_map, element, id, user
      where widget_map.widget = 'Diagram'
      
      optional match (diagram:Apps:Diagram)-[:uses]->(template:Apps:Diagram:Template)-[:hasType]->(type:Apps:Type)
      where id(diagram) = id
      optional match (template)-[:uses]->(list_shapes:Apps:List)-[:for]->(:System:Schema:NodeType{name: "Shape"})
      optional match (list_shapes)-[contains_shape:contains]->(shape:Apps:Shape)
      optional match (template)-[:uses]->(list_connectors:Apps:List)-[:for]->(:System:Schema:NodeType{name: "Connector"})
      optional match (list_connectors)-[contains_connector:contains]->(connector:Apps:Connector)

      with
         diagram,
         widget_map,
         template,
         type,
         list_shapes,
         shape{
           id: id(shape),
           .name,
           .description,
           sequence: coalesce(toInteger(contains_shape.sequence), 0)
         } as shape_map,
         apoc.convert.fromJsonMap(shape.content) as shape_symbol_map,
         list_connectors,
         connector{
          id: id(connector),
          .name,
          .description,
          sequence: coalesce(toInteger(contains_connector.sequence), 0)
        } as connector_map,
        apoc.convert.fromJsonMap(connector.content) as connector_symbol_map

      with
        diagram,
        widget_map,
        template,
        type,
        list_shapes,
        shape_symbol_map{
          .*,
          id: coalesce(shape_symbol_map.id, shape_map.id),
          name: coalesce(shape_symbol_map.name, shape_map.name),
          sequence: shape_map.sequence,
          shape: apoc.map.merge(
            shape_symbol_map.shape,
            {
              type: coalesce(shape_symbol_map.shape.type, 'Basic'),
              shape: coalesce(shape_symbol_map.shape.shape, 'Ellipse')
            }
          ),
          style: apoc.map.merge(
            shape_symbol_map.style,
            {
              fill: coalesce(shape_symbol_map.style.fill, '#cfcfcf'),
              strokeColor: coalesce(shape_symbol_map.style.strokeColor, 'black')
            }
          ),
          annotations: [
            annotation in
            case
              when shape_symbol_map.annotations is null 
              then [{style: {fill: 'transparent', color: 'black'}}]
              else shape_symbol_map.annotations
            end
            |
            annotation{
              .*,
              style: apoc.map.merge(
                annotation.style,
                {
                  fill: coalesce(annotation.style.fill, 'transparent'),
                  color: coalesce(annotation.style.color, 'black')
                }
              )
            }
          ]
        },
        list_connectors,
        connector_symbol_map{
          .*,
          id: coalesce(connector_symbol_map.id, connector_map.id),
          name: coalesce(connector_symbol_map.name, connector_map.name),
          sequence: connector_map.sequence,
          type: coalesce(connector_symbol_map.type, 'Orthogonal'),
          sourcePoint: apoc.map.merge(
            connector_symbol_map.sourcePoint,
            {
              x: coalesce(connector_symbol_map.sourcePoint.x, 0),
              y: coalesce(connector_symbol_map.sourcePoint.y, 0)
            }
          ),
          targetPoint: apoc.map.merge(
            connector_symbol_map.targetPoint,
            {
              x: coalesce(connector_symbol_map.targetPoint.x, 40),
              y: coalesce(connector_symbol_map.targetPoint.y, 40)
            }
          ),
          targetDecorator: apoc.map.merge(
            connector_symbol_map.targetDecorator,
            {
              shape: coalesce(connector_symbol_map.targetDecorator.shape, 'Arrow')
            }
          )
       }

      with distinct
        diagram,
        widget_map,
        {
          id: 'shape-list-' + toString(coalesce(id(list_shapes), 0)),
          title: coalesce(list_shapes.name, 'no shapes linked'),
          expanded: not list_shapes is null,
          symbols: apoc.coll.sortMaps(collect(distinct shape_symbol_map), '^sequence')
        } as shape_map,
        {
          id: 'connector-list-' + toString(coalesce(id(list_connectors), 0)),
          title: coalesce(list_connectors.name, 'no connectors linked'),
          expanded: not list_connectors is null,
          symbols: apoc.coll.sortMaps(collect(distinct connector_symbol_map), '^sequence')
        } as connector_map
      
      with distinct
        {
          content: diagram.content,
          template: {
            palettes: collect(distinct shape_map) + collect(distinct connector_map)
          }
        } as widget_subquery
        
      return widget_subquery
`

export {query};