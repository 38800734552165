import React from 'react'
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import dagre from 'cytoscape-dagre';
import cola from 'cytoscape-cola';
import bilkent from 'cytoscape-cose-bilkent';
import euler from 'cytoscape-euler';

import { connect } from 'react-redux'
import { withRouterHooks } from '../../navigation/router.hooks';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import { contextMenu } from './components/graph/contextMenu';
import cytoscapeStyle from './components/graph/cytoscapeStyle';
import { run as runActions } from '../../store/actions/run';
import onLoad from './components/graph/events/onLoad';
import tapNode from './components/graph/events/tap.node';
import tabContext from './components/graph/events/tab.context';
import tapEdge from './components/graph/events/tab.edge';
import { mouseoverNode, mouseoverEdge } from './components/graph/events/mouseover';
import { mouseoutNode, mouseoutEdge } from './components/graph/events/mouseout';
import MenuForm from '../../components/MenuForm/MenuForm';
import { withTranslation } from 'react-i18next';
import './styles.css';

var nodeHtmlLabel = require('cytoscape-node-html-label');
nodeHtmlLabel(cytoscape); // register extension

cytoscape.use(klay);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(bilkent);
cytoscape.use(euler);

class Graph extends React.Component<any, any> {
  cy: any
  contextMenuInstance = null;

  constructor(props) {
    super(props)

    this.state = {
      nodes: [],
      relations: [],
      layout: {}
    };

    this.renderCytoscapeElement = this.renderCytoscapeElement.bind(this);
    this.addNodesCytoscape = this.addNodesCytoscape.bind(this);
    this.setLayout = this.setLayout.bind(this)
  }

  componentDidMount() {
    const { renderData, data } = this.props;
    this.renderCytoscapeElement(data, renderData.config);
    this.setLayout()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { renderData, data } = this.props;

    if (prevProps.renderData !== renderData || prevProps.data !== data) {
      this.contextMenuInstance?.destroy();
      this.renderCytoscapeElement(data, renderData.config);
      this.setLayout()
    }

  }

  componentWillUnmount() {
    if (this.contextMenuInstance)
      this.contextMenuInstance.destroy();
  }

  setLayout() {
    const { renderData } = this.props;

    this.setState({
      layout:
      {
        name: renderData.config.layout,
        fit: true,
        spacingFactor: 1.2,
        randomize: renderData.config.randomize,
        animate: renderData.config.animate,
      }
    })
  }

  getAllNodesAndEdges = () => {
    let fetchArray = []
    this.cy.nodes().positions((node) => {
      fetchArray.push({ "group": node._private.group, 'data': node._private.data, 'position': node._private.position })
    })
    this.cy.edges().positions((edge) => {
      fetchArray.push({ "group": edge._private.group, 'data': edge._private.data })
    })
    return fetchArray
  }


  reloadLayout = (layout: any) => {
    var _layout = this.cy.layout({
      name: layout
    });
    _layout.run();
  }

  async addNodesCytoscape(nodes, position) {

    if (!nodes) return null

    const sizeNodesArray = Object.keys(nodes).length / 2;

    nodes.forEach((element, index) => {
      if (element.group === 'nodes') {
        if (sizeNodesArray > 20) {
          element.position.x = position.x + index * 100
          element.position.y = position.y + index * 40 + 200
        } else {
          element.position.x = position.x + 200 * Math.cos(2 * Math.PI * index / sizeNodesArray);
          element.position.y = position.y + 200 * Math.sin(2 * Math.PI * index / sizeNodesArray);
        }
      }
    });
    this.cy.add(nodes);
    //this.cy.layout(this.state.layout).run();
  }

  async removeNode(node: any) {

    if (!node) return null
    this.cy.remove(this.cy.getElementById(node.id.toString()))
  }

  async renderCytoscapeElement(elementsData, config) {
    const { navData, fetchStateAction, setNavigation, renderData, oldActionsParams } = this.props;

    this.cy = cytoscape({
      container: document.getElementById('cy'),
      selected: false,
      selectable: config.selectable,
      grabbable: config.grabbable,
      style: await cytoscapeStyle(config),
      elements: elementsData,
      wheelSensitivity: 0.1,
      locked: true,
      boxSelectionEnabled: false,
      autounselectify: true,
      layout: this.state.layout
    });

    this.cy.nodeHtmlLabel([
      {
        query: 'node.selected', // cytoscape query selector
        halign: 'center', // title vertical position. Can be 'left',''center, 'right'
        valign: 'bottom', // title vertical position. Can be 'top',''center, 'bottom'
        halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
        valignBox: 'bottom', // title relative box vertical position. Can be 'top',''center, 'bottom'
        cssClass: 'cy-infoblock', // any classes will be as attribute of <div> container for every title
        tpl(data) {
          return data?.infoblock === null || data?.infoblock === undefined ? '' : data.infoblock;
        }
      }
    ]);

    if (navData.widgetData.menu.context !== undefined)
      this.contextMenuInstance = await contextMenu(this.cy, navData.widgetData.menu.context, fetchStateAction, navData.widgetData.dataID, setNavigation, renderData, this.addNodesCytoscape, this.removeNode.bind(this), this.props.t);
    //await edgeHandle(this.cy);
    //await expandCollapse(this.cy);
    // await doubleTap(this.cy, setRightSideBar);

    if (navData.widgetData.events !== undefined && navData.widgetData.events.length > 0) {
      await onLoad(this.cy);
      await tapNode(this.cy, navData.widgetData.events, runActions);
      await tapEdge(this.cy, navData.widgetData.events, runActions);
      await tabContext(this.cy);
      await mouseoverNode(this.cy);
      await mouseoverEdge(this.cy);
      await mouseoutNode(this.cy);
      await mouseoutEdge(this.cy)
    }
  }


  render() {
    const { navData, oldActionsParams } = this.props

    if (!navData)
      return null;

    return (
      <>
        {
          ![null, undefined].includes(this.props.navData.widgetData.menu)
            ? <MenuForm
              key={`menu-${navData.widgetData.menu.id}`}
              getWidgetEditData={this.getAllNodesAndEdges.bind(this)}
              reloadLayout={this.reloadLayout.bind(this)}
              menu={navData.widgetData.menu}
              currentDataID={navData.widgetData.dataID}
              navData={navData}
              oldActionsParams={oldActionsParams}
              viewType={navData.widgetData.type}
              widgetType={navData.widgetData.widget}
            />
            : null
        }
        <div className="graph-container">
          <div style={{ width: '100%', height: '100%' }}>
            <div className="node_selected" style={{ height: '100%', width: '100%' }}>
              <div style={{ height: '100%', width: '100%' }} id="cy" />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  navigation: state.navigation,
  getState: state.getState,
  runActionsState: state.actions
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(Graph)))
