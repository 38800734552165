import axios from "axios";
import download from 'js-file-download';
import { logger } from '../../logger';

function base64ToBlob(base64, type = "application/octet-stream") {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
}

export const service = async (url: string, params: any) => {
  return axios.get(
    url,
    {
      responseType: 'json', // 'arraybuffer',
      params: params.response.params,
      headers: {
        Accept: 'application/pdf',
      }
    }
  ).then(
    (response) => {
      switch (response.data.type) {
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg':
        case 'image/webp':
          let window_img = window.open('about:blank');
          setTimeout(function () {
            let container = document.createElement('div');
            container.setAttribute('style', 'height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;');
            let img = document.createElement('img');
            img.src = `data:${response.data.type};base64,${response.data.data}`;
            img.setAttribute('style', 'max-height: 100%');
            container.appendChild(img);
            window_img.document.body.appendChild(container);
          }, 100);
          break;
        case 'application/pdf':
          setTimeout(function () {
            let window_embed = window.open('about:blank');
            var blob = base64ToBlob(response.data.data, 'application/pdf');
            window_embed.document.write('<iframe src="' + URL.createObjectURL(blob) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
          }, 100);
          break;
        default:
          download(response.data, params.originalName);
      };
    }
  ).catch(
    (error) => {
      logger.params({ params }).error('failed to call file/view', error);
    }
  )
}
