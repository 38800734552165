import axios from "axios";
import { logger } from '../../logger';

export const service = async (url: string, file: File) => {
  let requestParams = new FormData();
  requestParams.append("file", file);

  return axios.post(
    url,
    requestParams,
    {
      headers: {
        'Content-Type': 'application'
      }
    }
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      logger.params({ file }).error('failed to call service: file/getHash', error);
    }
  )
}