import { logger } from '../../../services/logger';
import query from './getTargetQuery'
import Connection from '../../../services/api/Database/connection';

export const service = async (storeName: string, params: any) => {
  try {
    let connection = new Connection()
    return connection.runQuery(storeName, query, params);
  } catch (error) {
    logger.error(error.message, query, params);
  }
}
