import React from 'react'
import { getStateRemoveModalWidget } from '../../store/getState/action';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { run as runAction } from '../../services/api/action/run.api';
import { run as runActions } from '../../store/actions/run';
import store from '../../store/store';
import { withTranslation } from 'react-i18next';
import './styles.css'

class ModalDialog extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      contentData: null
    }
  }

  componentDidMount() {
    const { navData } = this.props;
    runAction({ type: 'Query', query: navData.widgetData.query }, { ...this.props.oldActionsParams.params, id: navData.widgetData.dataID }, null, true)
      .then((response) => {
        this.setState({ contentData: response.targets });
      })
  }

  componentDidUpdate(prevProps) {
    const { navData } = this.props;
    if (prevProps.navData.widgetData !== navData.widgetData) {
      runAction({ type: 'Query', query: navData.widgetData.query }, { ...this.props.oldActionsParams.params, id: navData.widgetData.dataID }, null, true)
        .then((response) => {
          this.setState({ contentData: response.targets });
        })
    }
  }

  getButtons = () => {
    const { oldActionsParams, navData } = this.props

    const buttons = [];

    navData.widgetData.menu.elements.forEach(element => {
      buttons.push({
        buttonModel: {
          content: this.props.t(element.label),
          cssClass: 'e-flat',
          // isPrimary: true,
        },
        'click': () => {
          const eventKey = element.events.find(event => event.type === 'onClick').key;
          runActions(eventKey, navData.widgetData.dataID, { ...oldActionsParams, id: navData.widgetData.dataID })
        }
      })
    });

    buttons.push({
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-flat'
      },
      'click': () => {
        store.dispatch(getStateRemoveModalWidget(navData.widgetData.key, navData.widgetData.dataID));
      }
    })
    return buttons
  }


  render() {
    const { navData } = this.props
    const { contentData } = this.state

    if (!contentData) {
      return null
    }

    return (
      <DialogComponent
        isModal={true}
        height={navData.widgetData.height}
        width={navData.widgetData.width}
        enableResize={false}
        resizeHandles={[]}
        allowDragging={false}
        showCloseIcon={false}
        buttons={this.getButtons()}
        close={() => {
          store.dispatch(getStateRemoveModalWidget(navData.widgetData.key, navData.widgetData.dataID));
        }}
        header={contentData[0].header}
        content={contentData[0].content}
      >
      </DialogComponent>
    )
  }
}

export default withTranslation()(ModalDialog);