import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';


const CodeField: FC<any> = ({ viewType, field, value, setNewValue, hiddenClass }) => {
  let Editable: boolean = ["create", "edit"].includes(viewType);
  const { t } = useTranslation();

  return (
    <div className={`form-input-container-fullwidth ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <div className={`codefield ${Editable === false ? 'readonly' : ''}`} style={{ height: field.height + 3 + "px" }}>
        <CodeMirror
          width={"100%"}
          onChange={(value: any) => {
            setNewValue(value)
          }}
          readOnly={Editable === true ? false : true}
          value={value ? value : ''}
          extensions={field.type === 'diagram' ? [langs.json()] : [langs[field.type]()]}
          theme={githubLight}
          basicSetup={{
            foldGutter: true,
            dropCursor: true,
            allowMultipleSelections: true,
            indentOnInput: true,
          }}
        />
      </div>
    </div>
  )
}

export default CodeField;