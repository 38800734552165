import {
  runActionsPending,
  runActionsReceived,
  runActionsError
} from "./actions";
import store from "../store";
import { setBreadcrumbItems } from "../breadcrumb/action";
import { loop as loopActions } from "../../services/api/action/loop";
import { write as writeLog } from "../../services/api/log/write";

export const run = async (key: number, id: number, params: any = {}, files: any = null) => {
  let breadcrumb;
  let source;
  let relation;
  let target;
  let log;
  Promise.all([
    await store.dispatch(runActionsPending()),
    await loopActions(key, id, params, files)
      .then(
        (response) => {
          breadcrumb = response.breadcrumb
          key = response.key;
          id = response.id;
          params = response.params;
          source = response.source;
          relation = response.relation;
          target = response.target;
          log = response.log;
          store.dispatch(
            runActionsReceived(
              response.key,
              response.id,
              response.layout,
              response.params,
              response.breadcrumb,
              response.source,
              response.relation,
              response.target,
              response.timestamp
            )
          );
        }
      )
      .then(
        () => writeLog(log)
      )
      .catch((err: any) => {
        console.error("runActionsError: ", err.message);
        store.dispatch(runActionsError(err));
      }),
    breadcrumb === true ? await setBreadcrumbItems(key, id, params, source, relation, target) : null
  ]);
}
