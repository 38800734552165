const query = `
match (node)-[hasMenuItem:hasMenuItem]->(menuItem:MenuItem)
where id(node) = toInteger($id)

optional match (menuItem)-[:raises]->(event:Event)

optional match (event)-[runs_calls:runs|calls]->(action)
optional match (event)-[:hasSource]->(source:NodeType)
optional match (event)-[:hasRelation]->(relation:RelationType)
optional match (event)-[:hasTarget]->(target:NodeType)
optional match (source)<-[hasSource]-(relation)-[hasTarget]->(target)

with
  hasMenuItem,
  menuItem,
  event,
  case
    when any(label in labels(action) where label = 'Query')
    then action{
      type: 'Query',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      cypher: action.cypher
    }
    when any(label in labels(action) where label = 'Service')
    then action{
      type: 'Service',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      url: action.url,
      params: case when action.params is null then '' else apoc.convert.fromJsonMap(action.params) end
    }
    else null
  end as action,
  source,
  relation{
    .*,
    direction: case when (relation.direction is null or not relation.direction in ['out', 'in']) then
      case when type(hasSource) = 'startsAt'
        then 'out'
        else 'in'
      end
    else relation.direction
    end
  },
  target

with
  hasMenuItem,
  menuItem,
  event,
  action,
  {
    source: source{
      type: source.name
    },
    relation: relation{
      type: relation.name,
      direction: relation.direction,
      cypher: case when relation.direction = 'out'
          then '-[relation:' + relation.name + ']->'
          else '<-[relation:' + relation.name + ']-'
        end,
      apoc: case when relation.direction = 'out'
          then relation.name + '>'
          else '<' + relation.name
        end
    },
    target: target{
      type: target.name
    }
  } as params

with distinct
  hasMenuItem,
  menuItem,
  event,
  apoc.coll.sortMaps(collect(distinct action), '^sequence') as actions,
  params

optional match (event)-[loads:loads]->(widget)
where any(label in labels(widget) where label in ['Grid','Form','Gallery','Graph'])
optional match (widget)-[:hasLabel]->(label_widget:Label)

with distinct
  hasMenuItem,
  menuItem,
  actions,
  params,
  apoc.coll.sortMaps(collect(widget{
    id: id(widget),
    widget: [label in labels(widget) where label in ['Grid','Form','Gallery','Graph']][0],
    label: coalesce(label_widget.name, widget.name),
    type: apoc.text.capitalize(coalesce(widget.type, 'View')),
    event: {
      label: event.name,
      type: event.type,
      confirm: apoc.convert.toBoolean(event.confirm)
    },
    sequence: coalesce(toInteger(loads.sequence), toInteger(widget.sequence), 0),
    position: {
      target: coalesce(loads.target, 'self'),
      column: coalesce(toInteger(loads.column), 1),
      row: coalesce(toInteger(loads.row), 1)
    }
  }), '^sequence') as targets

optional match (menuItem)-[:hasLabel]->(label_menuItem:Label)

with
  collect(distinct menuItem{
    id: id(menuItem),
    label: coalesce(label_menuItem.name, menuItem.name),
    type: menuItem.type,
    sequence: coalesce(toInteger(hasMenuItem.sequence), toInteger(menuItem.sequence), 0),
    actions: actions,
    params: params,
    targets: targets
  }) as menu

with
  [item in menu where item.type = 'button'] as buttons,
  [item in menu where item.type = 'dropdown'] + [null] as nodes

unwind nodes as node

optional match (menuItem_caller:MenuItem)-[hasMenuItem:hasMenuItem]->(menuItem:MenuItem)
where id(menuItem_caller) = node.id

optional match (menuItem)-[:raises]->(event:Event)

optional match (event)-[runs_calls:runs|calls]->(action)
optional match (event)-[:hasSource]->(source:NodeType)
optional match (event)-[:hasRelation]->(relation:RelationType)
optional match (event)-[:hasTarget]->(target:NodeType)
optional match (source)<-[hasSource]-(relation)-[hasTarget]->(target)

with
  buttons,
  node,
  hasMenuItem,
  menuItem,
  event,
  case
    when any(label in labels(action) where label = 'Query')
    then action{
      type: 'Query',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      cypher: action.cypher
    }
    when any(label in labels(action) where label = 'Service')
    then action{
      type: 'Service',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      url: action.url,
      params: case when action.params is null then '' else apoc.convert.fromJsonMap(action.params) end
    }
    else null
  end as action,
  {
    source: source{
      type: source.name
    },
    relation: relation{
      type: relation.name,
      direction: case when type(hasSource) = 'startsAt'
          then 'out'
          else 'in'
        end,
      cypher: case when type(hasSource) = 'startsAt'
          then '-[relation:' + relation.name + ']->'
          else '<-[relation:' + relation.name + ']-'
        end,
      apoc: case when type(hasSource) = 'startsAt'
          then relation.name + '>'
          else '<' + relation.name
        end
    },
    target: target{
      type: target.name
    }
  } as params

with distinct
  buttons,
  node,
  hasMenuItem,
  menuItem,
  event,
  apoc.coll.sortMaps(collect(distinct action), '^sequence') as actions,
  params

optional match (event)-[loads:loads]->(widget)
where any(label in labels(widget) where label in ['Grid','Form','Gallery','Graph'])
optional match (widget)-[:hasLabel]->(label_widget:Label)

with distinct
  buttons,
  node,
  hasMenuItem,
  menuItem,
  actions,
  params,
  apoc.coll.sortMaps(collect(widget{
    id: id(widget),
    widget: [label in labels(widget) where label in ['Grid','Form','Gallery','Graph']][0],
    label: coalesce(label_widget.name, widget.name),
    type: apoc.text.capitalize(coalesce(widget.type, 'View')),
    event: {
      label: event.name,
      type: event.type,
      confirm: apoc.convert.toBoolean(event.confirm)
    },
    sequence: coalesce(toInteger(loads.sequence), toInteger(widget.sequence), 0),
    position: {
      target: coalesce(loads.target, 'self'),
      column: coalesce(toInteger(loads.column), 1),
      row: coalesce(toInteger(loads.row), 1)
    }
  }), '^sequence') as targets

optional match (menuItem)-[:hasLabel]->(label_menuItem:Label)
optional match (:Schema:NodeType{name: 'MenuItem'})-[:hasStyle]->(style:Style)
optional match (style)-[:hasIcon]->(icon:Icon)
optional match (style)-[:hasColor{type: 'icon'}]->(color_icon:Color)
optional match (style)-[:hasColor{type: 'font'}]->(color_font:Color)

with distinct
  buttons,
  node{
    .*,
    items: collect(distinct menuItem{
      id: id(menuItem),
      label: coalesce(label_menuItem.name, menuItem.name),
      type: menuItem.type,
      sequence: coalesce(toInteger(hasMenuItem.sequence), toInteger(menuItem.sequence), 0),
      actions: actions,
      params: params,
      targets: targets,
      style: {
        icon: {
          class: icon.name,
          color: color_icon.hex
        },
        label: {
          color: color_font.hex
        }
      }
    })
  }

with distinct
  apoc.coll.sortMaps(buttons + collect(node), '^sequence') as menu

optional match (node)-[hasMenuItem:hasMenuItem]->(menuItem:MenuItem{type: 'context'})
where id(node) = toInteger($callerID)

optional match (menuItem)-[:raises]->(event:Event)

optional match (event)-[runs_calls:runs|calls]->(action)
optional match (event)-[:hasSource]->(source:NodeType)
optional match (event)-[:hasRelation]->(relation:RelationType)
optional match (event)-[:hasTarget]->(target:NodeType)
optional match (source)<-[hasSource]-(relation)-[hasTarget]->(target)

with
  menu,
  hasMenuItem,
  menuItem,
  event,
  case
    when any(label in labels(action) where label = 'Query')
    then action{
      type: 'Query',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      cypher: action.cypher
    }
    when any(label in labels(action) where label = 'Service')
    then action{
      type: 'Service',
      sequence: coalesce(toInteger(runs_calls.sequence), toInteger(action.sequence)),
      url: action.url,
      params: case when action.params is null then '' else apoc.convert.fromJsonMap(action.params) end
    }
    else null
  end as action,
  source,
  relation{
    .*,
    direction: case when (relation.direction is null or not relation.direction in ['out', 'in']) then
      case when type(hasSource) = 'startsAt'
        then 'out'
        else 'in'
      end
    else relation.direction
    end
  },
  target

with
  menu,
  hasMenuItem,
  menuItem,
  event,
  action,
  {
    source: source{
      type: source.name
    },
    relation: relation{
      type: relation.name,
      direction: relation.direction,
      cypher: case when relation.direction = 'out'
          then '-[relation:' + relation.name + ']->'
          else '<-[relation:' + relation.name + ']-'
        end,
      apoc: case when relation.direction = 'out'
          then relation.name + '>'
          else '<' + relation.name
        end
    },
    target: target{
      type: target.name
    }
  } as params

with distinct
  menu,
  hasMenuItem,
  menuItem,
  event,
  apoc.coll.sortMaps(collect(distinct action), '^sequence') as actions,
  params

optional match (event)-[loads:loads]->(widget)
where any(label in labels(widget) where label in ['Grid','Form','Gallery','Graph'])
optional match (widget)-[:hasLabel]->(label_widget:Label)

with distinct
  menu,
  hasMenuItem,
  menuItem,
  actions,
  params,
  apoc.coll.sortMaps(collect(widget{
    id: id(widget),
    widget: [label in labels(widget) where label in ['Grid','Form','Gallery','Graph']][0],
    label: coalesce(label_widget.name, widget.name),
    type: apoc.text.capitalize(coalesce(widget.type, 'View')),
    event: {
      label: event.name,
      type: event.type,
      confirm: apoc.convert.toBoolean(event.confirm)
    },
    sequence: coalesce(toInteger(loads.sequence), toInteger(widget.sequence), 0),
    position: {
      target: coalesce(loads.target, 'self'),
      column: coalesce(toInteger(loads.column), 1),
      row: coalesce(toInteger(loads.row), 1)
    }
  }), '^sequence') as targets

optional match (menuItem)-[:hasLabel]->(label_menuItem:Label)

with
  menu,
  collect(distinct menuItem{
    id: id(menuItem),
    label: coalesce(label_menuItem.name, menuItem.name),
    type: menuItem.type,
    sequence: hasMenuItem.sequence,
    actions: actions,
    params: params,
    targets: targets
  }) as contextMenu

return
  menu,
  contextMenu
`;

export default query;
