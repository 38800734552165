import React from 'react'
import { logger } from '../../services/logger'
import { notifyError } from '../Toasts/toasts';

class NotificationMessageForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {

    notifyError("An error has occurred")
    logger.log("ERROR: ", error.message)
    
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  
  render() {
    if (this.state.errorInfo) {
      
      return (
        <div>
          <h2>Something went wrong here (Form)</h2>
        </div>
      );
    }
    
    return this.props.children;
  }  
}

export default NotificationMessageForm

