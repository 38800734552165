import {
    GET_STATE_PENDING,
    GET_STATE_SUCCESS,
    GET_STATE_ERROR,
    GET_STATE_REMOVE_MODAL_WIDGET,
  } from "./types";
  import initialState from "./initialState";
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_STATE_PENDING:
        return {
          ...state,
          pending: true,
          success: false,
          error: false,
        };
  
      case GET_STATE_SUCCESS:
        return {
            ...state,
            pending: false,
            success: true,
            error: false,
            data: {
                widgets: action.widgets,
                layout: action.layout || state.data.layout,
                oldActionsParams: {...state.data.oldActionsParams, ...action.oldActionsParams},
                oldWidgetViewType: action.oldWidgetViewType
            }
        };

      case GET_STATE_REMOVE_MODAL_WIDGET:

        const widgetsWithoutModal = state.data.widgets.filter(widget => widget.key !== action.key)

        return {
          ...state,
          data: {
            ...state.data,
            widgets: widgetsWithoutModal
          },
          flagGridAction: true
        }
  
      case GET_STATE_ERROR:
        return {
          ...state,
          pending: false,
          success: false,
          error: true
        };
  
      default:
        return state;
    }
  };
  
  export default reducer;
  