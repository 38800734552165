import React from 'react'
import LayoutWidget1 from '../layoutWidgets/LayoutWidget';
import Modal from '../../../components/modals/Modal'
import { fetchStateAction } from '../../../store/getState/action';
import { connect } from 'react-redux'
import { withRouterHooks } from '../../../navigation/router.hooks';

class MainStage extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { runActionsState, breadcrumb } = this.props;
    if (
      prevProps.runActionsState.data.key !== runActionsState.data.key ||
      prevProps.runActionsState.data.id !== runActionsState.data.id
    ) {
      if (runActionsState.data.key !== null) {
        fetchStateAction(
          runActionsState.data.key,
          runActionsState.data.id,
          runActionsState.data.params ?? {},
          null,
          null,
          runActionsState.data.breadcrumb,
          runActionsState.data.relation,
          runActionsState.data.source,
          runActionsState.data.target
        )
      }
    } else {
      if (prevProps.runActionsState.data.timestamp !== runActionsState.data.timestamp && prevProps.runActionsState.data.key === runActionsState.data.key && prevProps.runActionsState.data.id === runActionsState.data.id) {
        fetchStateAction(
          runActionsState.data.key,
          runActionsState.data.id,
          runActionsState.data.params ?? {},
          null,
          null,
          runActionsState.data.breadcrumb,
          runActionsState.data.relation,
          runActionsState.data.source,
          runActionsState.data.target
        )
      }
    }
    document.title = breadcrumb.currentPage?.label !== undefined && breadcrumb.currentPage?.label !== null ? breadcrumb.currentPage?.label : "semantic PDM"
  }


  render() {
    const { getState, runActionsState } = this.props


    const getColumnClass: any = (width: number) => (width > 50) ? "dynamicColumn spacy" : 'dynamicColumn tight';
    const getWidget: any = (columnIndex, rowIndex) => getState.data.widgets.find(widget => widget.position.column === columnIndex && widget.position.row === rowIndex)
    const getModalWidget: any = getState.data.widgets.find(widget => widget.modal === true)

    return (
      <>
        {
          getState.data.layout
          && getState.data.layout.columns
          && getState.data.layout.columns.map((column, columnIndex) =>
            <div className={getColumnClass(column.width)} style={{ height: '100%', width: `${column.width}%` }} key={'dynamicColumn-' + columnIndex}>
              <div className={"innerColumn"}>
                {
                  column.rows?.map((row, rowIndex) => {
                    const widget = getWidget(columnIndex + 1, rowIndex + 1);
                    return <div className={'dynamicRow'} key={`dynamicRow-${row.position}-${column.position}`}>
                      {
                        widget
                          ? (
                            <LayoutWidget1
                              runActionsState={runActionsState}
                              column={column}
                              row={row}
                              widget={widget}
                              oldActionsParams={getState.data.oldActionsParams}
                              oldWidgetViewType={getState.data.oldWidgetViewType}
                            />
                          )
                          : null
                      }
                    </div>
                  })
                }
              </div>
            </div>
          )
        }
        {
          getModalWidget
            ? (
              <Modal
                widget={getModalWidget}
                oldActionsParams={getState.data.oldActionsParams}
              />
            )
            : null

        }
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  getState: state.getState,
  runActionsState: state.actions,
  breadcrumb: state.breadcrumb
})


const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(MainStage))