import axios from "axios";
import { logger } from '../../logger';

export const service = async (url: string, params: any) => {
  return axios.post(
    url,
    params
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      logger.params({params}).error('failed to call image/convert', error);
    }
  )
}
