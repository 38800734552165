import store from '../../../store/store';
import { run as runActions } from '../../../store/actions/run'
import { goBackBreadcrumbAction, updateBreadcrumbItems } from '../../../store/breadcrumb/action';

export const service = async (url: string, params: any) => {
  var itemIndex = parseInt(params?.step);
  let newCurrentPage = null

  if (itemIndex === 0) {
    newCurrentPage = store.getState().breadcrumb.currentPage
    store.dispatch(await goBackBreadcrumbAction(store.getState().breadcrumb.historyPages.length - 2))
  } else {
    newCurrentPage = store.getState().breadcrumb.historyPages[store.getState().breadcrumb.historyPages.length - itemIndex - 1]
    store.dispatch(await goBackBreadcrumbAction(itemIndex))
  }
  runActions(newCurrentPage.key, newCurrentPage.dataID, newCurrentPage.params, null)
}
