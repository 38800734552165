const initialState: any = {
  pending: false,
  success: false,
  error: false,
  data: {
    key: null,
    id: null,
    layout: null,
    params: null,
    breadcrumb: null,
    source: null,
    relation: null,
    target: null,
    timestamp: null
  }
}

export default initialState
