import React from 'react'
import { connect } from 'react-redux'
import { withRouterHooks } from '../../../../navigation/router.hooks';
import './styles.css'
import { run as runActions } from '../../../../store/actions/run';
import { initBreadcrumbAction } from '../../../../store/breadcrumb/action';
import { NodeAnimationSettingsModel, TreeViewComponent, NodeSelectEventArgs } from '@syncfusion/ej2-react-navigations';
import { withTranslation } from 'react-i18next';

class NavigationMenu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      activeSubmenu: 0,
      actionState: null,
      activeItem: null
    }
  }

  fields: object
  animateSetting: NodeAnimationSettingsModel = { expand: { effect: 'SlideDown', duration: 30, easing: 'linear' }, collapse: { effect: 'SlideUp', duration: 30, easing: 'linear' } }
  treeviewInstance: TreeViewComponent
  style: string = 'accordiontree'

  componentDidMount() {
    const { runActionsState } = this.props
    this.setState({ actionState: runActionsState })
  }

  setDropdownItems = (data: any) => {
    const dropdownItems = data.map(
      (o) => {
        let eventKey = o.events ? o.events?.find(event => event?.type === 'onClick')?.key : null
        return {
          nodeId: `${o.key}.${eventKey}`,
          iconCss: o.icon.name !== '' ? `e-icons ${o.icon.name}` : null,
          nodeText: this.props.t(o.label),
          nodeChild: o.elements[0] !== null ? o.elements.map((element) => {
            return {
              nodeId: `${element.key}.${element.events.find(event => event.type === 'onClick')?.key}`,
              iconCss: element.icon.name !== '' ? `e-icons ${element.icon.name} label-icon` : null,
              nodeText: this.props.t(element.label),
              // key: element.events.find(event => event.type === 'onClick').key 
            }

          }) : null
        }
      }
    )
    return dropdownItems
  }

  nodeClicked = (args: any) => {
    const { initBreadcrumbAction, runActionsState } = this.props;

    let treeviewItemData: any = this.treeviewInstance.getNode(args.node)
    let eventKey = treeviewItemData.id.split(".")[1]
    let dataID = treeviewItemData.id.split(".")[0]

    if (treeviewItemData.hasChildren === true) {
      return null
    }
    if (!eventKey) {
      alert('Event Key nicht gefunden')
      return null;
    }
    // const getCurrentApllicationKey = sessionStorage.getItem("Current module");
    // const moduleLabel = modules.data.find((o) => o.value == getCurrentApllicationKey).texts

    runActions(eventKey, null, null)
    initBreadcrumbAction(
      treeviewItemData.text,
      treeviewItemData.text ? treeviewItemData.text : null,
      treeviewItemData.text,
      eventKey,
      null,
      runActionsState.data.breadcrumb
    );
  }

  nodeSelect(args: NodeSelectEventArgs): void {
    if (args.node.classList.contains('e-level-1')) {
      this.treeviewInstance.collapseAll();
      if (this.treeviewInstance.getNode(args.node).expanded) {
        this.treeviewInstance.getNode(args.node).expanded = false;
      } else {
        this.treeviewInstance.expandAll([args.node]);
        this.treeviewInstance.getNode(args.node).expanded = true;
      }
    }
  }

  render() {
    const { navigationMenu } = this.props;

    switch (true) {
      case navigationMenu.success:
        this.fields = { dataSource: this.setDropdownItems(navigationMenu.data.elements), id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: 'iconCss' };
        return (
          <div className={"sidebarElementWrapper"}>
            <TreeViewComponent
              ref={g => this.treeviewInstance = g}
              animation={this.animateSetting}
              cssClass={this.style}
              className='tree-view'
              id='main-treeview'
              fields={this.fields}
              expandOn='Click'
              nodeSelected={this.nodeSelect.bind(this)}
              nodeClicked={this.nodeClicked.bind(this)}
            />
          </div>
        );

      case navigationMenu.error:
        return (
          <div style={{ height: 'calc(100% - 303px)' }}>Fehler oder leer</div>
        );

      default:
        return (
          <div style={{ height: 'calc(100% - 303px)' }}> </div>
        );
    }
  }
}

const mapStateToProps = (state: any) => ({
  navigationMenu: state.getNavigation,
  themes: state.getThemes
})

const mapDispatchToProps = (dispatch: any) => ({
  initBreadcrumbAction: (moduleLabel: string, subMenuLabel: string, pageLabel: string, pageKey: number, pageDataID: number, pageParams: any, config: any) => dispatch(initBreadcrumbAction(moduleLabel, subMenuLabel, pageLabel, pageKey, pageDataID, pageParams, config))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHooks(withTranslation()(NavigationMenu)))
