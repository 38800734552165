export const service = async (params: any) => {
  let copyUrl = Object.values(params ?? null).join("")

  if (typeof navigator.clipboard == "undefined") {
    let textArea = document.createElement("textarea");
    textArea.value = copyUrl;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Failed to copy: ", copyUrl);
    }

    document.body.removeChild(textArea);
    return;
  }
  navigator.clipboard.writeText(copyUrl);
};