import React from "react";
import { FilteringEventArgs, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { withTranslation } from "react-i18next";

class ListField extends React.Component<any, any> {

  private dropdownInstance: MultiSelectComponent;
  data

  onFiltering = debounce((e: FilteringEventArgs) => {
    let query = new Query();
    query = (e.text !== "") ? query.where("name", "startswith", e.text, true) : query;
    e.updateData(this.data, query);
  }, 400);


  onChange = (args: any) => {
    const { options, setNewValue } = this.props;
    
    options.map(option => {
      option.selected = args.value.includes(option.name);
    });

    setNewValue(options);

    return null
  }

  renderPlaceholder = () => {
    const { options, isLocked } = this.props
    if (options) {
      let placeholder = options.find(o => o.selected === true)?.label
      if (options.length === 1) return options[0].label
      return placeholder === undefined || !isLocked ? 'Select something' : placeholder
    }
  }

  fillValueToSelects = (dataArray: any) => {
    if (!Array.isArray(dataArray)) {
      return null;
    }
    let findSelectedObj = []
    dataArray.forEach(obj => { if (obj.selected === true) { findSelectedObj.push(obj.name) } });
    return findSelectedObj
  }

  render() {
    const { field, isLocked, hiddenClass, options } = this.props

    if (options) {
      options.forEach(element => {
        element['text'] = element['label']
      })
    };
    this.data = options
    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          id='multifilter'
          className='form-input-element control-styles'
        // style={{ backgroundColor: setColorField() }}
        >
          <MultiSelectComponent
            dataSource={this.data}
            ref={(scope) => { (this.dropdownInstance as MultiSelectComponent | null) = scope; }}
            className='form-input-element'
            placeholder={this.renderPlaceholder()}
            value={this.fillValueToSelects(options)}
            change={this.onChange.bind(this)}
            enabled={!isLocked}
            filtering={this.onFiltering.bind(this)}
            allowFiltering={true}
            fields={options}
            mode='Box'
          />
        </div>
      </div>
    )
  }
}
export default (withTranslation()(ListField));