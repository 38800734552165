import { logger } from "../../../logger";
import jwt from 'jsonwebtoken'
import Connection from "../../Database/connection";

export const getTreeGridData = async (storeName: any, database: any, query: any, currentParams: any, dataID: any, oldActionsParams: any) => {
  let params = null;

  if (currentParams === null) {
    params = {
      source: { id: dataID }
    }
  } else {
    params = {
      ...currentParams,
      source: currentParams.source ? Object.assign({ id: dataID }, currentParams.source) : { id: dataID },
      relation: currentParams.relation ? Object.assign({}, currentParams.relation) : {},
      target: currentParams.target ? Object.assign({}, currentParams.target) : {}
    }
  }
  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { ...params, ...oldActionsParams, user: local_connection.user, id: dataID }

    let connection = new Connection()
    return connection.runQuery(storeName, query, parameters);
  } catch (error) {
    logger.error("API getTreeGridData:  ", error)
  }
}