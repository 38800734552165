import React from 'react'
import './styles.css'
import { MyProps } from './types'

const CenterLoader: React.FC<MyProps> = () => {

  return (
    <div className={`centerLoaderCover`}>
      <div className="centerLoader" />
    </div>

  )
}

export default CenterLoader
