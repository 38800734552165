const GET_CONTEXT_MENU_PENDING: string = 'GET_CONTEXT_MENU_PENDING'
const GET_CONTEXT_MENU_SUCCESS: string = 'GET_CONTEXT_MENU_SUCCESS'
const GET_CONTEXT_MENU_ERROR: string = 'GET_CONTEXT_MENU_ERROR'
const INIT_GET_CONTEXT_MENU: string = 'INIT_GET_CONTEXT_MENU'

export { 
    GET_CONTEXT_MENU_PENDING,
    GET_CONTEXT_MENU_SUCCESS, 
    GET_CONTEXT_MENU_ERROR,
    INIT_GET_CONTEXT_MENU
}
