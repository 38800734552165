import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import Header from "../../components/Header/Header";
import LeftSideMenu from '../../components/LeftSideMenu/LeftSideMenu'
import { connect } from 'react-redux'
import ConfirmModal from '../../components/modals/confirmModal/Modal.confirm'
import UploadModal from '../../components/uploadModal/uploadModal'
import RichtextDialog from '../../widgets/form/fields/components/RichtextDialog/RichttextDialog';
import './styles.css'
import MainStage from './mainStage/MainStage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader/Loader';
import { notifyError } from '../../components/Toasts/toasts';
import { setKeyEvents } from '../../services/keyEvents';
import { useIdleTimer } from 'react-idle-timer'



const ModulePage: FC<any> = ({ }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [toastHelper, setToastHelper] = useState(true);
  const [timeout, setTimeout] = useState(7200000);
  const [remaining, setRemaining] = useState(null);
  const themes: any = useSelector((state: any) => state.getThemes)
  const runActionsState: any = useSelector((state: any) => state.actions)
  const modals = useSelector((state: any) => state.modals);

  const handleLeftSidebar = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleOnIdle = () => {
    if (remaining === 300000) {
      notifyError(" Noch 5 Minuten verbleibend bis zur automatischen Abmeldung ")
    }
    if (remaining < 300 || remaining === null) {
      localStorage.removeItem('connection');
      window.location.reload();
      window.alert(" Sie wurden automatisch abgemeldet ")
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    crossTab: true,
    // crossTab: {
    //  type: 'localStorage',
    //  channelName: 'idle-timer',
    //  fallbackInterval: 2000,
    //  responseTime: 100,
    //  removeTimeout: 1000 * 60,
    //  emitOnAllTabs: true
    //}
  });

  useEffect(() => {
    setKeyEvents()

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 10);
  }, []);

  return (
    <>
      <div className="main-container" style={{ backgroundColor: themes?.data?.colors?.background?.hex }}>
        <div className={`navigation-container ${isMenuOpen ? "opened" : "closed"}`}>
          {runActionsState.pending === true ? <Loader /> : null}
          <LeftSideMenu isMenuOpen={isMenuOpen} handleLeftSidebar={handleLeftSidebar.bind(this)} />
        </div>
        <div className={`module-container ${isMenuOpen ? "navigation-container opened" : "navigation-container closed"}`} style={{ height: '100%' }}>
          <Header isMenuOpen={isMenuOpen} />
          <Grid columns={1} style={{ height: 'calc(100% - 52px', marginTop: '0rem' }}>
            <Grid.Column style={{ height: '100%' }}>
              <div style={{ height: '100%' }}>
                <MainStage />
              </div>
            </Grid.Column>
          </Grid>
        </div>
        <ConfirmModal />
        <UploadModal />
        <ToastContainer />
        {modals.imageDialog.isOpen ? <RichtextDialog /> : null}
      </div>
    </>
  );
}

export default ModulePage;