import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuForm from '../../components/MenuForm/MenuForm';
import { run as runQuery } from '../../services/api/query/run.api';
import { run as runActions } from '../../store/actions/run';
import { service as linkService } from './service/linkService'
import Handlebars from 'handlebars'
import { marked } from 'marked'
import { HtmlEditor, Inject, RichTextEditorComponent, Resize, MarkdownEditor } from '@syncfusion/ej2-react-richtexteditor';
import './style.css';

const View: React.FC<any> = ({ navData, oldActionsParams }) => {
  const [content, setContent] = useState(null)

  const { t } = useTranslation();
  let rteObj: any;

  useEffect(() => {
    runQuery(
      'system',
      navData.query,
      {
        id: navData.widgetData.dataID
      }
    )
      .then((response) => {
        console.log(
          `%c View (key: ${navData.renderID}) %c `,
          'background: #ff8890;',
          'background: transparent;',
          {
            query: navData.query,
            params: { ...oldActionsParams.params, id: navData.widgetData.dataID },
            response: response.response,
            position: {
              column: navData.widgetData.position.column,
              row: navData.widgetData.position.row
            }
          })
        switch (response.response.type) {
          case 'HTML':
            const template = Handlebars.compile(response.response.content ?? '');
            const result = template(response.response.data);
            setContent(result);
            break;
          case 'Markdown':
            setContent(marked(response.response.content))
            break
          default:
            setContent(response.response.content ?? '');
            break;
        }
        return;
      })
      .then(
        () => {
          setClickEventLink()
        }
      ).catch((error: any) => {
        console.log(error)
      })
  }, [navData.widgetData.dataID || navData.widgetData.timestamp]);


  const setClickEventLink = () => {

    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    let uuid: any;

    let eventKey = navData.widgetData.events?.find(event => event?.type === 'onClick')?.key;
    setTimeout(function () {
      document.querySelectorAll('a').forEach(
        (element: any) => {
          if (element.classList.contains('disable-link')) {
            element.classList.remove('disable-link')
          }

          if (element.href) {
            element.addEventListener("click", (event: any) => {
              event.preventDefault();
              let url = new URL(element.href)
              uuid = url.pathname.slice(1)
              if (regexExpUUID.test(uuid)) {
                event.preventDefault();
                let params = {
                  uuid: uuid
                }
                linkService('system', params)
                  .then((response: any) => {
                    runActions(eventKey, response.response.id, null)
                  })
                  .catch((error: any) => console.log(error))
              } else {
                window.open(url.href, '_blank');
                return null
              }
            })
          }
        }
      )
    }, 500)
  }


  if (!content) return null

  return (
    <div className='widget-container'>
      {
        navData.widgetData.menu
          ? <MenuForm
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            currentDataID={navData.widgetData.dataID}
            oldActionsParams={oldActionsParams}
            viewType={"edit"}
            widgetType={navData.widgetData.widget}
          />
          : null
      }
      {/* <h3 className='widget-label'>{t(navData.label)} </h3> */}
      <RichTextEditorComponent
        readonly={true}
        height={'calc(100% - 50px)'}
        ref={(richtexteditor) => { rteObj = richtexteditor }}
        value={content}
        editorMode={'HTML'}
      >
        <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
      </RichTextEditorComponent>
    </div >

  )
}

export default View;