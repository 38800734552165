const query = `
    match (user:User)
    where user.uuid = $user.uuid
      and apoc.convert.toBoolean(user.deactivated) = false
    
    optional match (user)-[:has]->(:System:Setting)-[uses:uses]->(language:System:UserInterface:Language)
    optional match (language_fallback:System:UserInterface:Language{iso639_1: 'en'})

    with distinct
      user,
      case when language is null then 0 else toInteger(uses.sequence) end as sequence,
      case when language is null then language_fallback else language end as language

    with
      user,
      [l in apoc.coll.sortMaps(collect({language: language, sequence: sequence}), '^sequence') | l.language][0] as language

    optional match (label:System:Label:UserInterface)-[:withName]->(term:Apps:Term)
    optional match (term)-[:hasTranslation]->(translation:Apps:Translation)-[:in]->(language)
    where any(label in labels(translation) where label = toUpper(language.iso639_1)) 

    with distinct
      apoc.map.fromPairs(collect([label.ID, coalesce(translation.name, term.name, label.name)])) as translations

    return
      {
        translations: translations
      } as response
    limit 1
    `;

export default query;