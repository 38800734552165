import { OPEN_UPLOAD_MODAL, CLOSE_UPLOAD_MODAL } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_UPLOAD_MODAL:

      return {
        ...state,
        isOpen: true,
        title: action.title,
        content: action.content,
        okEvent: action.okEvent
      }

    case CLOSE_UPLOAD_MODAL:

    return {
      ...state,
      isOpen: false,
      title: null,
      file: null,
      loadData: {
          pending: false,
          success: false,
          error: false
      }
    }
/*
    case SET_FILE_UPLOAD_MODAL:

    return {
        ...state,
        file: action.file
    }
*/
    default:
      return state
  }
}

export default reducer

/*
    isOpen: false,
    title: null,
    file: null,
    loadData: {
        pending: false,
        success: false,
        error: false
    }
*/