
import { getData } from "../../../services/api/Widgets/Gantt/getData.api";

const data: any = (database: any, cypher: any, params: any) => {

  return getData('system', database, cypher, params)
    .then(
      (response) => {
        return response.response;
      }
    )
    .catch(
      (error: any) => console.log(" ERROR gantt data", error)
    )

}

export default data;