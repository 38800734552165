const query = `
with permissions, widget, widget_map, element, id, user

with widget, widget_map
where widget_map.widget = 'Form'

optional match (propertyValue:Apps:PropertyValue)-[:for]->(property:Apps:Property)
where id(propertyValue) = toInteger($id)

optional match (propertyValue)-[:hasApproval]->(:System:AccessControl:Approval)-[:hasState]->(:Apps:State)<-[:current]-(validation:Apps:Validation)
optional match (validation)-[:hasLabel]->(label:Apps:Label)-[:hasType]->(type:Apps:Type)
optional match (label)-[:withName]->(term:Apps:Term)


with distinct
  widget,
  propertyValue,
  property,
  validation,
  collect(distinct {
    id: id(label),
    type: type.code,
    label: coalesce(case when not term is null then label.ID else label.name end, "")
  }) as label_maps

optional match (validation)-[:hasType]->(type:Apps:Type)

with distinct
  widget,
  propertyValue,
  property,
  collect(distinct {
    id: id(validation),
    type: type.code,
    rule: validation[type.code],
    labels: label_maps
  }) as validation_maps

optional match path=(property)-[:nextAtom*0..]->(:DataAtom)

with distinct
  widget,
  propertyValue,
  property,
  validation_maps,
  nodes(path)[-1] as dataAtom,
  length(path) - 1 as sequence
order by sequence desc

optional match (dataAtom)-[:isOfDataType]->(dataType:DataType)
optional match (dataAtom)-[:hasLabel]->(label:Label)
optional match (label)-[:withName]->(term:Apps:Term)
optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)

with distinct
  widget,
  apoc.coll.sortMaps(
    collect(distinct {
      type: dataType.name,
      label: coalesce(case when not term is null then label.ID else label.name end, ''),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      name: dataAtom.uuid,
      sequence: sequence,
      height: 1,
      hidden: false,
      download: false,
      attribute: {
        key: id(dataAtom),
        name: dataAtom.uuid
      },
      locked: false,
      validations: [validation_map in validation_maps where not validation_map.id is null]
    }),
    '^sequence'
  ) as fields

unwind fields as field

optional match (widget)-[hasSection_level1:hasSection]->(section_level1:Section)-[hasFieldSet_level1:hasFieldSet]->(fieldSet_level1:FieldSet)
optional match (fieldSet_level1)-[hasSection_level2:hasSection]->(section_level2:Section)-[hasFieldSet_level2:hasFieldSet]->(fieldSet_level2:FieldSet)
optional match (fieldSet_level2)-[hasField_level2:hasField]->(field_level2:Field)
optional match (field_level2)-[:for]->(attribute:Schema:Attribute)-[:isOfDataType]->(dataType:Schema:DataType)

optional match (node)
where id(node) = toInteger($id)

optional match (node)-[:hasApproval]->(:System:AccessControl:Approval)-[:hasState]->(state:Apps:State)
optional match (state)<-[:current]-(validation:Apps:Validation)-[:for]->(attribute)
optional match (validation)-[:hasLabel]->(label:Apps:Label)-[:hasType]->(type:Apps:Type)
optional match (label)-[:withName]->(term:Apps:Term)


with distinct
  widget,
  fields,
  field,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  hasSection_level2,
  section_level2,
  hasFieldSet_level2,
  fieldSet_level2,
  hasField_level2,
  field_level2,
  attribute,
  dataType,
  state,
  validation,
  collect(distinct {
    id: id(label),
    type: type.code,
    label: coalesce(case when not term is null then label.ID else label.name end, "")
  }) as label_maps

optional match (validation)-[:hasType]->(type:Apps:Type)

with distinct
  widget,
  fields,
  field,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  hasSection_level2,
  section_level2,
  hasFieldSet_level2,
  fieldSet_level2,
  hasField_level2,
  field_level2,
  attribute,
  dataType,
  state,
  collect(distinct {
    id: id(validation),
    type: type.code,
    rule: validation[type.code],
    labels: label_maps
  }) as validation_maps

optional match (field_level2)-[:hasLabel]->(label:Label)
optional match (label)-[:withName]->(term:Apps:Term)
optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)

with
  widget,
  fields,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  hasSection_level2,
  section_level2{
    sequence: coalesce(toInteger(hasSection_level2.sequence), toInteger(section_level2.sequence), 1)
  } as section_level2,
  fieldSet_level2{
    sequence: coalesce(toInteger(hasFieldSet_level2.sequence), toInteger(fieldSet_level2.sequence), 1),
    width: coalesce(toInteger(hasFieldSet_level2.width), toInteger(fieldSet_level2.width), 1)
  } as fieldSet_level2,
  case
    when coalesce(fieldSet_level2.type, 'static') = 'dynamic'
    then field
    else field_level2{
      type: dataType.name,
      label: coalesce(case when not term is null then label.ID else label.name end, ""),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      name: field_level2.name,
      sequence: coalesce(toInteger(hasField_level2.sequence), toInteger(field_level2.sequence), 1),
      height: coalesce(toInteger(hasField_level2.height), toInteger(field_level2.height), 1),
      hidden: apoc.convert.toBoolean(coalesce(hasField_level2.hidden, field_level2.hidden, false)),
      download: apoc.convert.toBoolean(coalesce(hasField_level2.download, field_level2.download, false)),
      locked: apoc.convert.toBoolean(coalesce(hasField_level2.locked, field_level2.locked, false)),
      attribute: {
        key: id(attribute),
        name: attribute.name
      },
      validations: [validation_map in validation_maps where not validation_map.id is null]
    }
  end as field_level2,
  apoc.convert.toBoolean(coalesce(hasField_level2.locked, field_level2.locked, false)) as locked_level2,
  state
order by
  hasSection_level2.sequence,
  hasFieldSet_level2.sequence,
  field_level2.sequence

with distinct
  widget,
  fields,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  section_level2,
  fieldSet_level2,
  case when not widget.type = "view" and fieldSet_level2.type = "static" then field_level2{.*, locked: locked_level2} else field_level2 end as field_level2,
  state

with distinct
  widget,
  fields,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  section_level2,
  fieldSet_level2{
    sequence: fieldSet_level2.sequence,
    fields: collect(distinct field_level2)
  } as fieldSet_level2,
  state

with distinct
  widget,
  fields,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  section_level2{
    sequence: section_level2.sequence,
    fieldSets: collect(distinct fieldSet_level2)
  } as section_level2,
  state

with distinct
  widget,
  fields,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  collect(distinct section_level2) as sections_level2,
  state

unwind fields as field

optional match (fieldSet_level1)-[hasField_level1:hasField]->(field_level1:Field)
optional match (field_level1)-[:for]->(attribute:Schema:Attribute)-[:isOfDataType]->(dataType:Schema:DataType)

optional match (state)<-[:current]-(validation:Apps:Validation)-[:for]->(attribute)
optional match (validation)-[:hasLabel]->(label:Apps:Label)-[:hasType]->(type:Apps:Type)
optional match (label)-[:withName]->(term:Apps:Term)

with distinct
  widget,
  fields,
  field,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  hasField_level1,
  field_level1,
  sections_level2,
  attribute,
  dataType,
  validation,
  collect(distinct {
    id: id(label),
    type: type.code,
    label: coalesce(case when not term is null then label.ID else label.name end, "")
  }) as label_maps

optional match (validation)-[:hasType]->(type:Apps:Type)

with distinct
  widget,
  fields,
  field,
  hasSection_level1,
  section_level1,
  hasFieldSet_level1,
  fieldSet_level1,
  hasField_level1,
  field_level1,
  sections_level2,
  attribute,
  dataType,
  collect(distinct {
    id: id(validation),
    type: type.code,
    rule: validation[type.code],
    labels: label_maps
  }) as validation_maps

optional match (field_level1)-[:hasLabel]->(label:Label)
optional match (label)-[:withName]->(term:Apps:Term)
optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)

with
  widget,
  section_level1{
    sequence: coalesce(toInteger(hasSection_level1.sequence), toInteger(section_level1.sequence), 1)
  } as section_level1,
  fieldSet_level1{
    sequence: coalesce(toInteger(hasFieldSet_level1.sequence), toInteger(fieldSet_level1.sequence), 1),
    width: coalesce(toInteger(hasFieldSet_level1.width), toInteger(fieldSet_level1.width), 1),
    sections: sections_level2
  } as fieldSet_level1,
  case
    when coalesce(fieldSet_level1.type, 'static') = 'dynamic'
    then field
    else field_level1{
      type: dataType.name,
      label: coalesce(case when not term is null then label.ID else label.name end, ""),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      name: field_level1.name,
      sequence: coalesce(toInteger(hasField_level1.sequence), toInteger(field_level1.sequence), 1),
      height: coalesce(toInteger(hasField_level1.height), toInteger(field_level1.height), 1),
      hidden: apoc.convert.toBoolean(coalesce(hasField_level1.hidden, field_level1.hidden, false)),
      download: apoc.convert.toBoolean(coalesce(hasField_level1.download, field_level1.download, false)),
      locked: apoc.convert.toBoolean(coalesce(hasField_level1.locked, field_level1.locked, false)),
      attribute: {
        key: id(attribute),
        name: attribute.name
      },
      validations: [validation_map in validation_maps where not validation_map.id is null]
    }
  end as field_level1,
  apoc.convert.toBoolean(coalesce(hasField_level1.locked, field_level1.locked, false)) as locked_level1
order by
  hasSection_level1.sequence,
  hasFieldSet_level1.sequence,
  field_level1.sequence

with distinct
  widget,
  section_level1,
  fieldSet_level1,
  case when not widget.type = "view" and fieldSet_level1.type = "static" then field_level1{.*, locked: locked_level1} else field_level1 end as field_level1

with distinct
  widget,
  section_level1,
  fieldSet_level1{
    sections: fieldSet_level1.sections,
    sequence: fieldSet_level1.sequence,
    fields: collect(distinct field_level1)
  } as fieldSet_level1

with distinct
  widget,
  section_level1{
    sequence: section_level1.sequence,
    fieldSets: collect(distinct fieldSet_level1)
  } as section_level1

with distinct
  widget,
  collect(distinct section_level1) as sections_level1

with distinct
  {
    sections: sections_level1,
    query: widget.cypher
  } as widget_subquery
  
return widget_subquery
`

export {query};