import axios from "axios";
const saltedMd5 = require("salted-md5");

export const login = async (username: string, password: string, version: string) => {
  const saltedHashAsync = await saltedMd5(username, password, true);

  return axios.post(process.env.REACT_APP_API_URL + "auth/signin", {
    username,
    password: saltedHashAsync,
    version
  });
};
