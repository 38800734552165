import * as React from 'react';
import './styles.css'
import { connect } from 'react-redux';
import { withRouterHooks } from '../../navigation/router.hooks';
import { setCustomContextMenu } from './services/setContextMenu';
import { run as runActions } from '../../store/actions/run';
import { setCommandItems } from './services/setCommandItems';
import { MenuEventArgs } from '@syncfusion/ej2-navigations';
import {
  fetchContextMenu as fetchContextMenuAction
} from '../../store/getContextMenu/action';
import MenuForm from '../../components/MenuForm/MenuForm';
/* syncfusion imports*/
import { getValue } from '@syncfusion/ej2-base';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  IFilter,
  ContextMenu,
  Edit,
  Filter,
  Group,
  Inject,
  Page,
  Sort,
  Selection,
  ExcelExport,
  PdfExport,
  PageSettingsModel,
  Resize,
  RowDD,
  Toolbar,
  FilterSettingsModel,
  CommandColumn,
  InfiniteScrollSettingsModel,
  InfiniteScroll
} from '@syncfusion/ej2-react-grids';
import { withTranslation } from 'react-i18next';

class GridWidgetSync extends React.Component<any, any>{

  constructor(props) {
    super(props);
    this.selIndex = [];
    this.state = {
      gridEditData: [],
      commands: null
    }
  }

  grid: GridComponent | null;
  selIndex;
  customAttributes: any = { class: 'customcss' };
  infiniteOptions: InfiniteScrollSettingsModel = { enableCache: true };
  filterMenu: IFilter = {
    type: 'CheckBox',
    operator: "contains"
  }
  pageOptions: PageSettingsModel = {
    pageSize: this.props.navData ? this.props.navData.widgetData.pageSize : null,
    pageSizes: ["5", "10", "20", "50"]
  };
  filterSettingsMenu: FilterSettingsModel = {
    showFilterBarOperator: true,
    mode: 'Immediate',
    immediateModeDelay: 400
  }
  filterSettingsExcel: FilterSettingsModel = {
    showFilterBarOperator: true,
    mode: 'Immediate',
    immediateModeDelay: 400,
    type: 'Excel'
  }

  contextMenuItems = this.props.navData ? setCustomContextMenu(this.props.navData) : null
  commandColumnItems

  componentDidMount() {
    this.setState({ commands: this.props.navData ? setCommandItems(this.props.navData) : null })
  }

  componentDidUpdate(prevProps) {
    // this.setState({ commands: setCommandItems(this.props.navData) })
  }

  componentWillUnmount() {
    this.clearData();
  }

  clearData() {
    this.setState({
      gridRenderData: null,
      gridEditData: [],
      commands: null
    })
  }

  contextMenuClick(args: MenuEventArgs) {
    const { oldActionsParams } = this.props

    let flag: boolean = false

    this.contextMenuItems.map((item: any) => {
      switch (this.grid && args.item.id) {
        case 'grid_excelexport':
          if (flag === false) { this.grid.excelExport() }
          flag = true
          break;

        case 'grid_pdfexport':
          if (flag === false) { this.grid.pdfExport() }
          flag = true
          break;

        case 'grid_print':
          if (flag === false) { this.grid.print() }
          flag = true
          break;

        case item.id:
          const selectedRecord: any = args;
          const eventKey: any = item.id
          const itemID = parseInt(selectedRecord.rowInfo.rowData.id);
          runActions(eventKey, itemID, { ...oldActionsParams, target: selectedRecord.rowInfo.rowData }, null)
          break;

        default:
          return null
      }
      return null
    })
  }

  getBatchEditData = () => {

    const instance: any = this

    let batchChanges: any = instance.grid.getCurrentViewRecords()
    let changedData = Object.assign(this.props.$this.state.gridData, batchChanges)
    return changedData
  }

  created = () => {
    this.setState({ gridEditData: this.props.$this.state.gridData })
  }

  dataBound(args: any) {
    if (this.grid && this.selIndex.length) {
      this.grid.selectRows(this.selIndex);
      this.selIndex = [];
    }
  }

  rowDataBound(args) {
    if (getValue('selected', args.data) === true) {
      this.selIndex.push(parseInt(args.row
        .getAttribute('data-rowindex'), 0));
    }
  }

  actionComlepte = (args) => {
    // batch edit
    if (args.action === 'edit') {
      const element = args.data
      const newEditData = this.state.gridEditData.map(obj => {
        if (obj.id === element.id) {
          return { ...element };
        }
        return obj;
      });
      this.setState({ gridEditData: newEditData })
    }
  }

  render() {
    const { navData, oldActionsParams, $this, gridRenderData } = this.props
    const { commands } = this.state;

    if (!gridRenderData || !$this.state.gridData) {
      return null
    }
    let flag = false;
    this.contextMenuClick = this.contextMenuClick.bind(this);

    return (
      <div className='widget-container'>
        {
          gridRenderData.menu
            ? <MenuForm
              key={`menu-${gridRenderData.menu.id}`}
              getWidgetEditData={this.getBatchEditData.bind(this)}
              menu={gridRenderData.menu}
              currentDataID={gridRenderData.dataID}
              properties={this.state.gridEditData}
              oldActionsParams={oldActionsParams}
              viewType={gridRenderData.type}
              widgetType={gridRenderData.widget}
            />
            : null
        }
        <h3 className='widget-label'>{this.props.t(gridRenderData.label)} </h3>
        <div className="widget-content">
          <GridComponent
            id={`grid-${gridRenderData.key}`}
            ref={g => this.grid = g}
            key={`grid-${gridRenderData.key}`}
            height={this.props.height ? this.props.height : "100%"}
            rowHeight={28}
            className={`grid-widget${gridRenderData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
            dataSource={this.props.gridData}
            // settings
            allowExcelExport={true}
            allowPdfExport={true}
            allowFiltering={true}
            allowResizing={true}
            allowSelection={true}
            allowPaging={navData ? navData.widgetData.allowPaging : null}
            enableInfiniteScrolling={navData ? !navData.widgetData.allowPaging : null}
            allowSorting={this.props.allowSorting ? this.props.allowSorting : true}
            allowRowDragAndDrop={this.props.allowRowDragAndDrop}
            // settings costum
            editSettings={this.props.editSettings}
            filterSettings={this.filterSettingsMenu}
            selectionSettings={this.props.selectionSettings}
            contextMenuItems={this.contextMenuItems}
            pageSettings={this.pageOptions}
            infiniteScrollSettings={!this.props.allowPaging ? this.infiniteOptions : null}
            // events
            rowDragStart={function (args) {
              flag = true
            }
            }
            created={this.created.bind(this)}
            rowDrop={this.props.onRowDrag ? this.props.onRowDrag.bind(this) : null}
            rowSelected={this.props.rowSelected ? this.props.rowSelected.bind(this) : null}
            rowDeselected={this.props.rowDeselected ? this.props.rowDeselected.bind(this) : null}
            rowSelecting={this.props.rowSelecting ? this.props.rowSelecting.bind(this) : null}
            cellSelected={this.props.cellSelected}
            commandClick={this.props.commandClick ? this.props.commandClick : null}
            contextMenuClick={this.contextMenuClick.bind(this)}
            dataBound={this.dataBound.bind(this)}
            rowDataBound={this.rowDataBound.bind(this)}
            rowDropSettings={this.props.rowDropSettings ? this.props.rowDropSettings : null}
            actionComplete={this.actionComlepte.bind(this)}
          >
            <ColumnsDirective>
              {gridRenderData.type === 'link' ?
                <ColumnDirective
                  type='checkbox'
                  width='24px'
                  customAttributes={this.customAttributes}
                  allowResizing={false}
                /> : null}

              {gridRenderData.columns.map((column) => {
                switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        customAttributes={{ class: [this.customAttributes.class, 'code-cell'] }}
                        commands={commands}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        // customAttributes={this.customAttributes}
                        customAttributes={column.name === 'id' ? { class: [this.customAttributes.class, 'id-cell'] } : this.customAttributes}
                        headerText={this.props.t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                        filter={this.filterMenu}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        edit={this.props.getEditTypeCell ? this.props.getEditTypeCell(column, this.props.dropdownElement, this.props.numericCell) : null}
                        isPrimaryKey={column.name === 'id'}
                        allowEditing={this.props.getEditSelectable ? this.props.getEditSelectable(column) : null}
                        editType={this.props.getEditType ? this.props.getEditType(column) : null}
                        type={"string"}
                      />
                    )
                }
              })}
            </ColumnsDirective>
            <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, InfiniteScroll]} />
          </GridComponent>
        </div>
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchContextMenu: (callerID: number) => dispatch(fetchContextMenuAction(callerID))
})

export default connect(
  null,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(GridWidgetSync)))