import axios from "axios";
import { logger } from "../../logger";

export const fileServiceUpload = async (file: any, directory: string) => {

    logger.log(process.env.REACT_APP_API_URL + 'fileService/upload')
  
    var formData = new FormData();
    formData.append('directory', directory) //'uploads');
    formData.append("file", file);
  
    return axios.post(process.env.REACT_APP_API_URL + 'fileservice/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      //onUploadProgress: progressEvent => dispatch(setProgressUploadFile(progressEvent.loaded / progressEvent.total * 100))
    });
  }