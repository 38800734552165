import React from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { withTranslation } from "react-i18next";

class ListField extends React.Component<any, any> {

  private dropdownInstance: DropDownListComponent;
  flag = true


  sortOptions(x, y) {
    return x.name.localeCompare(y.name);
  }

  onChange = (args: any) => {
    const { setFormEditData, options } = this.props

    const findOption = options.find(o => o.id === args?.itemData?.id ?? null)

    setFormEditData(findOption)
  }

  render() {
    const { field, isLocked, setFormEditData, hiddenClass, options } = this.props

    const sortedOptions = options?.sort(this.sortOptions)
    const initData = options?.find(o => o.selected === true)
    const placeholder = initData ? initData.label : 'Select something'

    if (initData) {
      if (this.flag === true) {
        setTimeout(function () {
          setFormEditData(initData);
        }, 500);
      }
      this.flag = false
    }

    return (
      <div className={`form-input-container listfield ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element'
        // style={{ backgroundColor: setColorField() }}
        >
          <DropDownListComponent
            dataSource={sortedOptions}
            fields={{ text: 'label', value: 'label' }}
            ref={(scope) => { (this.dropdownInstance as DropDownListComponent | null) = scope; }}
            className='form-input-element'
            placeholder={placeholder}
            value={initData ? initData?.label: ''}
            change={this.onChange.bind(this)}
            enabled={!isLocked}
          />
        </div>
      </div>
    )
  }
}
export default (withTranslation()(ListField));