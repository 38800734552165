import { GET_THEMES_PENDING, GET_THEMES_SUCCESS, GET_THEMES_ERROR } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_THEMES_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
        data: null
      }

    case GET_THEMES_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data,
      }

    case GET_THEMES_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        data: null
      }

    default:
      return state
  }
}

export default reducer