import React from 'react'
import { Grid, Header, Message, Segment, Label } from 'semantic-ui-react';
import { Formsy } from 'formsy-react/dist/Formsy';
import { Form } from 'formsy-semantic-ui-react';
// import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { loginUser } from '../../store/auth/action'
import { ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
import { notifyError } from '../../components/Toasts/toasts';
import { withRouterHooks } from '../../navigation/router.hooks';
import './styles.css'

const version = require('../../../package.json').version;

class LoginPage extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      adminPassH: '7061737323313233',
      user: null,
      username: '',
      password: '',
      path: '#'
    }

  }

  private formRef = React.createRef<Formsy>();

  componentDidMount() {
    if (this.getSessionToken()) {
      window.location.href = "/app"
    }
  }

  getSessionToken = () => {
    return (localStorage.getItem('connection'));
  }

  handleSubmit = () => {
    this.props.loginUser(this.state.username, this.state.password);
  }

  handelLinkClick = () => {
    if (this.state.username === '') {
      return notifyError("To reset your password please enter your user name")
    } else {
      this.props.history("/request-password")
    }
    return
  }



  render() {
    const logo = require('../../images/logo.png')
    const { Error, Messages } = this.props
    const errorLabel = <Label color="red" pointing />
    
    return (
      <>
        <Grid textAlign="center" style={{ margin: '0px', padding: '0px', height: '100vh', backgroundColor: `hsl(360, 100%, 100%)` }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <img src={logo} alt="Logo" height={150} width={200} />
            <Header as="h1" textAlign="center" className="my-title">
              Login to your account
            </Header>
            <Form
              size="large"
              ref={this.formRef}
              onValidSubmit={this.handleSubmit}
            >
              <Segment>
                <Form.Input
                  name="userName"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="User name"
                  key="user"
                  value={this.state.username}
                  onChange={(e) => {
                    if (e.target.value.length !== 0) {
                      this.setState({ path: '/request-password' })
                    } else {
                      this.setState({ path: '#' })
                    }
                    this.setState({ username: e.target.value });
                    localStorage.setItem("user", e.target.value);
                  }}
                  required
                  validationErrors={{ isDefaultRequiredValue: 'please fill in your user name' }}
                  errorLabel={errorLabel}
                  type=''
                />
                <Form.Input
                  name="password"
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  key="userPassword"
                  value={this.state.password}
                  onChange={(e) => { this.setState({ password: e.target.value }) }}
                  required
                  validationErrors={{ isDefaultRequiredValue: 'please fill in your password' }}
                  errorLabel={errorLabel}
                />
                <Form.Group>
                  <Form.Button content="Login" color="green" />
                  <Form.Button
                    type="button"
                    content="Reset"
                    onClick={() => this.formRef.current?.reset()}
                  />
                </Form.Group>
              </Segment>
            </Form>
            {Error ? <Message color="red">{Messages.msg}</Message> : null}
            <Message>For a new account call your administrator</Message>
            <Link to={this.state.path}
              onClick={() => this.handelLinkClick()}
            >Forgot your password?</Link>
            <footer>
              <div className="version-tag">graph-designer v{version}</div>
            </footer>
          </Grid.Column>
        </Grid>
        <ToastContainer />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  Pending: state.auth.Pending,
  Error: state.auth.Error,
  Messages: state.auth.Message,
})

const mapDispatchToProps = (dispatch) => ({
  loginUser: (user, userPassword) => dispatch(loginUser(user, userPassword, version)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks((LoginPage)))