import './styles.css'
import * as React from 'react';
import { connect } from 'react-redux'
import { DiagramComponent, SymbolPaletteComponent, NodeModel, UndoRedo, ConnectorModel, Inject, SnapSettingsModel, SnapConstraints, Snapping, DiagramContextMenu, PrintAndExport, Node, SnapSettings } from '@syncfusion/ej2-react-diagrams';
import { ItemDirective, ItemsDirective, ToolbarComponent, ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { withRouterHooks } from '../../navigation/router.hooks';
import { withTranslation } from 'react-i18next';
import MenuForm from '../../components/MenuForm/MenuForm';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { PageSettingsModel } from '@syncfusion/ej2-react-grids';

class DiagramWidget extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      diagram_data: null,
      connectors: null,
      shapes: null
    }
    let content = JSON.parse(this.props.navData.widgetData.content);
    this.nodes = content?.nodes ?? [];
    this.connectors = content?.connectors ?? [];
    this.pageSettings = content?.pageSettings;
  }
  nodes: NodeModel[];
  connectors: ConnectorModel[];
  pageSettings: any;
  diagramInstance: any;
  isMobile: any;

  componentDidMount() {
    const { navData } = this.props
    if (navData) {
      this.addEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.runActionsState.data.params?.name !== runActionsState.data.params?.name || prevProps.runActionsState.data.timestamp !== runActionsState.data.timestamp) {
    }
  }

  getContent = () => {
    var serializer = new XMLSerializer();
    var svg: string = Buffer.from(serializer.serializeToString(this.diagramInstance.exportDiagram({ mode: 'Data', format: 'SVG' }))).toString('base64');
    //var svg: string = encodeURIComponent(serializer.serializeToString(this.diagramInstance.exportDiagram({ mode: 'Data', format: 'SVG' })));
    var response: any = {
      png: this.diagramInstance.exportDiagram({ mode: 'Data', format: 'PNG' }),
      svg: `data:image/svg+xml;base64,${svg}`,
      content: this.diagramInstance.saveDiagram()
    };
    return response;
    // setFormEditData = (item) => {
    //   const { formEdit_data } = this.state;
    //   var index = formEdit_data.findIndex(x => x._key === item._key);

    //   // TODO: find reason for missing entries inside formEdit_data
    //   if (index === -1) {
    //     formEdit_data.push(item);
    //   } else {
    //     formEdit_data[index] = item;
    //   }

    //   this.setState({ formEdit_data: formEdit_data });
    // }
    // runAction({ type: 'Query', query: 'match (diagram:Diagram) where id(diagram) = $id set diagram.content = $content return diagram{id: $id, content: diagram.content} as response' }, params, null, true)
    //   .then(() => {
    //     alert('diagram saved');
    //   })
  }

  getPalettes(): [] {
    const { navData } = this.props;
    return navData?.widgetData?.template?.palettes ?? [];
  }

  addEvents() {
    this.isMobile = window.matchMedia('(max-width:550px)').matches;
    if (this.isMobile) {
      let paletteIcon = document.getElementById('palette-icon');
      if (paletteIcon) {
        paletteIcon.addEventListener('click', this.openPalette, false);
      }
    }
  }
  openPalette() {
    let paletteSpace = document.getElementById('palette-space');
    this.isMobile = window.matchMedia('(max-width:550px)').matches;
    if (this.isMobile) {
      if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
        paletteSpace.classList.add('sb-mobile-palette-open');
      }
      else {
        paletteSpace.classList.remove('sb-mobile-palette-open');
      }
    }
  }

  alignNodes(args: ClickEventArgs) {
    this.diagramInstance.align(args, this.diagramInstance.selectedItems.nodes, 'Selector');
    this.diagramInstance.dataBind();
  }

  alignAnnotationsHorizontal(args: ClickEventArgs) {
    this.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            annotation.horizontalAlignment = args;
          }
        )
      }
    )
    this.diagramInstance.dataBind();
  }

  alignAnnotationsVertical(args: ClickEventArgs) {
    this.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            annotation.verticalAlignment = args;
          }
        )
      }
    )
    this.diagramInstance.dataBind();
  }

  toggleSnapping(args) {
    if (args.target.checked) {
      this.diagramInstance.snapSettings = { constraints: SnapConstraints.All };
    } else {
      this.diagramInstance.snapSettings = { constraints: SnapConstraints.None };
    }
    this.diagramInstance.dataBind();
  }

  changeBackgroundColor(args) {
    this.diagramInstance.selectedItems.nodes.map(
      node => {
        node.style.fill = args.value
      }
    )
    this.diagramInstance.dataBind();
  }

  changeTextColor(args) {
    this.diagramInstance.selectedItems.nodes.map(
      node => {
        node.annotations.map(
          annotation => {
            console.log(annotation.style.color, args);
            annotation.style.color = args.value;
          }
        )
      }
    )
    this.diagramInstance.dataBind();
  }

  bringToFront(args) {
    this.diagramInstance.bringToFront();
  }

  sendToBack(args) {
    this.diagramInstance.sendToBack();
  }

  bringForward(args) {
    this.diagramInstance.bringForward();
  }

  sendBackward(args) {
    this.diagramInstance.sendBackward();
  }

  render() {
    const { navData, oldActionsParams } = this.props
    try {
      return (
        <div className='widget-container'>
          {
            navData.widgetData.menu
              ? <MenuForm
                key={`menu-${navData.widgetData.menu.id}`}
                getWidgetEditData={this.getContent.bind(this)}
                menu={navData.widgetData.menu}
                currentDataID={navData.widgetData.dataID}
                oldActionsParams={oldActionsParams}
                viewType={navData.widgetData.type}
                widgetType={navData.widgetData.widget}
              />
              : null
          }
          <h3 className='widget-label'>{this.props.t(navData.widgetData.label)} </h3>
          <ToolbarComponent id={`diagram-toolbar-${navData.widgetData.key}`} className='diagram-toolbar'>
            <ItemsDirective>
              <ItemDirective template={() => { return (<CheckBoxComponent label='show grid' checked={true} onChange={this.toggleSnapping.bind(this)} />) }} />
              <ItemDirective type='Separator' />
              <ItemDirective prefixIcon='e-align-bottom-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Left')} />
              <ItemDirective prefixIcon='e-align-top-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Right')} />
              <ItemDirective prefixIcon='e-align-middle-3' cssClass='rotate-90' click={this.alignNodes.bind(this, 'Center')} />
              <ItemDirective type='Separator' />
              <ItemDirective prefixIcon='e-align-top-3' click={this.alignNodes.bind(this, 'Top')} />
              <ItemDirective prefixIcon='e-align-bottom-3' click={this.alignNodes.bind(this, 'Bottom')} />
              <ItemDirective prefixIcon='e-align-middle-3' click={this.alignNodes.bind(this, 'Middle')} />
              <ItemDirective type='Separator' />
              <ItemDirective prefixIcon='e-align-left' click={this.alignAnnotationsHorizontal.bind(this, 'Right')} />
              <ItemDirective prefixIcon='e-align-right' click={this.alignAnnotationsHorizontal.bind(this, 'Left')} />
              <ItemDirective prefixIcon='e-align-center' click={this.alignAnnotationsHorizontal.bind(this, 'Center')} />
              <ItemDirective type='Separator' />
              <ItemDirective prefixIcon='e-align-left' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Bottom')} />
              <ItemDirective prefixIcon='e-align-right' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Top')} />
              <ItemDirective prefixIcon='e-align-center' cssClass='rotate-90' click={this.alignAnnotationsVertical.bind(this, 'Center')} />
              <ItemDirective type='Separator' />
              <ItemDirective prefixIcon='e-bring-to-front' click={this.bringToFront.bind(this)} />
              <ItemDirective prefixIcon='e-send-to-back' click={this.sendToBack.bind(this)} />
              <ItemDirective prefixIcon='e-bring-forward' click={this.bringForward.bind(this)} />
              <ItemDirective prefixIcon='e-send-backward' click={this.sendBackward.bind(this)} />
              <ItemDirective type='Separator' />
              <ItemDirective template={() => { return (<div><span className="e-label diagram-toolbar label">background:</span><ColorPickerComponent value={'#cfcfcf'} change={this.changeBackgroundColor.bind(this)} /></div>) }} />
              <ItemDirective template={() => { return (<div><span className="e-label diagram-toolbar label">text:</span><ColorPickerComponent value={'#000'} change={this.changeTextColor.bind(this)} /></div>) }} />
            </ItemsDirective>
          </ToolbarComponent>
          <div className='widget-content'>
            <div className='sb-mobile-palette-bar'>
              <div id='palette-icon' style={{ float: 'right' }} className='e-ddb-icons1 e-toggle-palette'></div>
            </div>
            <div id='palette-space' className='sb-mobile-palette'>
              <SymbolPaletteComponent
                id='symbolpalette'
                expandMode='Multiple'
                palettes={this.getPalettes()}
                width={'100%'}
                height={'100%'}
                symbolHeight={60}
                symbolWidth={60}
                getNodeDefaults={(symbol) => {
                  symbol.width = 50;
                  symbol.height = 50;
                  symbol.style.strokeColor = '#757575';
                }}
                symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }}
                getSymbolInfo={(symbol) => {
                  return { fit: true };
                }}
              />
            </div>
            <div id='diagram-space' className='sb-mobile-diagram'>
              <DiagramComponent
                id={`diagram-${navData.widgetData.key}`}
                ref={diagram => (this.diagramInstance = diagram)}
                width={'100%'}
                height={'100%'}
                snapSettings={{ constraints: SnapConstraints.All }}
                pageSettings={this.pageSettings}
                nodes={this.nodes}
                connectors={this.connectors}
              // getNodeDefaults={(node: any) => {
              //   let obj: any = {};
              //   if (obj.width === undefined) {
              //     obj.width = 145;
              //   }
              //   else {
              //     let ratio = 100 / obj.width;
              //     obj.width = 100;
              //     obj.height *= ratio;
              //   }
              //   obj.style = { fill: '#357BD2', strokeColor: 'white' };
              //   obj.annotations = [
              //     { style: { color: 'white', fill: 'transparent' } }
              //   ];
              //   return obj;
              // }}
              // getConnectorDefaults={(obj) => {
              //   if (obj.id.indexOf('connector') !== -1) {
              //     obj.type = 'Orthogonal';
              //     obj.targetDecorator = {
              //       shape: 'Arrow',
              //       width: 10,
              //       height: 10
              //     };
              //   }
              // }}
              >
                <Inject services={[UndoRedo, Snapping, DiagramContextMenu, PrintAndExport]} />
              </DiagramComponent>
            </div>
          </div>
        </div>
      );
    }
    catch {
      err => {
        console.error(err);
      }
    }

  }
}

const mapStateToProps = (state: any) => ({
  navigation: state.navigation,
  getState: state.getState,
  runActionsState: state.actions
})

const mapDispatchToProps = (dispatch: any) => ({
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(DiagramWidget)))