import cytoscape from 'cytoscape';
import $ from 'jquery';
import contextMenus from 'cytoscape-context-menus';
import { run as runActions } from '../../../../store/actions/run';
import store from '../../../../store/store';
import { run as runAction } from '../../../../services/api/action/run.api';
import { query as getStyleQuery } from '../../../../services/queries/getStyleQuery';

cytoscape.use(contextMenus, $);

export const contextMenu = (cy: any, contextData: any, fetchStateAction: any, currentDataID: any, setNavigation: any, renderData: any, addNodesCytoscape: any, removeNode: any, translate: any) => {

  let menuItems = contextData.elements.map((element: any) => (
    {
      id: element.key,
      content: translate(element.label),
      selector: 'node',
      show: true,
      onClickFunction: async (item) => {
        const eventKey = element.events?.find(event => event.type === 'onClick')?.key;

        if (!item) return null

        runActions(eventKey, item.target.data().id, item.target._private.data, null)
        setTimeout(function () {
          let data = store.getState().actions?.data?.params
          if (data?.url ?? null !== null) {
            let pathname = new URL(data?.url ?? null).pathname;
            switch (pathname) {
              case "/api/services/widgets/graph/add-nodes":
                return runAction({ type: 'StoredProcedure', id: 'get style', query: getStyleQuery }, { ...data, id: data.id })
                  .then((response: any) => addNodesCytoscape(response?.response?.objects, item.position))

              case "/api/services/widgets/graph/remove-nodes":
                return removeNode(data.params)

              default:
                return null
            }
          } else {
            return null;
          }
        }, 800);
      }
    }
  ))

  return cy.contextMenus({ menuItems: menuItems });
}