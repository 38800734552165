import React from 'react'
import LayoutWidget1 from '../../pages/modulePage/layoutWidgets/LayoutWidget'
import { getStateRemoveModalWidget } from '../../store/getState/action';
import { Modal as SModal } from 'semantic-ui-react'
import store from '../../store/store';
import { withTranslation } from 'react-i18next';
import './styles.css'

class Modal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const { widget, oldActionsParams } = this.props

    return (
      <SModal
        open={true}
        centered={true}
        closeOnDimmerClick={false}
        id={`form-${widget.key}`}
        className='form'
        height={widget.height}
        width={widget.width}
        style={{
          height: widget.height,
          width: widget.width,
          overflow: "hidden"
        }}
      >
        <SModal.Header>
          <div
            className={`e-icons e-close label-icon modal-close-icon`}
            onClick={() => { store.dispatch(getStateRemoveModalWidget(widget.key, widget.dataID)) }}
          />
        </SModal.Header>
        <SModal.Content>
          <SModal.Description>
            <LayoutWidget1
              column={widget.position.column}
              row={widget.position.row}
              widget={widget}
              oldActionsParams={oldActionsParams}
            />
          </SModal.Description>
        </SModal.Content>
      </SModal>
    )
  }
}


export default withTranslation()(Modal);