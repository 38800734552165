type getThemesState = {
    pending: boolean,
    success: boolean,
    error: boolean,
    data: Array<any>
  }
  
  const initialState: getThemesState = {
    pending: false,
    success: false,
    error: false,
    data: []
  }
  
  export default initialState
  