import React from "react";
import GridWidgetSync from "./Grid";
import { withRouterHooks } from "../../navigation/router.hooks";
import {
  RowDeselectEventArgs,
  RowSelectEventArgs,
  SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";
import fetchGridDataData from "./services/fetchGridData";
import { logger } from "../../services/logger";

class GridWidgetLink extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      gridData: null
    }
  }

  selectionSettings: SelectionSettingsModel = {
    persistSelection: true
  };
  isPrimaryKey: boolean = true
  allowSorting: boolean = false

  componentDidMount() {
    const { navData, oldActionsParams, runActionsState, $this } = this.props

    fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
      .then((response) => {
        this.setState({ gridData: response.targets })
        logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
      }
      );
  }

  componentDidUpdate(prevProps) {
    const { navData, oldActionsParams, runActionsState, $this } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ gridData: null })
      fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
        .then((response) => {
          this.setState({ gridData: response.targets })
          logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
        })
    }
  }

  linkElements(instance: any, args: any, link: boolean) {
    const { rowSelection } = this.props.navData.widgetData;

    let elements = [...this.state.gridData];

    if (!instance.grid) return null

    if (rowSelection === 'single' && args.name === 'rowDeselected' && args.isInteracted) {
      elements.map((element: any) => {
        element.selected = false
      })
      this.setState({ gridEditData: elements });
      return null
    }

    if (args.isHeaderCheckboxClicked) {
      elements?.map(
        row => {
          let index = elements.findIndex((element) => element.id === row.id);
          let element = elements[index];
          element.selected = args.name === "rowDeselected" ? false : true;
          elements[index] = element;
        }
      );
      this.setState({ gridEditData: elements });
      return null
    }

    if (rowSelection === 'single' || !args.isInteracted) {
      args.cancel = true;
      return null;
    }

    const row: any = (args.target.closest("tr"));
    const cell = row.querySelector("td.id-cell");
    const id = Number.parseFloat(cell.innerHTML);

    let index = elements.findIndex((element) => element.id === id);
    let element = elements[index];
    element.selected = link;
    elements[index] = element;

    this.setState({ gridEditData: elements });
  }


  rowSelecting(args: any) {
    const { navData, $this } = this.props

    let elements = [...$this.state.gridData];
    const instance: any = this

    if (!instance.grid || !args.isInteracted) return null

    if (navData.widgetData.rowSelection === 'single') {
      instance.grid.clearSelection();

      elements.map((element: any) => {
        element.selected = false
      })

      const row: any = (args.target.closest("tr"));
      const cell = row.querySelector("td.id-cell");
      const id = Number.parseFloat(cell.innerHTML);
      let index = elements.findIndex((element) => element.id === id);
      let element = elements[index];
      element.selected = true;
      elements[index] = element;
    }
  }

  rowSelected(args: RowSelectEventArgs) {
    this.props.$this.linkElements(this, args, true)
  }


  rowDeselected(args: RowDeselectEventArgs) {
    this.props.$this.linkElements(this, args, false)
  }

  render() {
    const { navData, runActionsState, oldActionsParams } = this.props;

    return (
      <GridWidgetSync
        $this={this}
        navData={navData}
        runActionsState={runActionsState}
        oldActionsParams={oldActionsParams}
        rowSelected={this.rowSelected}
        rowDeselected={this.rowDeselected}
        selectionSettings={this.selectionSettings}
        rowSelecting={this.rowSelecting}
        isPrimaryKey={this.isPrimaryKey}
        allowSorting={this.allowSorting}
        gridData={this.state.gridData}
        gridRenderData={navData.widgetData}
      />
    );
  }
}

export default withRouterHooks((GridWidgetLink))
