import * as React from 'react';
import { run as runQuery } from '../../../../../../../services/api/query/run.api';
import { query as getElementsQuery } from '../Queries/getPagesQuery'
import { query as getExistingElementsQuery } from '../Queries/getExistingPagesQuery'
import GridWidgetSync from '../../../../../../grid/Grid'
import { RowSelectEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-react-grids';
import { connect } from 'react-redux';


class LinkGrid extends React.Component<any, any>{

  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridRenderData: {
        columns: [
          { name: 'target.id', label: 'id', field: "target.id", hide: false, minWidth: 50 },
          { name: 'text', label: 'name', field: "text", hide: false, minWidth: 150 }
        ],
        type: 'link'
      },
      inputValue: ''
    }
  }

  selectionSettings: SelectionSettingsModel = {
    persistSelection: true,
    type: 'Single'
  };


  componentDidMount() {
    this.setState({ inputValue: this.props.modals.selectedText })
    this.getData(this.props.tabType);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.tabType !== this.props.tabType) {
      this.getData(this.props.tabType);
    }
  }

  getData(tabType: any) {
    runQuery(
      'system',
      tabType === 'existing' ? getExistingElementsQuery(['Apps', 'Section']) : getElementsQuery(['Apps', 'Section']),
      this.props.params
    )
      .then((response) => {
        console.log(
          `%c RichtextDialog: Link grid (${tabType === 'existing' ? 'existing' : 'new'}) %c`,
          `background: #ff8800`,
          'background: transparent;',
          {
            query: tabType === 'existing' ? getExistingElementsQuery(['Apps', 'Section']) : getElementsQuery(['Apps', 'Section']),
            params: this.props.params,
            response: response.response
          }
        )
        const arr = Object.values(response.response.elements)
        const results = arr.filter(element => {
          return element !== null;
        });
        return this.setState({ gridData: results });

      });
  }


  rowSelected(args: RowSelectEventArgs) {
    this.props.$this.props.$this.setState({ selectedElement: args.data })
  }

  rowSelecting(args: any) {
    const instance: any = this
    instance.grid.clearSelection();
  }

  render() {
    const { gridRenderData, gridData } = this.state;

    if (!gridData) return null

    return (
      <>
        {this.props.tabType === 'new' ?
          <input
            className="e-input textbox-link-text"
            type="text"
            id="textbox"
            placeholder="Text"
            onChange={(e: any) => {
              this.setState({ inputValue: e.target.value })
            }}
            value={this.state.inputValue}
            style={{ width: '50%' }}
          />
          : null}
        <GridWidgetSync
          $this={this}
          gridData={gridData}
          gridRenderData={gridRenderData}
          selectionSettings={this.selectionSettings}
          rowSelected={this.rowSelected}
          rowSelecting={this.rowSelecting}
          height={'550px'}
        />
      </>
    )
  }
};

const mapStateToProps = (state: any) => ({
  modals: state.modals
})

export default connect(
  mapStateToProps,
  null
)(LinkGrid)