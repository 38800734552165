var CryptoJS = require("crypto-js");

export const service = (code: string, key: string) => {
    try {
      var bytes  = CryptoJS.AES.decrypt(code, key);
      return {text: bytes.toString(CryptoJS.enc.Utf8)};
    }
    catch (error: any) {
      return {text: null};
    }
}