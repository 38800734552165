import React from 'react'
import { withRouterHooks } from '../../navigation/router.hooks';
import ContextList from './Context.list'
import './styles.css'

class ContextWidget extends React.Component<any, any> {
  constructor(props) {
    super(props)
        this.state = {}
  }

  componentDidMount() {}
  componentDidUpdate() {}
  componentWillUnmount(){}

  render() {
    const { navData } = this.props;
    return (
      <ContextList
        elementList={navData.widgetData.elements}
        currentDataID={navData.widgetData.dataID || null}
        timestamp={navData.widgetData.timestamp}
      />
    )
  }
}

export default (withRouterHooks(ContextWidget))