import { checkConnection } from "../../../../../services/api/driver";

const tapEdge = (cy: any, widgetEvents: any, runActions: any) => {

    checkConnection(localStorage.getItem("connection"))

    return cy.on('tap', 'edge', async function (e) {

        const element = e.target;
        
        element.addClass('selected');
        cy.elements()
          .difference(element)
          .removeClass('selected');

        const id = parseInt(element.data().id);
        const key = widgetEvents?.find(event => event.type === 'onClick').key;

        if (!key) {
            alert('tapEdge: kein event gefunden')
            return null;
        }
        runActions(key, id, {});
    });

}

export default tapEdge;
