import React from 'react'
import { connect } from 'react-redux'
import { withRouterHooks } from '../../../../navigation/router.hooks';
import { getNavigation } from '../../../../store/getNavigation/action'
import { getModules } from '../../../../store/getModules/action'
import { withTranslation } from 'react-i18next';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { run as runActions } from '../../../../store/actions/run';
import './styles.css'
import { clearBreadcrumb } from '../../../../store/breadcrumb/action';
import store from '../../../../store/store';
import { logger } from '../../../../services/logger';

class ModulesSelect extends React.Component<any, any> {

  dropdownInstance: DropDownListComponent;
  fields: object = {
    text: 'label',
    value: 'key'
  };

  componentDidMount() {
    const { getModules } = this.props;
    try {
      getModules();
    } catch (error) {
      logger.error("<ModulesSelect/> getModules error:  ", error)
    }
  }

  onChangeModule = (args: any) => {
    const { getNavigation } = this.props;

    if (!args.value) return null

    const eventkey = args.itemData.events?.find(event => event?.type === 'onLoad')?.key

    if (eventkey) {
      store.dispatch(clearBreadcrumb())
      runActions(eventkey, args.itemData.key, null, null)
    }
    getNavigation(args.value);
  }


  render() {
    const { themes, data } = this.props;

    if (data.length !== 0 && data) {
      data.modules.forEach((element) => {
        element.label = this.props.t(element.label)
      })
    }

    return (
      <div className="leftsidemenu-module-select-wrap">
        <DropDownListComponent
          dataSource={data.modules}
          fields={this.fields}
          ref={(scope) => { (this.dropdownInstance as DropDownListComponent | null) = scope; }}
          placeholder={this.props.t(data.placeholder)}
          change={this.onChangeModule.bind(this)}
          style={{ cursor: 'pointer', color: themes?.data?.colors?.primary?.contrast }}
          allowFiltering={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  themes: state.getThemes,
  data: state.getModules.data
})

const mapDispatchToProps = (dispatch: any) => ({
  getModules: () => dispatch(getModules()),
  getNavigation: (key: number) => dispatch(getNavigation(key)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHooks(withTranslation()(ModulesSelect)))
