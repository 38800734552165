const query = `
unwind [
  object in apoc.coll.flatten([$objects]) |
  case apoc.meta.type(object)
    when 'NODE' then id(object)
    when 'MAP' then toInteger(object.id)
    when 'FLOAT' then toInteger(object)
    when 'INTEGER' then object
    else null
  end
] as id

match (node)
where id(node) = id

optional match path=(:System:Schema:NodeType)<-[:extends*0..]-(:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(node))

with
  node,
  path
order by length(path) desc

with
  node,
  apoc.convert.toBoolean(nodes(collect(path)[-1])[-1].log) as log

call apoc.do.when(
  log,
  '
    with $node as node
    
    optional match (node)-[:has]->(log_last:System:Log)<-[current:current]-(node)

    delete current

    with distinct
      node,
      labels(node) as labels,
      properties(node) as properties,
      log_last

    create (node)-[:has]->(log_current:System:Log)<-[:current]-(node)
    set log_current += {
      action: "update",
      user: $user.uuid,
      timestamp: datetime(),
      labels: ":" + apoc.text.join(labels, ":"),
      properties: apoc.convert.toJson(properties)          
    }

    merge (log_current)-[:next]->(log_last)

    return distinct
      log_current as log,
      null as relations
  ',
  '
    return distinct
       null as response,
       null as relations
  ',
  {
    node: node,
    user: $user
  }
)
yield value

with distinct
  [log in collect(value.log) | log{id: id(log), .action}] as logs

return distinct
  logs as response,
  null as log
`;

export { query };
