const query = `
with permissions, widget, widget_map, element, id, user
  
  with widget, widget_map, element, id, user
  where widget_map.widget = 'Gantt'

  optional match (element)-[raises:raises]->(event:Event)
  where not raises.type in ["onLoad", "onClose"]

  optional match (widget)-[hasColumn:hasColumn]->(column:Column)-[:for]->(attribute:Attribute)

  optional match (widget)-[:hasColumn]->(column:System:UserInterface:Column)-[:for]->(attribute:System:Schema:Attribute)
  optional match (column)-[:hasLabel]->(label:System:UserInterface:Label)

  with distinct widget, widget_map{
    .*,
    events: collect(distinct event{
      key: id(event),
      type: raises.type
    }),
    query: widget.cypher,
    taskFields: apoc.map.fromPairs(collect(distinct [attribute.name, column.name])),
    editOptions: {
      allowAdding: apoc.convert.toBoolean(widget.editable),
      allowEditing: apoc.convert.toBoolean(widget.editable),
      allowTaskbarEditing: apoc.convert.toBoolean(widget.editable),
      allowDeleting: apoc.convert.toBoolean(widget.editable),
      mode: "Dialog"
    }
   } as widget_subquery

  return widget_subquery
`

export {query};