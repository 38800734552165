const GET_STATE_PENDING: string = "GET_STATE_PENDING";
const GET_STATE_SUCCESS: string = "GET_STATE_SUCCESS";
const GET_STATE_ERROR: string = "GET_STATE_ERROR";
const GET_STATE_REMOVE_MODAL_WIDGET: string = "GET_STATE_REMOVE_MODAL_WIDGET";

export {
    GET_STATE_PENDING,
    GET_STATE_SUCCESS,
    GET_STATE_ERROR,
    GET_STATE_REMOVE_MODAL_WIDGET
};
