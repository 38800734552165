import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormWidget from '../../../widgets/form/Form'
import GraphWidget from '../../../widgets/graph/Graph'
import TreeGridWidget from '../../../widgets/treegrid/treegrid'
import ContextWidget from '../../../widgets/context/Context'
import GalleryWidget from '../../../widgets/gallery/Gallery'
import NotificationMessageForm from '../../../components/NotificationMessage/errorBoudary.form';
import NotificationMessageGrid from '../../../components/NotificationMessage/errorBoundary.grid';
import NotificationMessageGraph from '../../../components/NotificationMessage/errorBoundary.graph';
import NotificationMessageContext from '../../../components/NotificationMessage/errorBoundary.context';
import GanttWidget from '../../../widgets/gantt/gantt.widget'
import ModalDialog from '../../../components/modals/Modal.dialog'
import View from '../../../widgets/View/View'
import DiagramWidget from '../../../widgets/diagram/diagram';
import GridWidgetView from '../../../widgets/grid/Grid.view'
import GridWidgetSort from '../../../widgets/grid/Grid.sort'
import GridWidgetLink from '../../../widgets/grid/Grid.link'
import GridWidgetUpdate from '../../../widgets/grid/Grid.update'


class LayoutWidget1 extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      /* navigation */
      historyPages: [],
      currentPage: null,
      runActionsState: null
    }

    this.navigateWithExtraParams = this.navigateWithExtraParams.bind(this);
    this.setCurrentDataID = this.setCurrentDataID.bind(this);
  }

  componentDidMount() {
    const { widget, runActionsState } = this.props
    if (runActionsState) {
      this.setState({ runActionsState: runActionsState })
    }

    if (widget) {
      this.setCurrentPage(widget);
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { widget, runActionsState } = this.props;

    if (JSON.stringify(prevProps.widget) !== JSON.stringify(this.props.widget)) {
      if (widget) {
        this.setCurrentPage(widget);
      }
    }

    if (JSON.stringify(prevProps.runActionsState) !== JSON.stringify(this.props.runActionsState)) {
      if (widget) {
        this.setState({ runActionsState: runActionsState })
      }
    }

  }

  setCurrentPage(widget: any) {
    this.setState({
      currentPage: {
        label: widget.label,
        widget: widget.widget,
        renderID: widget.key,
        dataID: null,
        params: { "source": { "id": null }, "relation": null, "target": { "type": null } },
        widgetData: widget,
        query: widget.query
      }
    })
  }

  setCurrentDataID(id: number) {
    this.setState({ currentDataID: id })
  }

  navigateWithExtraParams(label, widget, viewType, renderID, dataID, params, extraParams) {
    const { currentPage, historyPages } = this.state;

    if (historyPages.length === 17) { historyPages.shift(); }

    let newParams;

    if (params !== null && (params.target !== null || params.relation !== null || params.source !== null)) {
      newParams = {
        source: {
          ...params.source,
          ...extraParams.source
        },
        relation: {
          ...params.relation,
          ...extraParams.relation
        },
        target: {
          ...params.target,
          ...extraParams.target
        }
      }
    } else {
      newParams = {
        ...currentPage.params,
        ...extraParams
      }
    }

    this.setState({
      currentPage: {
        label,
        widget,
        viewType,
        renderID,
        dataID,
        params: newParams,
        query: widget.query
      }
    })

  }


  render() {
    const { oldActionsParams, oldWidgetViewType } = this.props
    const { currentPage, runActionsState } = this.state;

    if (!currentPage)
      return null;

    switch (currentPage.widget) {
      case 'Form':
        if (currentPage.widgetData.type === 'dialog') {
          return (
            <ModalDialog
              runActionsState={runActionsState}
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          )
        } else {
          return (
            <NotificationMessageForm>
              <FormWidget
                runActionsState={runActionsState}
                navData={currentPage}
                oldActionsParams={oldActionsParams}
                oldWidgetViewType={oldWidgetViewType}
              />
            </NotificationMessageForm>
          )
        }

      case 'Graph':
        return (
          <NotificationMessageGraph>
            <GraphWidget runActionsState={runActionsState} navData={currentPage} oldActionsParams={oldActionsParams} oldWidgetViewType={oldWidgetViewType} />
          </NotificationMessageGraph>
        )

      case 'Grid':
        switch (currentPage.widgetData?.type) {
          case 'view':
            return (
              <NotificationMessageGrid>
                <GridWidgetView
                  runActionsState={runActionsState}
                  navData={currentPage}
                  navigateWithExtraParams={this.navigateWithExtraParams}
                  oldActionsParams={oldActionsParams}
                  oldWidgetViewType={oldWidgetViewType}
                />
              </NotificationMessageGrid>
            )
          case 'link':
            return (
              <NotificationMessageGrid>
                <GridWidgetLink
                  runActionsState={runActionsState}
                  navData={currentPage}
                  navigateWithExtraParams={this.navigateWithExtraParams}
                  oldActionsParams={oldActionsParams}
                  oldWidgetViewType={oldWidgetViewType}
                />
              </NotificationMessageGrid>
            )
          case 'sort':
            return (
              <NotificationMessageGrid>
                <GridWidgetSort
                  runActionsState={runActionsState}
                  navData={currentPage}
                  navigateWithExtraParams={this.navigateWithExtraParams}
                  oldActionsParams={oldActionsParams}
                  oldWidgetViewType={oldWidgetViewType}
                />
              </NotificationMessageGrid>
            )
          case 'update':
          case 'edit':
            return (
              <NotificationMessageGrid>
                <GridWidgetUpdate
                  runActionsState={runActionsState}
                  navData={currentPage}
                  navigateWithExtraParams={this.navigateWithExtraParams}
                  oldActionsParams={oldActionsParams}
                  oldWidgetViewType={oldWidgetViewType}
                />
              </NotificationMessageGrid>
            )
          default:
            return null
        }
      case 'TreeGrid':
        return (
          <NotificationMessageGrid>
            <TreeGridWidget
              runActionsState={runActionsState}
              navData={currentPage}
              navigateWithExtraParams={this.navigateWithExtraParams}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </NotificationMessageGrid>

        )

      case 'Context':
        return (
          <NotificationMessageContext>
            <ContextWidget
              runActionsState={runActionsState}
              navData={currentPage}
              oldActionsParams={oldActionsParams}
              oldWidgetViewType={oldWidgetViewType}
            />
          </NotificationMessageContext>
        )

      case 'Gallery':
        return (
          <GalleryWidget
            runActionsState={runActionsState}
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        )

      case 'Gantt':
        return (
          <GanttWidget
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        )

      case 'View':

        return (
          <View
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        )

      case 'Diagram':

        return (
          <DiagramWidget
            navData={currentPage}
            oldActionsParams={oldActionsParams}
            oldWidgetViewType={oldWidgetViewType}
          />
        )

      default:
        return null
    }

  }
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWidget1)