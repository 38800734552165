import { toast } from 'react-toastify';

export const notifyError = (toastContent) => {
    toast.error(toastContent, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true
    });
}

export const notifySuccess = (toastContent) => {
    toast.success(toastContent, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true
    });
}
