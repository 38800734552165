import neo4j from "neo4j-driver";
import { notifyError } from '../../components/Toasts/toasts';

let driver;

try {
  const connection = JSON.parse(localStorage.getItem("connection"));
  driver = connection
    ? neo4j.driver(
      process.env.REACT_APP_PRIMARY_DATABASE_URL,
      neo4j.auth.basic(
        process.env.REACT_APP_PRIMARY_DATABASE_USER,
        process.env.REACT_APP_PRIMARY_DATABASE_PASSWORD
      ),
      {
        disableLosslessIntegers: true,
      }
    )
    : null;

} catch (error) {
  console.error("API driver error:  ", error)
}



export const checkConnection = (connec: any) => {
  if (!connec) {
    localStorage.removeItem('connection');
    window.location.reload();
    notifyError(" Sie wurden automatisch abgemeldet ")
  }
}


export default driver;
