import React from "react";
import { connect } from "react-redux";
import '../styles.css'

class LinkForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      listData: null,
      inputValue: ''
    };
  }

  componentDidMount() {
    this.setState({ inputValue: this.props.modals.selectedText })
  }

  select = (args: any) => {
    const { $this } = this.props
    $this.setState({ selectedElement: { ...args.data } })
  }

  render() {
    const { $this } = this.props

    return (
      <div id="sample" className="list-content">
        <input
          className="e-input textbox-external-link"
          type="url"
          id="textbox"
          placeholder="External URL"
          onChange={(e: any) => {
            $this.setState({ externURL: { url: e.target.value, type: 'link' } })
          }}
        />
        <input
          className="e-input textbox-link-text"
          type="text"
          id="textbox"
          placeholder="Text"
          onChange={(e: any) => {
            this.setState({ inputValue: e.target.value })
          }}
          value={this.state.inputValue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  modals: state.modals
})

export default connect(
  mapStateToProps,
  null
)(LinkForm)
