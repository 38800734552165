import { Input } from "formsy-semantic-ui-react";
import { Label } from 'semantic-ui-react'
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

const URLField: FC<any> = ({ field, value, hiddenClass, setNewValue, isLocked, viewType, setColorField }) => {
  const errorLabel = <Label color="red" pointing />
  const { t } = useTranslation();

  switch (viewType) {
    case 'view':
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{field.label}</label>
          <div
            className='form-input-element '
          >
            <a href={value} target='_blank' rel="noopener noreferrer">{value}</a>
          </div>
        </div>
      )
    case 'edit':
    case 'create':
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{t(field.label)}</label>
          <div
            className="form-input-element"
          // style={{ backgroundColor: setColorField() }}
          >
            <Input
              name={field.name}
              className='form-input-element'
              style={{ height: field.height }}
              type="text"
              placeholder=""
              value={value}
              onChange={(e) => setNewValue(e.target.value)}
              disabled={isLocked}
              validations="isUrl"
              validationErrors={{ isUrl: 'please check the format of the URL' }}
              errorLabel={errorLabel}
              // enterKeyHint
            />
          </div>
        </div>
      )
    default:
      return (
        <span>viewType nicht gesetzt</span>
      )
  }
}
export default URLField;