const query = `
with permissions, widget, widget_map, element, id, user
  
  with widget, widget_map, element, id, user
  where widget_map.widget = 'TreeGrid'

  optional match (element)-[raises:raises]->(event:Event)
  where not raises.type in ["onLoad", "onClose"]

  optional match (widget)-[hasColumn:hasColumn]->(column:Column)-[:for]->(attribute:Attribute)-[:isOfDataType]->(dataType:DataType)

  with
    widget,
    widget{
      events: collect(distinct event{
        key: id(event),
        type: raises.type
      })
    } as widget_map,
    column,
    hasColumn,
    coalesce(toInteger(hasColumn.sequence), toInteger(column.sequence), 0) as sequence,
    attribute,
    dataType

  optional match (column)-[:hasLabel]->(label:Label)
  optional match (label)-[:withName]->(term:Apps:Term)
  optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)
  optional match (attribute)-[hasValue:hasValue]->(value:Value)

  with distinct
    widget,
    widget_map,
    column,
    hasColumn,
    sequence,
    attribute,
    dataType,
    label,
    term,
    icon,
    collect(distinct value) as values
  
  with distinct
    widget,
    widget_map,
    column,
    hasColumn,
    sequence,
    attribute,
    dataType,
    label,
    term,
    icon,
    values
  order by sequence

  with distinct
    widget,
    widget_map,
    collect(distinct column{
      label: coalesce(case when not term is null then label.ID else label.name end, ""),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      name: attribute.name,
      hide: apoc.convert.toBoolean(coalesce(hasColumn.hidden, column.hidden, false)),
      width: coalesce(toInteger(hasColumn.width), toInteger(column.width), 0),
      type: dataType.name
    }) as columns
    
  with distinct
    widget_map{
      .*,
      columns: columns,
      query: widget.cypher,
      level: widget.level
    } as widget_subquery

  return widget_subquery
`

export {query};