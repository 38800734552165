import { Input } from "formsy-semantic-ui-react";
import { Label } from 'semantic-ui-react'
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

const EmailField: FC<any> = ({ field, value, hiddenClass, setNewValue, isLocked, viewType, setColorField }) => {
  const errorLabel = <Label color="red" pointing />
  const { t } = useTranslation();

  switch (viewType) {
    case 'view':
      return (
        <div className={`FormInputContainer ${hiddenClass}`}>
          <label className='FormInputLabel'>{t(field.label)}</label>
          <div
            className='form-input-element '
          >
            <a href={`mailto:${value}`}>{value}</a>
          </div>
        </div>
      )
    case 'edit':
    case 'create':
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{t(field.label)}</label>
          <div
            className='form-input-element'
          >
            <Input
              name={field.name}
              style={{ height: field.height }}
              type="text"
              placeholder=""
              value={value}
              onChange={(e) => setNewValue(e.target.value)}
              disabled={isLocked}
              validations="isEmail"
              validationErrors={{ isEmail: 'mail addresse is not valid' }}
              errorLabel={errorLabel}
              // enterKeyHint
            />
          </div>
        </div>
      )
    default:
      return (
        <span>viewType nicht gesetzt</span>
      )
  }
}
export default EmailField;