import getMenuQuery from "./getMenuForm.query";
import  driver from "../../driver";
import { logger } from "../../../logger";

export const getMenuForm = async (id: number) => {
  try {
    const connection = JSON.parse(localStorage.getItem('connection'));
    const parameters = { id: id, user: connection.user }

    return driver.session({ database: process.env.REACT_APP_PRIMARY_DATABASE_NAME }).run(getMenuQuery, parameters)
  } catch (error) {
    logger.error("API getMenuForm:   ", error)
  }
}
