import { GET_THEMES_PENDING, GET_THEMES_SUCCESS, GET_THEMES_ERROR } from './types'
import { getThemes as getThemesAPI } from '../../services/api/Themes/getThemes.api.'
import { logger } from '../../services/logger'

interface MyMessage {
  msg: string
}

const themesPending = () => {
  return {
    type: GET_THEMES_PENDING,
  }
}

const themesReceived = (data: any) => {
  return {
    type: GET_THEMES_SUCCESS,
    data,
  }
}

const themesError = (err: MyMessage) => {
  return {
    type: GET_THEMES_ERROR,
    err,
  }
}

export const getThemes = () => {
  return (dispatch) => {
    dispatch(themesPending())
    getThemesAPI('system')
      .then((resp) => {
        if(resp.settings){
          var background = resp.settings?.colors?.background;
          var primary = resp.settings?.colors?.primary;
          var secondary = resp.settings?.colors?.secondary;
          document.documentElement.style.setProperty(`--backgroundColor`, `rgb(${background.rgb.r}, ${background.rgb.g}, ${background.rgb.b})`);
          document.documentElement.style.setProperty(`--backgroundContrast`, background.contrast);
          document.documentElement.style.setProperty(`--backgroundOverlay`, background.contrast === 'black' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)');
          document.documentElement.style.setProperty(`--primaryColor`, `rgb(${primary.rgb.r}, ${primary.rgb.g}, ${primary.rgb.b})`);
          document.documentElement.style.setProperty(`--primaryContrast`, primary.contrast);
          document.documentElement.style.setProperty(`--primaryOverlay`, primary.contrast === 'black' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)');
          document.documentElement.style.setProperty(`--secondaryColor`, `rgb(${secondary.rgb.r}, ${secondary.rgb.g}, ${secondary.rgb.b})`);
          document.documentElement.style.setProperty(`--secondaryContrast`, secondary.contrast);
          document.documentElement.style.setProperty(`--secondaryOverlay`, secondary.contrast === 'black' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)');
          dispatch(themesReceived(resp.settings));
        } else {
          dispatch(themesError(null))
        }
      }) 
      .catch((err: any) => {
        logger.error("getThemesAPI error: ", err);
        dispatch(themesError(err))
      }) 
  }
}