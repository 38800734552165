import { logger } from "../../logger";
import Connection from "../Database/connection";
import jwt from 'jsonwebtoken'

export const run = async (storeName: string, query: any, params: any) => {

  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { ...params, user: local_connection.user }
    let connection = new Connection()
    return connection.runQuery(storeName, query, parameters);
  } catch (error) {
    logger.error("API run error:  ", error)
  }
}