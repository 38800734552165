import React from "react";
import { connect } from 'react-redux';
import { withRouterHooks } from '../../../navigation/router.hooks';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, RichTextEditorModel, Table, Resize, NodeSelection, MarkdownEditor, MarkdownFormatter, RichTextEditor } from '@syncfusion/ej2-react-richtexteditor';
import { withTranslation } from "react-i18next";
import { openRichtextDialog, setTextSelection } from "../../../store/modals/action";
import { DropDownButton } from "@syncfusion/ej2-react-splitbuttons";
import { marked } from 'marked'
import { addClass, Browser, createElement, isNullOrUndefined, KeyboardEventArgs, removeClass } from "@syncfusion/ej2-base";


class RichTextField extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      openImageDialog: false,
      previewClick: true,
    }
  }
  rteObj: any;
  selection_image: NodeSelection = new NodeSelection();
  selection_link: NodeSelection = new NodeSelection();
  selection_diagram: NodeSelection = new NodeSelection();
  range_image: Range;
  range_link: Range;
  range_diagram: Range;
  saveSelection_image: NodeSelection;
  saveSelection_link: NodeSelection;
  saveSelection_diagram: NodeSelection;
  formatter = new MarkdownFormatter({ listTags: { OL: '1., 2., 3.' } });
  private textArea: HTMLTextAreaElement;
  private mdsource: HTMLElement;
  private mdSplit: HTMLElement;
  private htmlPreview: HTMLElement;

  dropdownFields_intern: any = [
    { iconCss: 'e-icons e-link-2', text: 'link', id: 'custom_link' },
    { iconCss: 'e-icons e-image', text: 'image', id: 'custom_image' },
    { iconCss: 'e-icons e-transform', text: 'diagram', id: 'custom_diagram' },
  ];

  dropdownFields_extern: any = [
    { iconCss: 'e-icons e-link-2', text: 'link', id: '1' },
    { iconCss: 'e-icons e-image', text: 'image', id: '2' }
  ];

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    document.querySelectorAll('.e-rte-image').forEach((element: any) => {
      element.classList.remove('e-rte-image')
    })
    this.setDisableLinkClass(true)
  }

  componentWillUnmount(): void {
    this.setDisableLinkClass(false)
  }

  setDisableLinkClass = (inactive: boolean) => {
    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    let uuid: any;

    document.querySelectorAll('a').forEach(
      (element: any) => {
        if (element.href) {
          let url = new URL(element.href)
          uuid = url.pathname.slice(1)
          if (regexExpUUID.test(uuid) || url.href) {
            if (inactive) {
              element.classList.add('disable-link')
            } else {
              element.classList.remove('disable-link')
            }
          }
        }
      })
  }

  setImage(element: any, externURL: any) {
    if (!this.saveSelection_image) return null

    if (this.props.field.type === 'markdown') {
      this.saveSelection_image.restore();
      this.rteObj.executeCommand('insertText', `![](${externURL ? externURL : element.image})`);
      this.rteObj.formatter.saveData();
      this.rteObj.formatter.enableUndo(this.rteObj);
      return null
    }

    this.saveSelection_image.restore();
    this.rteObj.executeCommand('insertHTML', `<img src="${externURL ? externURL : element.image}" />`);
    this.rteObj.formatter.saveData();
    this.rteObj.formatter.enableUndo(this.rteObj);
  }

  setLink = (element: any, inputValue: any, externURL: any) => {

    if (inputValue === '' || inputValue === null || inputValue === undefined || !this.saveSelection_link) return null

    if (this.props.field.type === 'markdown') {
      this.saveSelection_link.restore();
      if (element && element?.uuid) {
        this.rteObj.executeCommand('insertText', `[${inputValue}](${element.uuid})`)
      } else if (externURL) {
        this.rteObj.executeCommand('insertText', `[${inputValue}](${externURL})`)
      } else {
        return null
      }
      this.rteObj.formatter.saveData();
      this.rteObj.formatter.enableUndo(this.rteObj);

      return null
    }

    this.saveSelection_link.restore();
    if (element && element?.uuid) {
      this.rteObj.executeCommand('insertHTML', `<a href="${element.uuid}">${inputValue}<a/>`);
    } else if (externURL) {
      this.rteObj.executeCommand('insertHTML', `<a href="${externURL}">${inputValue}<a/>`);
    } else {
      return null
    }
    this.rteObj.formatter.saveData();
    this.rteObj.formatter.enableUndo(this.rteObj);

    this.setDisableLinkClass(true)
  }

  setDiagram = (element: any) => {
    if (this.props.field.type === 'markdown') {
      this.saveSelection_diagram.restore();
      this.rteObj.executeCommand('insertText', `![](${element.preview})`);
      this.rteObj.formatter.saveData();
      this.rteObj.formatter.enableUndo(this.rteObj);
      return null
    }

    this.saveSelection_diagram.restore();
    this.rteObj.executeCommand('insertHTML', `<img src="${element.preview}" />`);
    this.rteObj.formatter.saveData();
    this.rteObj.formatter.enableUndo(this.rteObj);
  }

  toolbarSettingsMarkdown = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      {
        template: '<button style="width:70px" id="dropdown-extern">intern</button>'
      },
      {
        template: '<button style="width:70px" id="dropdown-intern">extern</button>'
      },
      {
        tooltipText: 'Preview',
        template:
          '<button type="button" class="e-tbar-btn e-btn e-filemanager-btn" id="preview-code" tabindex="-1" style="width:100%"><div class="e-icons e-eye"></div></button>'
      },
      // split editor button is hidden
      {
        tooltipText: 'Split Editor',
        template: '<button type="button" class="e-tbar-btn e-control e-btn e-icon-btn" id="MD_Preview" tabindex="-1" style="width:100%" hidden><div class="e-btn-icon e-view-side e-icons"></div></button>'
      },
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      'CreateTable',
      '|',
      'Undo',
      'Redo'
    ]
  };

  toolbarSettingsHTML = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      {
        template: '<button style="width:70px" id="dropdown-extern">intern</button>'
      },
      {
        template: '<button style="width:70px" id="dropdown-intern">extern</button>'
      },
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      'CreateTable',
      '|',
      'Undo',
      'Redo'
    ]
  };

  getDialogData(type) {
    const { params, openRichtextDialog } = this.props
    openRichtextDialog({ ...params, type: 'zoom', selectedText: window.getSelection().toString() }, this.setImage.bind(this), this.setLink.bind(this), this.setDiagram.bind(this), type)
  };


  getChanges = (args: RichTextEditorModel) => {
    const { setNewValue } = this.props
    setNewValue(args.value)
  }

  public markdownConversion(): void {
    if (this.mdSplit.classList.contains('e-active')) {
      let id: string = this.rteObj.getID() + 'html-view';
      let htmlPreview: HTMLElement = this.rteObj.element.querySelector('#' + id);
      this.htmlPreview.innerHTML = marked((this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement).value);
    }
  }

  fullPreview(e) {
    let id: string = this.rteObj.getID() + 'html-preview';
    this.htmlPreview = this.rteObj.element.querySelector('#' + id);
    if ((this.mdsource.classList.contains('e-active')) && e.mode) {
      this.mdsource.classList.remove('e-active');
      // this.mdSplit.classList.remove('e-active');
      this.mdsource.parentElement.title = 'Preview';
      this.textArea.style.display = 'block';
      this.textArea.style.width = '100%';
      this.htmlPreview.style.display = 'none';
    } else {
      this.mdsource.classList.add('e-active');
      // this.mdSplit.classList.add('e-active');
      if (!this.htmlPreview) {
        this.htmlPreview = createElement('div', { className: 'e-content' });
        this.htmlPreview.id = id;
        this.textArea.parentNode.appendChild(this.htmlPreview);
      }
      if (e.type === 'preview') {
        this.textArea.style.display = 'none'; this.htmlPreview.classList.add('e-pre-source');
      } else {
        this.htmlPreview.classList.remove('e-pre-source');
        this.textArea.style.width = '50%';
      }
      this.htmlPreview.style.display = 'block';
      this.htmlPreview.innerHTML = marked((this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement).value);
      this.mdsource.parentElement.title = 'Code View';
    }
  }

  onCreate = () => {
    if (this.props.field.type === 'markdown') {
      this.textArea = this.rteObj.contentModule.getEditPanel() as HTMLTextAreaElement;
      this.textArea.addEventListener('keyup', (e: KeyboardEventArgs) => { this.markdownConversion(); });
      let rteObj: RichTextEditor = this.rteObj;
      this.mdsource = document.getElementById('preview-code');
      this.mdsource.addEventListener('click', (e: MouseEvent) => {
        this.fullPreview({ mode: true, type: 'preview' });
        if ((e.currentTarget as HTMLElement).classList.contains('e-active')) {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, false)
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, false)
          this.rteObj.disableToolbarItem([
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            'CreateTable',
            '|',
            'Undo',
            'Redo'
          ]);
          (e.currentTarget as HTMLElement).parentElement.nextElementSibling.classList.add('e-overlay');
        } else {
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(18, true)
          rteObj.toolbarModule.baseToolbar.toolbarObj.enableItems(19, true)
          this.rteObj.enableToolbarItem([
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            'CreateTable',
            '|',
            'Undo',
            'Redo'
          ]);
          (e.currentTarget as HTMLElement).parentElement.nextElementSibling.classList.remove('e-overlay');
        }
      });
      this.mdSplit = document.getElementById('MD_Preview');
      this.mdSplit.addEventListener('click', (e: MouseEvent) => {
        return null
        //TODO add implement full screen
        if (rteObj.element.classList.contains('e-rte-full-screen')) { this.fullPreview({ mode: true, type: '' }); }
        this.mdsource.classList.remove('e-active');
        if (!rteObj.element.classList.contains('e-rte-full-screen')) {
          rteObj.showFullScreen();
        }
      });
    }

    let dropdownIntern: DropDownButton = new DropDownButton({
      beforeOpen: () => this.props.setTextSelection(window.getSelection().toString()),
      items: this.dropdownFields_intern,
      content: 'intern',
      select: (args: any) => {
        switch (args.item.properties.text) {
          case 'link':
            (this.rteObj.contentModule.getEditPanel() as HTMLElement).focus();
            this.range_link = this.selection_link.getRange(document);
            this.saveSelection_link = this.selection_link.save(this.range_link, document);
            return this.getDialogData('link_grid')
          case 'image':
            (this.rteObj.contentModule.getEditPanel() as HTMLElement).focus();
            this.range_image = this.selection_image.getRange(document);
            this.saveSelection_image = this.selection_image.save(this.range_image, document);
            return this.getDialogData('image_grid')
          case 'diagram':
            (this.rteObj.contentModule.getEditPanel() as HTMLElement).focus();
            this.range_diagram = this.selection_diagram.getRange(document);
            this.saveSelection_diagram = this.selection_diagram.save(this.range_diagram, document);
            return this.getDialogData('diagram_grid')
          default:
            return null
        }

      }
    });

    dropdownIntern.appendTo('#dropdown-intern');

    let dropdownExtern: DropDownButton = new DropDownButton({
      items: this.dropdownFields_extern,
      content: 'extern',
      select: (args: any) => {
        switch (args.item.properties.text) {
          case 'link':
            (this.rteObj.contentModule.getEditPanel() as HTMLElement).focus();
            this.range_link = this.selection_link.getRange(document);
            this.saveSelection_link = this.selection_link.save(this.range_link, document);
            return this.getDialogData('link')
          case 'image':
            (this.rteObj.contentModule.getEditPanel() as HTMLElement).focus();
            this.range_image = this.selection_image.getRange(document);
            this.saveSelection_image = this.selection_image.save(this.range_image, document);
            return this.getDialogData('image')
          default:
            return null
        }

      }
    });
    dropdownExtern.appendTo('#dropdown-extern');
  }

  public handleFullScreen(e: any): void {
    let sbCntEle: HTMLElement = document.querySelector('.sb-content.e-view');
    let sbHdrEle: HTMLElement = document.querySelector('.sb-header.e-view');
    let leftBar: HTMLElement;
    let transformElement: HTMLElement;
    if (Browser.isDevice) {
      leftBar = document.querySelector('#right-sidebar');
      transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
    } else {
      leftBar = document.querySelector('#left-sidebar');
      transformElement = document.querySelector('#right-pane');
    }
    if (e.targetItem === 'Maximize') {
      if (Browser.isDevice && Browser.isIos) {
        addClass([sbCntEle, sbHdrEle], ['hide-header']);
      }
      addClass([leftBar], ['e-close']); removeClass([leftBar], ['e-open']);
      if (!Browser.isDevice) { transformElement.style.marginLeft = '0px'; }
      transformElement.style.transform = 'inherit';
    } else if (e.targetItem === 'Minimize') {
      if (Browser.isDevice && Browser.isIos) {
        removeClass([sbCntEle, sbHdrEle], ['hide-header']);
      }
      removeClass([leftBar], ['e-close']);
      if (!Browser.isDevice) {
        addClass([leftBar], ['e-open']);
        transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
      }
      transformElement.style.transform = 'translateX(0px)';
    }
  }

  public actionComplete(e: any): void {
    if (e.targetItem === 'Maximize' && isNullOrUndefined(e.args)) {
      this.fullPreview({ mode: true, type: '' })
    }
    else if (!this.mdSplit.parentElement.classList.contains('e-overlay')) {
      if (e.targetItem === 'Minimize') {
        this.textArea.style.display = 'block';
        this.textArea.style.width = '100%';
        if (this.htmlPreview) {
          this.htmlPreview.style.display = 'none';
        }
        this.mdSplit.classList.remove('e-active');
        this.mdsource.classList.remove('e-active');
      }
      this.markdownConversion();
    }
    this.rteObj.toolbarModule.refreshToolbarOverflow();
  }


  render() {
    const { viewType, field, value, hiddenClass } = this.props
    let Editable: boolean = ["create", "edit"].includes(viewType);


    if (Editable) {
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{this.props.t(field.label)}</label>
          <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
          <div
            style={{ /*backgroundColor: setColorField(),*/ width: "100%" }}
          >
            <RichTextEditorComponent
              id="markdownPreview"
              ref={(richtexteditor) => { this.rteObj = richtexteditor }}
              formatter={field.editorMode === 'markdown' ? this.formatter : null}
              height={450}
              toolbarSettings={field.type === 'markdown' ? this.toolbarSettingsMarkdown : this.toolbarSettingsHTML}
              editorMode={field.type === 'markdown' ? field.type.charAt(0).toUpperCase() + field.type.slice(1) : 'HTML'}
              enableResize={true}
              value={value ? value : ''}
              change={this.getChanges.bind(this)}
              created={this.onCreate.bind(this)}
              actionBegin={field.type === 'markdown' ? this.handleFullScreen.bind(this) : null}
              actionComplete={field.type === 'markdown' ? this.actionComplete.bind(this) : null}
            >
              <Inject services={[Toolbar, HtmlEditor, MarkdownEditor, Image, Link, Table, QuickToolbar, Resize]} />
            </RichTextEditorComponent>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`form-input-container ${hiddenClass}`}>
          <label className='form-input-label'>{this.props.t(field.label)}</label>
          <div className='parsedHTML richtext-container' style={{ height: value !== null ? field.height + "px" : "70px", width: '100%', overflowY: 'auto' }}>
            <div>
              {
                value
                  ? <RichTextEditorComponent
                    readonly={true}
                    ref={(richtexteditor) => { this.rteObj = richtexteditor }}
                    value={field.type === 'markdown' ? marked(value) : value}
                    editorMode={'HTML'}
                    className={'richtext-view'}
                  >
                    <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
                  </RichTextEditorComponent>
                  : ''
              }
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  openRichtextDialog: (params: any, imageEvent: RichTextEditorComponent, setLink: RichTextEditorComponent, diagramEvenet: any, eventType: any) => dispatch(openRichtextDialog(params, imageEvent, setLink, diagramEvenet, eventType)),
  setTextSelection: (selectedText: any) => dispatch(setTextSelection(selectedText))
})

export default connect(
  null,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(RichTextField)))
