type MyLoginState = {
    Loading: boolean
    Success: boolean
    Error: boolean
    Contexts: any
  }
  
  const initialState: any = {
    pending: false,
    success: false,
    error: false,
    data: null
  }
  
  export default initialState
  