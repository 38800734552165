import { OPEN_CONFIRM_MODAL, CLOSE_CONFIRM_MODAL, CLOSE_RICHTEXT_MODAL, OPEN_RICHTEXT_MODAL, SET_TEXT_SELECTION } from './types'
  
export const openConfirmModal = (contentID: any, okEvent: any) => {

    const musterContent = [
        {
            title: 'Löschen',
            content: 'Möchten Sie wirklich löschen ?'
        }
    ]
  
    return {
      type: OPEN_CONFIRM_MODAL,
      title: musterContent[contentID].title,
      content: musterContent[contentID].content,
      okEvent
    }
}
  

export const closeConfirmModal = () => {
  return {
    type: CLOSE_CONFIRM_MODAL
  }
}

export const closeRichtextDialog = () => {
  return {
    type: CLOSE_RICHTEXT_MODAL
  }
}

export const setTextSelection = (selectedText: any) => {
  return {
    type: SET_TEXT_SELECTION,
    selectedText: selectedText
  }
}

export const openRichtextDialog = (params: any, imageEvent: any, linkEvent: any, diagramEvent: any, eventType: any) => {
  return {
    type: OPEN_RICHTEXT_MODAL,
    params: params,
    imageEvent,
    linkEvent,
    diagramEvent,
    eventType
  }
}

