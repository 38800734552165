import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import authReducer from './auth/reducer'
import navigationReducer from './navigation/reducer'
import confirmModalReducer from './modals/reducer'
import uploadModalReducer from './uploadModal/reducer'
import uploadFileReducer from './uploadFile/reducer'
import getContextItemsCountsReducer from './getContextItemsCounts/reducer'
import getMenuFormReducer from './getMenuForm/reducer'
import getThemesReducer from './getThemes/reducer'
import getContextMenuReducer from './getContextMenu/reducer'
import getNavigationReducer from './getNavigation/reducer'
import getModulesReducer from './getModules/reducer'
import getStateReducer from './getState/reducer'
import actionsReducer from './actions/reducer'
import breadcrumbReducer from './breadcrumb/reducer'
import globalKeyEventReducer from './globalKeyEvent/reducer'
import thunk from 'redux-thunk'

const combiReducers = combineReducers(
  {
    auth: authReducer,
    navigation: navigationReducer,
    modals: confirmModalReducer,
    uploadModal: uploadModalReducer,
    uploadFile: uploadFileReducer,
    getContextItemsCounts: getContextItemsCountsReducer,
    getMenuForm: getMenuFormReducer,
    getThemes: getThemesReducer,
    getContextMenu: getContextMenuReducer,
    getNavigation: getNavigationReducer,
    getModules: getModulesReducer,
    getState: getStateReducer,
    actions: actionsReducer,
    breadcrumb: breadcrumbReducer,
    keyEvent: globalKeyEventReducer
  }
)

const composeEnhancers = (process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  combiReducers,
  compose(
    applyMiddleware(thunk),
    composeEnhancers()
  )
);

export default store
