import React from "react";
import GridWidgetSync from "./Grid";
import { withRouterHooks } from "../../navigation/router.hooks";
import {
    RowDropEventArgs,
    SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";
import fetchGridDataData from "./services/fetchGridData";
import { logger } from "../../services/logger";

class GridWidgetSort extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            gridData: null
        }
    }

    selectionSettings: SelectionSettingsModel = {
        type: 'Multiple'
    };
    rowDropSettings: Object = this.props.navData.widgetData.dependency !== null ? { targetID: `grid-${this.props.navData.widgetData.dependency?.target?.id}` } : null;

    componentDidMount() {
        const { navData, oldActionsParams, runActionsState, $this } = this.props

        fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
            .then((response) => {
                this.setState({ gridData: response.targets })
                logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
            }
            );
    }

    componentDidUpdate(prevProps) {
        const { navData, oldActionsParams, runActionsState, $this } = this.props;

        if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
            this.clearData()
            this.setState({ gridRenderData: navData.widgetData })
            fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
                .then((response) => {
                    this.setState({ gridData: response.targets })
                    logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
                })
        }
    }

    clearData() {
        this.setState({
            gridData: null,
            gridEditData: []
        })
    }


    onRowDrag(args: RowDropEventArgs) {

        const dropEventArg: any = args
        const fromIndex = dropEventArg?.fromIndex
        const toIndex = dropEventArg?.dropIndex

        this.props.$this.state.gridData?.sort((x, y) => {
            const obj1 = x['sequence']
            const obj2 = y['sequence']

            if (obj1 < obj2) {
                return -1
            }
            if (obj1 > obj2) {
                return 1
            }
            return 0
        })
        this.props.$this.state.gridData?.splice(toIndex, 0, this.props.$this.state.gridData.splice(fromIndex, 1)[0]);

        let dragAndDropData = this.props.$this.state.gridData?.map(element => {
            let index = this.props.$this.state.gridData?.indexOf(element)
            element.sequence = index
            return element
        });

        this.setState({ gridEditData: dragAndDropData });
    }


    render() {
        const { navData, runActionsState, oldActionsParams } = this.props;

        return (
            <GridWidgetSync
                $this={this}
                navData={navData}
                runActionsState={runActionsState}
                oldActionsParams={oldActionsParams}
                selectionSettings={this.selectionSettings}
                onRowDrag={this.onRowDrag}
                allowRowDragAndDrop={true}
                gridEditData={this.state.gridEditData}
                rowDropSettings={this.rowDropSettings}
                gridData={this.state.gridData}
                gridRenderData={navData.widgetData}
            />
        );
    }
}

export default withRouterHooks((GridWidgetSort))
