type MyLoginState = {
  Loading: boolean
  Success: boolean
  Error: boolean
  MenuForms: any
}

const initialState: MyLoginState = {
  Loading: false,
  Success: false,
  Error: false,
  MenuForms: [],
}

export default initialState
