const query = `
match (user:User)
where user.uuid = $user.uuid

optional match (user)-[:plays]->(role:System:AccessControl:Role)
optional match (role)-[:includes*..]->(role_includes:System:AccessControl:Role)

with distinct
  user,
  [role] + collect(distinct role_includes) + [] as roles

unwind roles as role

match (role)-[:hasGiven]->(permission:System:AccessControl:Permission)

with distinct
  user,
  collect(distinct permission) as permissions

match (module:System:Workflow:Module)
where
  (
    id(module) = toInteger($key)
    and not $key is null
  ) or (
    module.uuid = $uuid
    and $key is null
  )

match (module)-[:raises{type: 'onLoad'}]->(:System:Workflow:Event)-[:loads]->(navigation:System:Workflow:Navigation)

optional match (navigation)-[:hasLabel]->(label:Label)
optional match (label)-[:withName]->(term:Apps:Term)

with distinct
  user,
  permissions,
  navigation,
  navigation{
    key: id(navigation),
    type: coalesce(navigation.type, ''),
    label: coalesce(case when not term is null then label.ID else label.name end, navigation.name, '')
  } as navigation_map

match (navigation)-[:raises{type: 'onLoad'}]->(:System:Workflow:Event)-[:loads]->(menu:System:Workflow:Menu)
where coalesce(menu.type, 'default') = 'default'

optional match (menu)-[:hasLabel]->(label:Label)
optional match (menu)-[:hasIcon]->(icon:System:UserInterface:Icon)
optional match (label)-[:withName]->(term:Apps:Term)

with distinct
  user,
  permissions,
  navigation,
  navigation_map,
  menu,
  menu{
    key: id(menu),
    type: coalesce(menu.type, 'default'),
    label: coalesce(case when not term is null then label.ID else label.name end, menu.name, ''),
    icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
    sequence: 1
  } as menu_map

optional match (menu)-[raises_menu:raises]->(event_menu:System:Workflow:Event)-[:loads]->(element_menu:System:Workflow:Menu)-[raises_button:raises]->(event_button:System:Workflow:Event)-[:loads]->(element_button:System:Workflow:Button)
where raises_menu.type = raises_button.type = 'onLoad'
  and any(permission in permissions where (permission)-[:for]->(event_menu))
  and any(permission in permissions where (permission)-[:for]->(event_button))

optional match (element_menu)-[:hasLabel]->(label_menu:Label)
optional match (element_menu)-[:hasIcon]->(icon_menu:System:UserInterface:Icon)
optional match (label_menu)-[:withName]->(term_menu:Apps:Term)
optional match (element_button)-[:hasLabel]->(label_button:Label)
optional match (element_button)-[:hasIcon]->(icon_button:System:UserInterface:Icon)
optional match (label_button)-[:withName]->(term_button:Apps:Term)
optional match (element_button)-[raises:raises]->(event:System:Workflow:Event)
where not raises.type in ['onLoad', 'onClose']

with distinct
  user,
  permissions,
  navigation,
  navigation_map,
  menu,
  menu_map,
  element_menu,
  element_menu{
    key: id(element_menu),
    type: 'menu',
    label: coalesce(case when not term_menu is null then label_menu.ID else label_menu.name end, element_menu.name, ''),
    icon: {name: coalesce(icon_menu.name, ""), code: coalesce(icon_menu.code, "")},
    sequence: coalesce(toInteger(raises_menu.sequence), 100),
    events: [null],
    elements: [null]
  } as element_menu_map,
  element_button{
    key: id(element_button),
    type: 'button',
    label: coalesce(case when not term_button is null then label_button.ID else label_button.name end, element_button.name, ''),
    icon: {name: coalesce(icon_button.name, ""), code: coalesce(icon_button.code, "")},
    sequence: coalesce(toInteger(raises_button.sequence), 100),
    events: collect(distinct event{
      key: id(event),
      type: raises.type
    }),
    elements: [null]
  } as element_button_map

with distinct
  user,
  permissions,
  navigation,
  navigation_map,
  menu,
  menu_map,
  element_menu_map{
    .*,
    elements: apoc.coll.sortMaps(collect(distinct element_button_map), '^sequence')
  }

optional match (menu)-[raises_button:raises]->(event_button:System:Workflow:Event)-[:loads]->(element_button:System:Workflow:Button)
where raises_button.type = 'onLoad'
  and any(permission in permissions where (permission)-[:for]->(event_button))

optional match (element_button)-[:hasLabel]->(label_button:Label)
optional match (element_button)-[:hasIcon]->(icon_button:System:UserInterface:Icon)
optional match (label_button)-[:withName]->(term_button:Apps:Term)
optional match (element_button)-[raises:raises]->(event:System:Workflow:Event)
where not raises.type in ['onLoad', 'onClose']

with distinct
  user,
  navigation,
  navigation_map,
  menu,
  menu_map,
  element_menu_map,
  element_button{
    key: id(element_button),
    type: 'button',
    label: coalesce(case when not term_button is null then label_button.ID else label_button.name end, element_button.name, ''),
    icon: {name: coalesce(icon_button.name, ""), code: coalesce(icon_button.code, "")},
    sequence: coalesce(toInteger(raises_button.sequence), 100),
    events: collect(distinct event{
      key: id(event),
      type: raises.type
    }),
    elements: [null]
  } as element_button_map

with distinct
  navigation_map{
    .*,
    elements: apoc.coll.sortMaps(
      apoc.coll.union(
        collect(element_menu_map),
        collect(element_button_map)
      ),
      '^sequence'
    )
  } as navigation

return
  navigation
`;

export default query;
