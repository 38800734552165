import { GET_MODULES_PENDING, GET_MODULES_SUCCESS, GET_MODULES_ERROR } from './types'
import { getModules as getModulesNew_API } from '../../services/api/Widgets/LeftSideMenu/Modules/getModules.api'
import { logger } from "../../services/logger";

const getModulesPending = () => {
  return {
    type: GET_MODULES_PENDING,
  }
}

const getModulesReceived = (data: any[]) => {
  return {
    type: GET_MODULES_SUCCESS,
    data,
  }
}

const getModulesError = (err: string) => {
  return {
    type: GET_MODULES_ERROR,
    err,
  }
}

export const getModules = () => {
  return (dispatch) => {
    dispatch(getModulesPending())
    getModulesNew_API('system')
      .then((resp) => {
        const res = resp.response;
        dispatch(getModulesReceived(res))
      })
      .catch((err: any) => {
        logger.error("Get modules error: ", err)
        dispatch(getModulesError(err))
      })
  }
}
