import { logger } from "../../../logger";
import Connection from "../../Database/connection";
import jwt from 'jsonwebtoken'

export const getData = async (storeName: string, database: any, query: any, params: any) => {
  try {
    var params = params === null ? { source: { id: params.id } } : {
      ...params,
      source: params.source ? Object.assign({ id: params.id }, params.source) : { id: params.id },
      relation: params.relation ? Object.assign({}, params.relation) : {},
      target: params.target ? Object.assign({}, params.target) : {}
    };

    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    params = { ...params, user: local_connection.user, id: params.id }
    let connection = new Connection()
    return connection.runQuery(storeName, query, params);
  } catch (error) {
    logger.error("API getGanttData: ", error)
  }
}