import axios from "axios";

export const serviceFilePreview = async (url: any) => {

    if (!url)
      return null;
  
    let apiURL = (process.env.REACT_APP_API_URL + url.substring(1));
  
    return axios.get(apiURL)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
       // logger.params({ url }).error("Get request error: ", error);
      })
  }
  