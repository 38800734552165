const UPLOAD_FILE_PENDING: string = 'UPLOAD_FILE_PENDING'
const UPLOAD_FILE_SUCCESS: string = 'UPLOAD_FILE_SUCCESS'
const UPLOAD_FILE_ERROR: string = 'UPLOAD_FILE_ERROR'
const SET_PROGRESS_UPLOAD_FILE: string = 'SET_PROGRESS_UPLOAD_FILE'
const SET_ACTIONS_UPLOAD_FILE: string = 'SET_ACTIONS_UPLOAD_FILE'
const INIT_UPLOAD_FILE: string = 'INIT_UPLOAD_FILE'

export { 
    UPLOAD_FILE_PENDING,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    SET_PROGRESS_UPLOAD_FILE,
    SET_ACTIONS_UPLOAD_FILE,
    INIT_UPLOAD_FILE
}