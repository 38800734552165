import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import { closeConfirmModal as closeConfirmModalAction } from '../../../store/modals/action';

class ConfirmModal extends React.Component<any, any> {

  render() {
    const { modal, closeConfirmModal } = this.props

    return (
        <Modal
            open={modal.isConfirmModalOpen}
            dimmer={'blurring'}
            size={'tiny'}
        >
            <Modal.Header>{modal.title}</Modal.Header>
            <Modal.Content>
            <Modal.Description>
                <p>{modal.content}</p>
            </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
            <Button 
                content="Nein"
                color='red'
                labelPosition='right'
                icon='remove'
                onClick={closeConfirmModal}
            />
            <Button
                content="Ja"
                labelPosition='right'
                icon='checkmark'
                onClick={modal.okEvent}
                positive
            />
            </Modal.Actions>
        </Modal>
    )
  }
}


const mapStateToProps = (state: any) => ({
  modal: state.modals
})

const mapDispatchToProps = (dispatch: any) => ({
  closeConfirmModal: () => dispatch(closeConfirmModalAction())
})

export default connect( mapStateToProps, mapDispatchToProps)(ConfirmModal);
