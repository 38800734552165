
export const setCommandItems = (navData) => {
  const commandItems = navData.widgetData.menu?.inline?.elements.map(
    (inlineMenu) => {
      return {
        buttonOption: {
          cssClass: 'e-flat',
          iconCss: inlineMenu.label === '' ? `e-icons e-${inlineMenu.icon.name}` : null,
          content: inlineMenu.label !== '' ? inlineMenu.label : null
        },
        title: inlineMenu.events[0].key
      }
    }
  )
  return commandItems
}