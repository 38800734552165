import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { useSelector } from 'react-redux'
import PageItem from "./components/PageItem";
import SubMenuItem from "./components/SubMenuItem";
import ModuleItem from "./components/ModuleItem";

const BreadcrumbCustom = () => {
  const breadcrumb = useSelector((state: any )=> state.breadcrumb);

  const goHome = () => { window.location.reload(); };

  const renderItems = (item, itemIndex) => {

    if (item.label === 'delete') {
      return null
    }

    switch (item.type) {
      case 'page':
        return <PageItem
          key={`page-item-${item.key} + random():${Math.random()}`}
          itemIndex={itemIndex}
          label={item.label}
          keyID={item.key}
          dataID={item.dataID}
          params={item.params}
          source={item.source}
          relation={item.relation}
          target={item.target}
          config={item.config}
        />
      case 'subMenu':
        return <SubMenuItem
          label={item.label}
          key={`submenu-item-${item.key}`}
        />
      case 'module':
        return <ModuleItem
          label={item.label}
          key={`app-item-${item.key}`}
        />
    }

  }

  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        onClick={goHome}
      >
         <div className='breadcrumb-item'>
          <div className={`e-icons e-home breadcrumb-icon`} />
          <div className='breadcrumb-label'>Home</div>
          <Breadcrumb.Divider icon="right chevron" />
        </div>
      </Breadcrumb.Section>
      {breadcrumb.shiftHelper.length >= 1 ?
        <>
          <Breadcrumb.Section> ... </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
        </>
        : null
      }

      {
        breadcrumb.historyPages.map((item, i) => renderItems(item, i))
      }
    </Breadcrumb>
  )
}

export default BreadcrumbCustom;