import { 
    UPLOAD_FILE_PENDING, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR, SET_PROGRESS_UPLOAD_FILE, SET_ACTIONS_UPLOAD_FILE, INIT_UPLOAD_FILE
} from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
    case UPLOAD_FILE_PENDING:
        return {
            ...state,
            pending: true,
            progress: 0,
            success: false,
            error: false,
            data: null
        }

    case UPLOAD_FILE_SUCCESS:
        return {
            ...state,
            pending: false,
            progress: 100,
            success: true,
            error: false,
            data: action.data,
        }

    case UPLOAD_FILE_ERROR:
        return {
            ...state,
            pending: false,
            progress: 100,
            success: false,
            error: true,
            data: null,
        }

    case INIT_UPLOAD_FILE:

    return {
        ...initialState
    }

    case SET_PROGRESS_UPLOAD_FILE:
        return {
            ...state,
            progress: Math.round(action.progress)
        }

    case SET_ACTIONS_UPLOAD_FILE:

        return {
            ...state,
            actions: action.actions,
            serviceParams: action.serviceParams
        }


    default:
        return state
    }
}

export default reducer
