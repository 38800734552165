import { GET_NAVIGATION_PENDING, GET_NAVIGATION_SUCCESS, GET_NAVIGATION__ERROR, 
  ADD_NAVIGATION, GO_BACK_NAVIGATION, INIT_NAVIGATION, CLEAR_LAST_NAVIGATION } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NAVIGATION_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
        historyPages: [],
        currentPage: null
      }

    case GET_NAVIGATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        historyPages: [],
        currentPage: null
      }

    case GET_NAVIGATION__ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        historyPages: [],
        currentPage: null
      }

    case ADD_NAVIGATION:

        return {
            ...state,
            pending: false,
            success: true,
            error: false,
            historyPages: [...state.historyPages, action.data],
            currentPage: action.data
        }

    case GO_BACK_NAVIGATION:

      return {
        ...state,
            pending: false,
            success: true,
            error: false,
            historyPages: action.historyPages,
            currentPage: action.currentPage
      }

    case INIT_NAVIGATION: 

    return {
      ...state,
          pending: false,
          success: true,
          error: false,
          historyPages: action.historyPages,
          currentPage: action.currentPage
    }

    case CLEAR_LAST_NAVIGATION:
      return {
        ...state,
            pending: false,
            success: true,
            error: false,
            historyPages: action.historyPages,
            currentPage: state.currentPage
      }


    default:
      return state
  }
}

export default reducer
