const initialState: any = {
  pending: false,
  success: false,
  error: false,
  historyPages: [],
  currentPage: null

    /*{
      label: 'Grid',
      widget: 'Graph',
      renderID: 295,
      dataID: 1197,
      params: { "source":{"id":1197}, "relation":null,"target":{"type":"Grid"}}
    } */

    /*currentPage: {
      label: 'Grid',
      widget: 'Graph',
      renderID: 295,
      dataID: 52049,
      params: { "source":{"id":52049}, "relation":null,"target":{"type":"Grid"}}
    } */

    
    //currentPage: null
  //currentPage: null

    /*
    currentPage: {
      label: 'Testy',
      widget: 'Graph',
      renderID: 120,
      dataID: 0,
      params: null
    }

      currentPage: {
        label: 'Navigations',
        widget: 'Grid',
        renderID: 129,
        dataID: 0,
        params: { "source":{"id":52049}, "relation":null,"target":{"type":"Grid"}}
      }
    currentPage: {
      label: 'Navigations',
      widget: 'Grid',
      renderID: 129,
      dataID: 0,
      params: { source:null, relation:null,target:{type:"Navigation"}}
    }

    */

}

export default initialState
