import React from 'react';
import { useSelector } from "react-redux";
import { MyProps } from './types'
import './styles.css'

const Logo: React.FC<MyProps> = () => {
  const themes = useSelector((state: any) => state.getThemes);

  if(!themes.success){
    return (
      <div className="logo" />
    );
  }

  return (
    <div className="logo">
      <img src={themes?.data?.logo} alt="Logo" />
    </div>
  )
}

export default Logo
