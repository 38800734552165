const query = `
with permissions, widget, widget_map, element, id, user
  
  with permissions, widget, widget_map, element, id, user
  where widget_map.widget = 'Grid'
  
  optional match (element)-[:has]->(dependency:System:Workflow:Dependency)-[:on]->(element_target:System:Workflow:Element)-[:with]->(widget_target:System:UserInterface:Widget)
  where not element = element_target
    and exists((dependency)-[:hasType]->(:Apps:Type{code: 'drag-and-drop'}))

  unwind permissions as permission

  optional match (element)-[raises:raises]->(event:Event)<-[:for]-(permission)
  where not raises.type in ["onLoad", "onClose"]

  optional match (widget)-[hasColumn:hasColumn]->(column:Column)-[:for]->(attribute:Attribute)-[:isOfDataType]->(dataType:DataType)

  with distinct
    widget{
      events: collect(distinct event{
        key: id(event),
        type: raises.type
      }),
      dependency: dependency{
        target: {id: id(widget_target)},
        type: 'drag-and-drop'
      },
      query: widget.cypher,
      allowPaging: apoc.convert.toBoolean(coalesce(element.allowPaging, widget.allowPaging)),
      rowSelection: coalesce(element.rowSelection, widget.rowSelection, 'single'),
      pageSize: coalesce(toInteger(element.pageSize), toInteger(widget.pageSize), 20)
    } as widget_map,
    column,
    hasColumn,
    coalesce(toInteger(hasColumn.sequence), toInteger(column.sequence), 0) as sequence,
    attribute,
    dataType

  optional match (column)-[:hasLabel]->(label:Label)
  optional match (label)-[:withName]->(term:Apps:Term)
  optional match (label)-[:hasIcon]->(icon:System:UserInterface:Icon)
  optional match (attribute)-[hasValue:hasValue]->(value:Value)

  with distinct
    widget_map,
    column,
    hasColumn,
    sequence,
    attribute,
    dataType,
    label,
    term,
    icon,
    collect(distinct value) as values
  
  with distinct
    widget_map,
    column,
    hasColumn,
    sequence,
    attribute,
    dataType,
    label,
    term,
    icon,
    values
  order by sequence

  with distinct
    widget_map,
    collect(distinct column{
      id: id(attribute),
      icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
      label: coalesce(case when not term is null then label.ID else label.name end, ""),
      name: attribute.name,
      hide: apoc.convert.toBoolean(coalesce(hasColumn.hidden, column.hidden, false)),
      width: coalesce(toInteger(hasColumn.width), toInteger(column.width), 0),
      type: dataType.name,
      editable: apoc.convert.toBoolean(coalesce(hasColumn.edit, column.edit, false)),
      dropDownData: [value in values | value.name]
    }) as column_maps

  with
    widget_map,
    [
      column_map in column_maps |
      case
        when column_map.width = 0
        then apoc.map.removeKey(column_map, "width")
        else column_map{
          .*,
          minWidth: column_map.width,
          maxWidth: column_map.width + 400
        }
      end
    ] as column_maps

  with distinct
    widget_map{
      .*,
      columns: column_maps
    } as widget_subquery

  return widget_subquery
`

export {query};