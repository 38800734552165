import axios from "axios";
const saltedMd5 = require("salted-md5");

export const setNewPassword = async (password: string, user: string) => {
  const saltedHashAsync = await saltedMd5(user, password, true);

  return axios.post(process.env.REACT_APP_API_URL + "auth/reset-password", {
    user: user,
    password: saltedHashAsync
  });
};
