import React from 'react'
import { connect } from 'react-redux'
import Card from './Gallery.card';
import { getGalleryData } from '../../services/api/Widgets/Gallery/getGalleryData.api';

import './styles.css';
import { run as runActions } from '../../store/actions/run';
import { logger } from '../../services/logger';

class GalleryWidget extends React.Component<any, any> {

  constructor(props) {
    super(props)

    this.state = {
      imageData_data: null
    }
  }

  componentDidMount() {
    const { navData, oldActionsParams, runActionsState } = this.props

    if (navData) {
      getGalleryData('system', null, navData.widgetData.query, { ...navData.params, params: runActionsState.data.params }, navData.widgetData.dataID, oldActionsParams)
        .then((response: any) => {
          this.setState({ imageData_data: response.targets });
          logger.logWidget(navData, runActionsState, oldActionsParams, response, '#ff8800')
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { navData, runActionsState } = this.props;

    if (runActionsState.data.params?.name === undefined) {
      return null
    }

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.runActionsState.data.params?.name !== runActionsState.data.params?.name || prevProps.runActionsState.data.timestamp !== runActionsState.data.timestamp) {
      getGalleryData('system', null, navData.widgetData.query, { ...navData.params, params: runActionsState.data.params }, navData.widgetData.dataID, { ...navData.params, params: runActionsState.data.params })
        .then((response: any) => {
          this.setState({ imageData_data: response.targets });
          console.log(
            `%c ${navData.widgetData.widget}.${navData.widgetData.type} (key: ${navData.widgetData.key}) %c`,
            'background: #f7f414;',
            'background: transparent;',
            {
              query: navData.widgetData.query,
              params: {
                ...navData.params,
                params: runActionsState.data.params
              },
              response: response,
              position: {
                column: navData.widgetData.position.column,
                row: navData.widgetData.position.row
              }
            }
          );
        })
    }
  }

  handleClick = async (imageID, label) => {
    const { navData } = this.props
    runActions(navData.widgetData.events.find(event => event.type === 'onClick').key, imageID, navData.widgetData.oldActionsParams)
    //fetchStateAction(navData.widgetData.events.find(event => event.type == 'onClick').key, imageID, navData.widgetData.oldActionsParams, label, null)
  }

  setCounter = () => {
    const string = this.state.imageData_data.length === 1 ? ' item' : ' items'
    return `(${this.state.imageData_data.length} ${string})`
  }


  render() {
    const { imageData_data } = this.state

    if (!imageData_data) {
      return null
    }

    return (
      <>
        <div className="gallery">
          <div className="gallery-container wrap">
            {imageData_data.map((image) => {
              return (
                <div key={`gallery-card-wrapper-${image.id}`} className="gallery-item" onClick={() => this.handleClick(image.id, image.name)}>
                  <Card
                    key={`gallery-item-${image.id}`}
                    absolutePath={image.absolutePath}
                    imageName={image.name}
                  />
                </div>)
            })}
          </div>
        </div>
        <div className='gallery-counter'>
          {this.setCounter()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({
  // fetchStateAction: (key: number, id: number, params: any, label: any, oldWidgetViewType: string, breadcrumb: any) => dispatch(fetchStateAction(key, id, params, label, oldWidgetViewType, breadcrumb)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryWidget)
