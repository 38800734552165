import {
  ACTIONS_PENDING,
  ACTIONS_SUCCESS,
  ACTIONS_ERROR
} from "./types";

export const runActionsPending = () => {
  return {
    type: ACTIONS_PENDING,
  };
};

export const runActionsReceived = (
  key: any,
  id: any,
  layout: any,
  params: any,
  breadcrumb: any,
  source: any,
  relation: any,
  target: any,
  timestamp: any
) => {
  return {
    type: ACTIONS_SUCCESS,
    key,
    id,
    layout,
    params,
    breadcrumb,
    source,
    relation,
    target,
    timestamp
  };
};

export const runActionsError = (err: any) => {
  return {
    type: ACTIONS_ERROR,
  };
};
