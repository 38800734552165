import React from 'react';
import { connect } from 'react-redux';
import Graph from './graph.widget';
import { getGraphData } from '../../services/api/Widgets/Graph/getGraphData.api';
import { logger } from '../../services/logger';


class GraphWidget extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      graphRender_data: null,

      graphData_data: null,
      graphData_pending: false,
      graphData__success: false,
      graphData_error: false
    }
  }

  componentDidMount() {
    const { navData } = this.props;

    this.setState({ graphRender_data: navData.widgetData })
    this.fetchGraphDataData()
  }

  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.setState({ graphRender_data: navData.widgetData })
      this.fetchGraphDataData()
    }
  }

  componentWillUnmount() {
    this.setState({
      graphRender_data: null,

      graphData_data: null,
      graphData_pending: false,
      graphData__success: false,
      graphData_error: false
    })
  }

  fetchGraphDataData = () => {
    const { navData } = this.props;
    this.setState({ graphData_pending: true })

    getGraphData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...{ source: { id: navData.widgetData.dataID }, data: this.props.oldActionsParams.params.data } }, navData.widgetData.dataID)
      .then((response: any) => {
        logger.logWidget(navData, null, null, response, '#ff8800')
        this.setState({ graphData__success: true, graphData_data: response.response.objects })
      })
      .catch((error: any) => this.setState({ graphData__success: false, graphData_data: null, graphData_error: true }))
      .finally(() => this.setState({ graphData_pending: false }))
  }

  render() {
    const { navData, oldActionsParams } = this.props
    const { graphRender_data, graphData_data, graphData__success } = this.state

    if (!graphData__success || !graphRender_data || !graphData_data)
      return null;

    return (
      <>
        <Graph
          navData={navData}
          renderData={graphRender_data}
          data={graphData_data}
          oldActionParams={oldActionsParams}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphWidget)