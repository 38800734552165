import {
  ACTIONS_PENDING,
  ACTIONS_SUCCESS,
  ACTIONS_ERROR
} from "./types";
import initialState from "./initialState";

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIONS_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false
      };

    case ACTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: {
          key: action.key === null ? state.data.key: action.key,
          id: action.key === null ? state.data.id: action.id,
          layout: action.key === null ? state.data.layout: action.layout,
          params: action.key === null ? state.data.params: action.params,
          breadcrumb: action.key === null ? state.data.breadcrumb: action.breadcrumb,
          source: action.key === null ? state.data.source: action.source,
          relation: action.key === null ? state.data.relation: action.relation,
          target: action.key === null ? state.data.target: action.target,
          timestamp: action.timestamp
        }
      };

    case ACTIONS_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export default reducer;
