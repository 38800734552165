import React from "react";
import { connect } from "react-redux";
import { withRouterHooks } from '../../navigation/router.hooks';
import { Image } from "semantic-ui-react";
import jwt from "jsonwebtoken";
import { run as runActions } from '../../store/actions/run';
import { getModules } from "../../store/getModules/action";
import { checkConnection } from "../../services/api/driver";
import { getNavigation } from "../../services/api/Widgets/LeftSideMenu/Navigation/getNavigation.api";
import './styles.css'
import { withTranslation } from "react-i18next";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

class HeaderMenu extends React.Component<any, any>  {
  constructor(props) {
    super(props);

    this.state = {
      menu: null
    }
  }

  componentDidMount() {
    getNavigation('system', null, 'ce6b3e7f-e0e8-4b14-9e5d-299634fd17de').then((resp) => {
      this.setState({ menu: resp?.navigation?.elements })
    })
  }

  handleClick = (args: any) => {
    const { breadcrumb } = this.props;
    let eventKey = args?.item?.key

    if (!eventKey) return null;

    const urlParam = {
      key: breadcrumb.currentPage?.key,
      id: breadcrumb.currentPage?.dataID,
      params: breadcrumb.currentPage?.params,
      label: null
    }
    const jwtParams = jwt.sign(urlParam, "pdm");
    const copyURL = window.location.href + "/" + jwtParams
    
    runActions(eventKey, args?.item?.properties?.id, copyURL)
  };

  render() {

    const currentUser = jwt.verify(JSON.parse(localStorage.getItem("connection")), process.env.REACT_APP_LICENSE_KEY);
    checkConnection(currentUser)

    return (
      <div className={"childLeaving headerMenu"}>
        {currentUser ? <Image avatar src={currentUser?.user?.image} /> : null}
        <DropDownButtonComponent
          items={this.state.menu ? this.state.menu.map((element) => {
            return {
              text: this.props.t(element.label),
              id: element.key,
              key: element.events.find(event => event.type === 'onClick')?.key
            }
          }) : null}
          iconPosition="Left"
          select={this.handleClick.bind(this)}
          className="header-dropdown-item"
        >
          {currentUser.user.name}
        </DropDownButtonComponent>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => ({
  modules: state.getModules,
  navigation: state.navigation,
  breadcrumb: state.breadcrumb
});

const mapDispatchToProps = (dispatch: any) => ({
  getModulesNew: () => dispatch(getModules()),
  getNavigation: (key: number) => dispatch(getNavigation('system', null, 'ce6b3e7f-e0e8-4b14-9e5d-299634fd17de')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(HeaderMenu)));
