import { GET_MODULES_PENDING, GET_MODULES_SUCCESS, GET_MODULES_ERROR } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MODULES_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false
      }

    case GET_MODULES_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data
      }

    case GET_MODULES_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
        data: []
      }

    default:
      return state
  }
}

export default reducer
