import axios from "axios";
import download from 'js-file-download';
import { logger } from '../../logger';

export const service = async (url: string, params: any) => {
  return axios.get(
    url,
    {
      responseType: 'blob', // 'arraybuffer',
      params: params,
      headers: {
        Accept: 'application/pdf',
      }
    }
  ).then(
    (response) => {
      download(response.data, params.originalName);
    }
  ).catch(
    (error) => {
      logger.params({params}).error('failed to call file/download', error);
    }
  )
}