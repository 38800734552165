const query = `
unwind [
  object in apoc.coll.flatten([$objects]) |
  case apoc.meta.type(object)
    when 'NODE' then id(object)
    when 'MAP' then toInteger(object.id)
    when 'FLOAT' then toInteger(object)
    when 'INTEGER' then object
    else null
  end
] as id

match (node)
where id(node) = id

optional match path=(:System:Schema:NodeType)<-[:extends*0..]-(:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(node))

with
  node,
  path
order by length(path) desc

with
  node,
  apoc.convert.toBoolean(nodes(collect(path)[-1])[-1].log) as log

call apoc.do.when(
  log,
  '
    with $node as node
    
    optional match (node)-[:has]->(log_last:System:Log)<-[current:current]-(node)
    optional match (node)-[relation]-(target)
    where not target:System:Log

    delete current

    with distinct
      node,
      labels(node) as labels,
      properties(node) as properties,
      log_last,
      collect(id(relation)) as relations

    call apoc.create.setLabels(node, ["System", "Backup"])
    yield node as backup

    with
      backup,
      labels,
      properties,
      log_last,
      relations

    create (backup)-[:has]->(log_current:System:Log)<-[:current]-(backup)
    set log_current += {
      action: "delete",
      user: $user.uuid,
      timestamp: datetime(),
      labels: ":" + apoc.text.join(labels, ":"),
      properties: apoc.convert.toJson(properties)          
    }

    create (log_current)-[:next]->(log_last)

    return distinct
      log_current as log,
      relations
  ',
  '
    with
      $node as node
    
    detach delete node
    
    return distinct
      null as log,
      null as relations
  ',
  {
    node: node,
    user: $user
  }
)
yield value

unwind value.relations + [-1] as relation

with distinct
  collect(distinct value.log) as logs,
  collect(distinct relation) as relations

with distinct
  [log in logs | log{id: id(log), .action}] as logs,
  [relation in relations where not relation = -1] as relations

return distinct
  logs as response,
  {
    relations: {
      deleted: relations
    }
  } as log
`;

export { query };
