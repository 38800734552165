import React from 'react';
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';
import { withRouterHooks } from '../../navigation/router.hooks';;
import { withTranslation } from 'react-i18next';
import { query as validateQuery } from '../../services/api/validate/validate.query';
import { run as runAction } from '../../services/api/action/run.api';
import { run as runActions } from '../../store/actions/run';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import store from '../../store/store';
import './styles.css'


class MenuForm extends React.Component<any, any> {

  handleButtonClick = async (element, label, key, validate: boolean, e: any) => {
    this.handleAction(null, label, key, validate, e)
  }

  handleClickDropDownItem(args: any) {
    this.handleAction(args, null, null, null, null)
  }

  handleAction = (args: any, label: any, key: any, validate: boolean, e: any) => {
    const { currentDataID, propertiesFiles, properties, oldActionsParams, getWidgetEditData, globalKeyEvent, viewType, widgetType, reloadLayout } = this.props;

    const _key = args ? args.item.key : key
    const _validate: boolean = args ? true : validate
    const _label = args ? args.item.properties.text : label

    let _data = properties
    let parameters = { ...oldActionsParams, data: _data };

    if (!_key) return null;

    if (store.getState().keyEvent.ctrlKey === true || e?.ctrlKey === true) {
      this.handleNewTab(_key, currentDataID, _label, parameters)
      return null
    }

    switch (widgetType) {
      case 'Diagram':
        //if (viewType === "edit" || viewType === "update") {
          _data = getWidgetEditData()
        //}
        parameters = { ...oldActionsParams, data: _data };
        return runActions(_key, currentDataID, parameters, null);

      case 'View':
        return runActions(_key, currentDataID, parameters, propertiesFiles)

      case 'Gantt':
        return runActions(_key, currentDataID, parameters, propertiesFiles)

      case 'Grid':
        parameters = { ...oldActionsParams, data: _data };
        return runActions(_key, currentDataID, parameters, propertiesFiles)

      case 'TreeGrid':
      case 'Graph':
        if (args) {
          runActions(_key, currentDataID, parameters, propertiesFiles)
          setTimeout(function () {
            let _data = store.getState().actions?.data?.params
            let pathname = new URL(_data?.url).pathname;
            if (!pathname) return null
            switch (pathname) {
              case "/api/services/widgets/graph/rearrange":
                return reloadLayout(_data.layout)
              default:
                return null
            }
          }, 500)
          return null
        } else {
          if (viewType === "edit" || viewType === "update") {
            _data = getWidgetEditData()
          }
          parameters = { ...oldActionsParams, data: _data };
          return runActions(key, currentDataID, parameters, propertiesFiles)
        }

      case 'Form':
        var isValid = true;
        document.querySelectorAll('[id^="validation-label-"]').forEach(function (label, index) {
          label.innerHTML = '';
        });
        if (_validate) {
          return Promise.resolve()
            .then(() => {
              return runAction({ type: 'StoredProcedure', id: 'validate input', query: validateQuery }, { id: currentDataID, data: _data }, null, true);
            })
            .then((response: any) => {
              isValid = response?.response?.isValid;
              if (!isValid) {
                response?.response?.data?.forEach((field: any) => {
                  const element = document.getElementById(`validation-label-${field._key}`);
                  if (field.isValid === false && field?.validations[0]?.message && element)
                    element.innerHTML = this.props.t(field?.validations[0]?.message);
                });
              } else {
                return runActions(_key, currentDataID, parameters, propertiesFiles);
              }
            });
        } else {
          return runActions(_key, currentDataID, parameters, propertiesFiles);
        }
      default:
        return null;
    }
  }

  handleNewTab = (eventKey, currentDataID, args, parameters) => {

    const urlParam = {
      key: eventKey,
      id: currentDataID,
      params: parameters,
      label: args
    }
    const jwtParams = jwt.sign(urlParam, 'pdm');
    window.open(window.location.href + '/' + jwtParams)
    return null
  }

  render() {
    const { menu, setFormOnSubmitFunc } = this.props

    if (!menu.default) return null;
    return (
      <div
        className="dropDown-container"
        id={`menu-${menu.key}`}
      >
        {menu.default?.elements?.map((element, menuIndex) => {
          switch (element.type) {
            case 'menu':
              return (
                <DropDownButtonComponent
                  items={element.elements.sort((a, b) => (a.sequence - b.sequence)).map((subMenuItem) => {
                    return {
                      text: this.props.t(subMenuItem.label),
                      iconCss: 'e-ddb-icons e-dashboard',
                      id: subMenuItem.key,
                      key: subMenuItem.events.find(event => event.type === 'onClick')?.key
                    }
                  })}
                  iconPosition="Left"
                  cssClass='e-custom-dropdown'
                  select={this.handleClickDropDownItem.bind(this)}
                  key={"Dropdown" + menuIndex}
                >
                  {this.props.t(element.label)}
                </DropDownButtonComponent>
              )
            case 'button':
            case 'search':
            case 'submit':
              return (
                <ButtonComponent
                  id={`button-${element.key}`}
                  cssClass='e-custom'
                  style={{ textTransform: "none" }}
                  content={this.props.t(element.label)}
                  disabled={element.disabled}
                  key={`menu-item-${element.key}-random:${Math.random()}`}
                  type={element.type}
                  iconCss={element.icon.name !== '' ? `e-icons e-${element.icon.name}` : null}
                  onClick={(e: any) => {
                    var key = element.events?.find(event => event?.type === 'onClick')?.key;
                    switch (element.type) {
                      case 'search':
                        return setFormOnSubmitFunc((e: any) => this.handleButtonClick(element, element.label, key, false, e));
                      case 'submit':
                        return setFormOnSubmitFunc((e: any) => this.handleButtonClick(element, element.label, key, true, e));
                      case 'button':
                        return this.handleButtonClick(element, element.label, key, false, e);
                      default:
                        break;
                    }
                  }}
                />
              )
            default:
              return null;
          }
        }
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHooks(withTranslation()(MenuForm)));
