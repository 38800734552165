import axios from "axios";
import { logger } from '../../logger';

export const service = async (url: string, params: any) => {
  params = {directory: params.directory, image: params.image, absolutePath: params.absolutePath};
  return axios.post(
    url,
    params,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(
    (response) => {
      return response.data;
    }
  ).catch(
    (error) => {
      logger.params({ params }).error('failed to call service: image/upload', error);
    }
  )
}