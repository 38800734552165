import React from 'react'
import jwt from 'jsonwebtoken';
import { Breadcrumb } from 'semantic-ui-react'
import { useDispatch } from "react-redux";
import { goBackBreadcrumbAction } from '../../../../../store/breadcrumb/action';
import { runActionsReceived } from '../../../../../store/actions/actions';
import { checkConnection } from '../../../../../services/api/driver';
import { setGlobalKeyEvent } from '../../../../../store/globalKeyEvent/action';

const PageItem = ({ itemIndex, label, keyID, dataID, params, source, relation, target, config }) => {
  const dispatch = useDispatch();

  const handleSectionClick = (e: any) => {
    checkConnection(localStorage.getItem("connection"))

    if (e.ctrlKey === true) {
      setGlobalKeyEvent(false, false)
      const urlParam = {
        key: keyID,
        id: dataID,
        params: null,
        label: null
      }
      const jwtParams = jwt.sign(urlParam, 'pdm');
      window.open(window.location.href + '/' + jwtParams)
      return null
    }
    console.log(`%c Breadcrumb %c clicked with params: `, 'background: #634C2A; color: white;', 'background: transparent; color: black;', {
      key: keyID,
      id: dataID,
      params: params,
      source: source,
      relation: relation,
      target: target,
      config: config
    });
    dispatch(runActionsReceived(keyID, dataID, null, params, null, source, relation, target, null))
    dispatch(goBackBreadcrumbAction(itemIndex))
  }


  return (
    <Breadcrumb.Section
      link
      key={`key-${keyID}`}
      onClick={(e: any) => handleSectionClick(e)}
    >
      <div className='breadcrumb-item'>
        <div className={`e-icons e-${config?.icon?.name} breadcrumb-icon`} style={{ color: config?.icon?.color }} />
        <div className='breadcrumb-label'>{config?.label?.name}</div>
        <Breadcrumb.Divider icon="right chevron" />
      </div>
    </Breadcrumb.Section>
  )
}

export default PageItem