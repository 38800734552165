import i18n from '../../../i18n';
import { run as runQuery } from '../query/run.api';
import query from "./Translations.query";
import jwt from 'jsonwebtoken'

export const service = async (url: string) => {

  const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);

  runQuery('system', query, null)
    .then((response: any) => {
      localStorage.setItem('translations', JSON.stringify(response.response.translations));
      const lang = localStorage.getItem("i18nextLng")

      if (lang === 'lng1') {
        i18n.changeLanguage("lng2")
      } else {
        i18n.changeLanguage("lng1")
      }
      window.location.href = '/app'
    })
    .catch((error: any) => console.log(error));
}
