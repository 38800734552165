import axios from "axios";
import { logger } from '../../logger';

export const service = async (url: string, params: any) => {
  params = {absolutePath: params.absolutePath || null, absolutePaths: params.absolutePaths || null}
  
  return axios.post(
    url,
    params,
    {
      headers: {
        'Content-Type': 'application/json' //'multipart/form-data'
      }
    }
  ).then(
    (response) => {
      return response.data.data;
    }
  ).catch(
    (error) => {
      logger.params({ params }).error('failed to call service: file/remove', error);
    }
  )
}
