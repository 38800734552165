import { query as getFormQuery } from './widgets/getForm.query';
import { query as getGridQuery } from './widgets/getGrid.query';
import { query as getContextQuery } from './widgets/getContext.query';
import { query as getGraphQuery } from './widgets/getGraph.query';
import { query as getGalleryQuery } from './widgets/getGallery.query';
import { query as getTreeGridQuery } from './widgets/getTreeGrid.query';
import { query as getGanttQuery } from './widgets/getGantt.query';
import { query as getViewQuery } from './widgets/getView.query';
import { query as getDiagramQuery } from './widgets/getDiagram.query';

const query = `
// test graph-click:
// :params {id: 4314,key: 7035, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test context-click:
// :params {id: 314,key: 6433, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test navigation click:
// :params {id: null,key: 3161, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test navigation click (eplan filter graph):
// :params {id: null,key: 3895, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test navigation click (image gallery):
// :params {id: null,key: 6980, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test inspect click:
// :params {id: 29,key: 2980, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test properties view:
// :params {id: 12313,key: 10343, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// test validations for image form view:
// :params {id: 13687, key: 7790, user:{uuid:"8d1bd521-c47f-474c-b0f6-705d113bcc6c"}}
// test diagram:
// :params {id: 205318,key: 1237, user: {uuid: "e39883b1-5420-11e8-a89d-0242a1a4bb0f"}}
// view diagram 2:
// :params {id: 254450, key: 212280, user:{uuid:"8d1bd521-c47f-474c-b0f6-705d113bcc6c"}}

match (user:System:AccessControl:User)
where user.uuid = $user.uuid

optional match (user)-[:plays]->(role:System:AccessControl:Role)
optional match (role)-[:includes*..]->(role_includes:System:AccessControl:Role)

with distinct
  [role] + collect(distinct role_includes) + [] as roles

unwind roles as role

match (role)-[:hasGiven]->(permission:System:AccessControl:Permission)

with distinct
  collect(distinct permission) as permissions

match (event:System:Workflow:Event)
where id(event) = toInteger($key)

optional match (event)-[:uses]->(layout:System:UserInterface:Layout)
optional match (layout)-[hasColumn:hasColumn]->(column:System:UserInterface:Column)
optional match (column)-[hasRow:hasRow]->(row:System:UserInterface:Row)

with distinct
  permissions,
  event,
  layout,
  case when hasColumn is null then 0 else toInteger(hasColumn.sequence) end as column_sequence,
  case when hasRow is null then 0 else toInteger(hasRow.sequence) end as row_sequence,
  case when hasColumn is null then 1 else coalesce(toInteger(hasColumn.width), 1) end as width,
  case when hasRow is null then 1 else coalesce(toInteger(hasRow.height), 1) end as height,
  column,
  row

with
  permissions,
  event,
  layout,
  column_sequence,
  width,
  column,
  apoc.coll.sortMaps(collect({
    position: row_sequence,
    height: height
  }), '^position') as rows

with
  permissions,
  event,
  layout,
  column_sequence,
  width,
  column,
  rows,
  apoc.coll.sum([row in rows | row.height]) as height

unwind rows as row

with distinct
  permissions,
  event,
  layout,
  column_sequence,
  width,
  column,
  [
    row in rows |
    {
      position: apoc.coll.indexOf(rows, row) + 1,
      height: toInteger((100.0 / toFloat(height)) * toFloat(row.height))
    }
  ] as rows

with
  permissions,
  event,
  layout,
  apoc.coll.sortMaps(collect({
    position: column_sequence,
    width: width,
    rows: rows
  }), '^position') as columns

with
  permissions,
  event,
  layout,
  columns,
  apoc.coll.sum([column in columns | column.width]) as width

with
  permissions,
  event,
  case when layout is null then null else {
    columns: [
      column in columns |
      column{
        .*,
        position: apoc.coll.indexOf(columns, column) + 1,
        width: toInteger((100.0 / toFloat(width)) * toFloat(column.width))
      }
    ]
  } end as layout

optional match (event)-[runs_calls:runs|calls]->(action:System:Workflow:Action)

with
  permissions,
  event,
  layout,
  action,
  toInteger(runs_calls.sequence) as sequence

with
  permissions,
  event,
  layout,
  case
    when any(label in labels(action) where label = 'Query')
    then action{
      id: id(action),
      type: 'Query',
      sequence: sequence,
      query: action.cypher,
      params: {
          id: toInteger($id)
        }
    }
    when any(label in labels(action) where label = 'Service')
    then action{
      id: id(action),
      type: 'Service',
      sequence: sequence,
      url: action.url,
      method: coalesce(toUpper(toString(action.method)), 'GET'),
      params: case when action.params is null then null else apoc.convert.fromJsonMap(action.params) end
    }
    else null
  end as action

with distinct
  permissions,
  event,
  layout,
  apoc.coll.sortMaps(collect(distinct action), '^sequence') as actions

optional match (event)-[:loads]->(:System:Workflow:Element)-[:with]->(:System:UserInterface)<-[:hasWidget]-(nodeType_widget:System:Schema:NodeType)

with distinct
  permissions,
  event,
  layout,
  actions,
  collect(distinct nodeType_widget) as nodeTypes_widget

optional match (node)
where id(node) = toInteger($id)

optional match (nodeType_node:System:Schema:NodeType)
where nodeType_node.name in labels(node)

with distinct
  permissions,
  event,
  layout,
  actions,
  nodeTypes_widget,
  collect(distinct nodeType_node) as nodeTypes_node

with distinct
  permissions,
  event,
  layout,
  actions,
  [nodeType_widget in nodeTypes_widget where nodeType_widget in nodeTypes_node][0] as nodeType  

optional match (event)-[loads:loads]->(element:System:Workflow:Element)-[:with]->(widget:System:UserInterface)
where (
  (
    (widget)<-[:hasWidget]-(nodeType)
    or $id is null
  ) and any(label in labels(widget) where label in ['Graph'])
) or (
  all(label in labels(widget) where not label in ['Graph'])
)

with distinct
  permissions,
  event,
  layout,
  actions,
  //loads,
  //element,
  nodeType,
  [widget_ in collect(distinct widget) where not widget_ is null] as widgets

with distinct
  permissions,
  event,
  layout,
  actions,
  //loads,
  //element,
  nodeType,
  //case when size(widgets) > 1 then [widget in widgets where (widget)<-[:hasWidget]-(nodeType)] else widgets end as widgets
  case when not nodeType is null then [widget in widgets where (widget)<-[:hasWidget]-(nodeType)] else widgets end as widgets

unwind widgets as widget

with distinct
  permissions,
  event,
  layout,
  actions,
  //loads,
  //element,
  widget
where not widget is null

match (event)-[loads:loads]->(element:System:Workflow:Element)-[:with]->(widget)
optional match (element)-[:hasLabel]->(label_element:Label)
optional match (widget)-[:hasLabel]->(label_widget:Label)

with distinct
  permissions,
  event,
  layout,
  actions,
  widget,
  loads,
  element,
  coalesce(label_element, label_widget) as label

optional match (label)-[:withName]->(term:Apps:Term)

with distinct
  permissions,
  layout,
  actions,
  element,
  widget,
  widget{
    key: id(widget),
    widget: coalesce([label_ in labels(widget) where label_ in ['Grid','Form','Gallery','Graph','Context','TreeGrid','Gantt','View','Diagram']][0], widget.widget),
    label: coalesce(case when not term is null then label.ID else label.name end, ''),
    type: coalesce(widget.type, 'view'),
    sequence: coalesce(toInteger(loads.sequence), toInteger(widget.sequence), 100),
    modal: apoc.convert.toBoolean(element.modal),
    height: coalesce(element.height, widget.height),
    width: coalesce(element.width, widget.width),
    position: {
      // target: coalesce(loads.target, 'self'),
      column: coalesce(toInteger(loads.column), 1),
      row: coalesce(toInteger(loads.row), 1)
    }
  } as widget_map
  
optional match (:Schema:NodeType{name: widget_map.widget})-[:hasStyle]->(style:Style)
optional match (style)-[:hasIcon]->(icon:Icon)
optional match (style)-[:hasColor{type: 'icon'}]->(color_icon:Color)
optional match (style)-[:hasColor{type: 'font'}]->(color_font:Color)

with distinct
  permissions,
  layout,
  actions,
  element,
  widget,
  widget_map{
    .*,
    style: {
      icon: {
        class: icon.name,
        color: color_icon.hex
      },
      label: {
        color: color_font.hex
      }
    }
  },
  toInteger($id) as id,
  $user as user

call {
  ${getFormQuery}
  union
  ${getGridQuery}
  union
  ${getContextQuery}
  union
  ${getGraphQuery}
  union
  ${getGalleryQuery}
  union
  ${getTreeGridQuery}
  union
  ${getGanttQuery}
  union
  ${getViewQuery}
  union
  ${getDiagramQuery}
}

with distinct
  permissions, 
  layout,
  actions,
  element,
  widget,
  apoc.map.merge(
    widget_map,
    widget_subquery
  ) as widget_map

optional match (element)-[raises:raises]->(event:System:Workflow:Event)-[:loads]->(menu:System:Workflow:Menu)
where raises.type = 'onLoad'
  and any(permission in permissions where (permission)-[:for]->(event))

optional match (menu)-[:hasLabel]->(label:Label)
optional match (menu)-[:hasIcon]->(icon:System:UserInterface:Icon)
optional match (label)-[:withName]->(term:Apps:Term)

with distinct
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu{
    key: id(menu),
    type: 'menu',
    label: coalesce(case when not term is null then label.ID else label.name end, ''),
    icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
    name: menu.name,
    sequence: 1
  } as menu_map

optional match (menu)-[raises_menu:raises]->(event_menu:System:Workflow:Event)-[:loads]->(element_menu:System:Workflow:Menu)-[raises_button:raises]->(event_button:System:Workflow:Event)-[:loads]->(element_button:System:Workflow:Button)
where raises_menu.type = raises_button.type = 'onLoad'

optional match (event_button)-[:enabledBy]->(query_enabledBy:System:Workflow:Query)

with
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  raises_menu,
  event_menu,
  element_menu,
  raises_button,
  event_button,
  element_button,
  coalesce(query_enabledBy.cypher, "return {enabled: true} as response") as cypher_enabled

call apoc.cypher.run(cypher_enabled, {key: id(event_button), id: $id, user: $user}) yield value

with
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  raises_menu,
  event_menu,
  element_menu,
  raises_button,
  event_button,
  element_button,
  apoc.convert.toBoolean(value.response.enabled) as button_enabled

optional match (element_menu)-[:hasLabel]->(label_menu:Label)
optional match (element_menu)-[:hasIcon]->(icon_menu:System:UserInterface:Icon)
optional match (label_menu)-[:withName]->(term_menu:Term)
optional match (element_button)-[:hasLabel]->(label_button:Label)
optional match (element_button)-[:hasIcon]->(icon:System:UserInterface:Icon)
optional match (label_button)-[:withName]->(term_button:Term)
optional match (element_button)-[raises:raises]->(event:System:Workflow:Event)
where not raises.type in ['onLoad', 'onClose']

with distinct
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  element_menu,
  element_menu{
    key: id(element_menu),
    type: 'menu',
    label: coalesce(case when not term_menu is null then label_menu.ID else label_menu.name end, ""),
    icon: icon_menu{.name, .code},
    name: element_menu.name,
    sequence: coalesce(toInteger(raises_menu.sequence), 100),
    events: [null],
    elements: [null],
    disabled: not any(permission in permissions where (permission)-[:for]->(event_menu))
  } as element_menu_map,
  element_button{
    key: id(element_button),
    type: element_button.type, // 'button',
    breadcrumb: coalesce(element_button.breadcrumb, 'ignore'),
    label: coalesce(case when not term_button is null then label_button.ID else label_button.name end, ""),
    icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
    name: element_button.name,
    sequence: coalesce(toInteger(raises_button.sequence), 100),
    disabled: not any(permission in permissions where (permission)-[:for]->(event_button)) or not button_enabled,
    events: collect(distinct event{
      key: id(event),
      type: raises.type,
      mode: coalesce(raises.mode, "load")
    }),
    elements: [null]
  } as element_button_map

with distinct
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  element_menu_map{
    .*,
    elements: apoc.coll.sortMaps(collect(distinct element_button_map), '^sequence')
  }

optional match (menu)-[raises_button:raises]->(event_button:System:Workflow:Event)-[:loads]->(element_button:System:Workflow:Button)
where raises_button.type = 'onLoad'

optional match (event_button)-[:enabledBy]->(query_enabledBy:System:Workflow:Query)

with
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  element_menu_map,
  raises_button,
  event_button,
  element_button,
  coalesce(query_enabledBy.cypher, "return {enabled: true} as response") as cypher_enabled

call apoc.cypher.run(cypher_enabled, {key: id(event_button), id: $id, user: $user}) yield value

with
  permissions,
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  element_menu_map,
  raises_button,
  event_button,
  element_button,
  apoc.convert.toBoolean(value.response.enabled) as button_enabled

optional match (element_button)-[:hasLabel]->(label_button:Label)
optional match (element_button)-[:hasIcon]->(icon:System:UserInterface:Icon)
optional match (label_button)-[:withName]->(term_button:Term)
optional match (element_button)-[raises:raises]->(event:System:Workflow:Event)
where not raises.type in ['onLoad', 'onClose']

with distinct
  layout,
  actions,
  widget_map,
  menu,
  menu_map,
  element_menu_map,
  element_button{
    key: id(element_button),
    type: element_button.type, // 'button',
    breadcrumb: coalesce(element_button.breadcrumb, 'ignore'),
    label: coalesce(case when not term_button is null then label_button.ID else label_button.name end, ""),
    icon: {name: coalesce(icon.name, ""), code: coalesce(icon.code, "")},
    name: element_button.name,
    sequence: coalesce(toInteger(raises_button.sequence), 100),
    disabled: not any(permission in permissions where (permission)-[:for]->(event_button)) or not button_enabled,
    events: collect(distinct event{
      key: id(event),
      type: raises.type,
      mode: coalesce(raises.mode, "load")
    }),
    elements: [null]
  } as element_button_map

with distinct
  layout,
  actions,
  widget_map,
  menu,
  menu_map{
    .*,
    elements: apoc.coll.sortMaps(
      apoc.coll.union(
        collect(element_menu_map),
        collect(element_button_map)
      ),
      '^sequence'
    )
  }

with distinct
  layout,
  actions,
  widget_map{
    .*,
    menu: apoc.map.merge(
      [menu_ in collect(distinct menu_map) where menu_.type = 'menu'][0],
      apoc.map.fromPairs(collect(distinct [coalesce(menu.type, 'default'), menu_map]))
    ),
    timestamp: toInteger(timestamp() / 1000)
  }

with
  layout,
  actions,
  collect(widget_map) as widgets

return
  layout,
  actions,
  widgets
  `;

export default query;
