import { service as fileUploadService } from '../file/upload';
import { service as fileRemoveService } from '../file/remove'
import { service as fileHashService } from '../file/getHash';
import { service as fileDownloadService } from '../file/download';
import { service as fileViewService } from '../file/view';
import { service as imageUploadService } from '../image/upload';
import { service as imageConvertService } from '../image/convert';
import { service as fileGetMetaDataService } from '../file/metadata/get';
import { service as clipboardCopyService } from '../clipboard/copy'
import { service as copyUrlService } from '../clipboard/url/copy';
import { service as reloadTranslationsService } from '../Translations/Translations.reload'
import { service as logoutService } from '../Session/Session.logout'
import { service as historyBack } from '../history/history.back'
import { service as graphAddNodesService } from '../Widgets/Graph/addNodes'
import { service as graphRemoveNodesService } from '../Widgets/Graph/removeNodes'
import { service as graphRearangeNodesService } from '../Widgets/Graph/rearrangeNodes'
import { logger } from '../../logger';
import { service as encryptService } from '../tools/encrypt';
import { service as decryptService } from '../tools/decrypt';

export const call = async (path: string, params: any, files: any = null) => {
  path = path.replace(/^\/+|\/+$/g, '');
  let url = `${process.env.REACT_APP_API_URL.replace(/^\/+|\/+$/g, '')}/${path}`;

  switch (path) {
    case 'services/file/upload':
      return fileUploadService(url, params, files);
    case 'services/file/remove':
      return fileRemoveService(url, params);
    case 'services/file/getHash':
      return fileHashService(url, files);
    case 'services/file/download':
      return fileDownloadService(url, params);
    case 'services/file/view':
      return fileViewService(url, params);
    case 'services/file/metadata/get':
      return fileGetMetaDataService(url, params);
    case 'services/image/upload':
      return imageUploadService(url, params);
    case 'services/image/convert':
      return imageConvertService(url, params);
    case 'services/clipboard/copy':
      return clipboardCopyService(params);
    case 'services/copy-url':
      return copyUrlService(params);
    case 'services/session/reload-translations':
      return reloadTranslationsService(url); //, params);
    case 'services/session/logout':
      return logoutService();
    case 'services/history/back':
      return historyBack(url, params);
    case 'services/widgets/graph/add-nodes':
      return graphAddNodesService(url, params);
    case 'services/widgets/graph/remove-nodes':
      return graphRemoveNodesService(url, params);
    case 'services/widgets/graph/rearrange':
      return graphRearangeNodesService(url, params);
    case 'services/tools/encrypt':
      return encryptService(params.text, params.key);
    case 'services/tools/decrypt':
      return decryptService(params.code, params.key);
    default:
      logger.params({ params }).error(`unknown service called: ${path}`)
  }
}