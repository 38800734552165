import { logger } from "../../../../logger";
import Connection from "../../../Database/connection";
import getNavigationQuery from "./getNavigation.query";
import jwt from 'jsonwebtoken'

export const getNavigation = async (storeName: string, key: number, uuid?: string) => {
  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { key: key, uuid: uuid === undefined ? null : uuid, user: local_connection.user }
    let connection = new Connection()
    return connection.runQuery(storeName, getNavigationQuery, parameters);
  } catch (error) {
    logger.error("API getNavigation error:  ", error)
  }
}
