import React from "react";
import { DateTimePicker, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Internationalization } from '@syncfusion/ej2-base';
import jwt from 'jsonwebtoken'
import { withTranslation } from "react-i18next";
import { loadCldr } from "@syncfusion/ej2-base";
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from '@syncfusion/ej2-base';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  'de': {
    'datetimepicker': {
      placeholder: 'Wählen Sie ein Datum und eine Uhrzeit aus',
      today: 'heute'
    }
  }
});

class DateTimeField extends React.Component<any, any> {

  dateTimepickerInstance: DateTimePickerComponent;

  user = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY).user

  timeFormat = this.user?.timeFormat;
  dateFormat = this.user?.dateFormat;
  timezone = this.user?.timezone;

  setDateTimeValue = () => {
    const { value } = this.props
    if (value === null || undefined) {
      return null;
    } else {
      const date = typeof(value) === 'string' ? new Date(value) : new Date(Date.UTC(value.year, value.month - 1, value.day, value.hour, value.minute, value.second));
      let intl = new Internationalization();
      let dFormatter = intl.getDateFormat({
        skeleton: 'medium',
        type: 'dateTime',
        format: `${this.dateFormat} ${this.timeFormat}`
      });
      return dFormatter(date);
    }


  }

  onChange = (args: any) => {
    const { setNewValue } = this.props;
    setNewValue(new Date(args?.value).toISOString() ?? new Date().toISOString());
  }


  render() {
    const { field, isLocked, value, hiddenClass } = this.props;

    return (
      <div className={`form-input-container ${hiddenClass}`}>
        <label className='form-input-label'>{this.props.t(field.label)}</label>
        <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
        <div
          className='form-input-element '
        >
          <DateTimePickerComponent
            ref={(scope) => { (this.dateTimepickerInstance as DateTimePicker | null) = scope; }}
            firstDayOfWeek={1}
            openOnFocus={true}
            className='form-input-element'
            name="dateTime"
            placeholder={value ? this.setDateTimeValue() : ""}
            change={this.onChange.bind(this)}
            format={'yyyy-MM-dd HH:mm'}
            value={this.setDateTimeValue()}
            enabled={!isLocked}
            timeFormat={this.timeFormat}
          />
        </div>
      </div>
    )
  }
}
export default (withTranslation()(DateTimeField));