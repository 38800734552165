import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const HTMLField: FC<any> = ({ field, value, hiddenClass, setColorField }) => {

  const { t } = useTranslation();

  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div
        className='form-input-element'
        // style={{ backgroundColor: setColorField() }}
      >
        <div className='htmlContent' dangerouslySetInnerHTML={{ __html: value }}></div>
      </div>
    </div>
  )
}
export default HTMLField;