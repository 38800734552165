import getStateQuery from "./getState.query";
import Connection from "../Database/connection";
import { logger } from "../../logger";
import jwt from 'jsonwebtoken'

export const getState = async (storeName: string, key: any, id: any) => {

  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { id: id, key: key, user: local_connection.user }
    let connection = new Connection()
    return connection.runQuery(storeName, getStateQuery, parameters);
  } catch (error) {
    logger.error("API getState error:  ", error)
  }
}