
import { getTreeGridData } from "../../../services/api/Widgets/Treegrid/getTreeGridData.api";

const fetchTreeGridData: any = ($this, database: any, cypher: any, params: any, dataID: any, oldActionsParams: any, navData) => {

  $this.setState({ treeData_pending: true })

  return getTreeGridData('system', database, cypher, params, dataID, oldActionsParams)
    .then(
      (response) => {
        $this.setState({ treeData_pending: false, treeData_data: response.response });
        return response.response;
      }
    )
    .catch(
      (error: any) => console.log(" ERROR getTreeGridData", error)
    )

}

export default fetchTreeGridData;