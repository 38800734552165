import React from 'react'
import { Grid } from 'semantic-ui-react'
import './styles.css'
import { connect } from 'react-redux'
import Logo from './components/Logo/Logo'
import ModulesSelect from './components/modulesSelect/modulesSelect';
import NavigationMenu from './components/navigationMenu/navigationMenu';
import { MyState } from './types'
import NotificationMessage from '../NotificationMessage/errorBoundary'
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';

class LeftSideMenu extends React.Component<any, MyState> {

  public sidebarobj: SidebarComponent;

  render() {
    const { themes, runActionsState } = this.props;

    return (
      <Grid.Column width={3} className="LeftGrid" style={{ height: '100%' }}>
        <SidebarComponent
          ref={Sidebar => this.sidebarobj = Sidebar}
          style={{ backgroundColor: themes?.data?.colors?.primary?.hex }}
          width={this.props.isMenuOpen ? '300' : '30'}
          enableGestures={false}
        >
          {/* <div className='closeIcon' style={{ cursor: 'pointer' }} onClick={() => { this.props.handleLeftSidebar() }}>X</div> */}
          <div className={`float-left e-icons ${this.props.isMenuOpen ? 'close-navigation-icon' : 'open-navigation-icon'}`} style={{ cursor: 'pointer', color: themes?.data?.colors?.primary?.contrast }} onClick={() => { this.props.handleLeftSidebar() }}></div>
          <div className={this.props.isMenuOpen ? '' : 'hide'}>
            <NotificationMessage>
              <Logo />
            </NotificationMessage>

            <NotificationMessage>
              <ModulesSelect />
            </NotificationMessage>

            <NotificationMessage>
              <NavigationMenu runActionsState={runActionsState} />
            </NotificationMessage>
          </div>
        </SidebarComponent>
      </Grid.Column>
    )
  }
}

const mapStateToProps = (state: any) => ({
  themes: state.getThemes,
  runActionsState: state.actions
})

export default connect(mapStateToProps)(LeftSideMenu)