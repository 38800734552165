import React from "react";
import { connect } from 'react-redux';
import { withRouterHooks } from '../../../../../navigation/router.hooks';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withTranslation } from "react-i18next";
import { closeRichtextDialog } from "../../../../../store/modals/action";
import { query as mergeElementsQuery } from "./RichtextDialogLists/Queries/mergeElementsQuery";
import { run as runAction } from '../../../../../services/api/action/run.api';
import { write, write as writeLog } from "../../../../../services/api/log/write";
import { Tab } from 'semantic-ui-react'
import DiagramGrid from "./RichtextDialogLists/Diagram/DiagramGrid";
import ImageGrid from "./RichtextDialogLists/Image/ImageGrid";
import LinkGrid from "./RichtextDialogLists/Link/LinkGrid";
import LinkForm from "./RichtextDialogLists/Link/LinkForm";
import ImageForm from "./RichtextDialogLists/Image/ImageForm";
import { notifyError } from "../../../../../components/Toasts/toasts";
import jwt from 'jsonwebtoken'
import './styles.css';

class RichtextDialog extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedElement: null,
      externURL: null
    };
  }
  dialogInstance: DialogComponent | null;

  buttons: any = [
    {
      buttonModel: {
        content: 'ok',
        cssClass: 'e-flat',
        isPrimary: true
      },
      'click': () => {
        const { selectedElement, externURL } = this.state

        const inputTextElement: any = document.querySelector('input.textbox-link-text')

        if (inputTextElement?.value === '') {
          notifyError("Text input is missing")
          return null
        }
        if (externURL !== null) {
          let url
          if (externURL.url !== '') {
            try {
              url = new URL(externURL.url);
            } catch (_) {
              notifyError("URL is not valid")
              return null;
            }
          }
          switch (externURL.type) {
            case 'image':
              if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url) === false) {
                notifyError("URL is not a valid image")
                return null
              }
              this.props.modals.imageEvent(this.state.selectedElement, url?.href);
              this.props.closeRichtextDialog();
              return null
            case 'link':
              this.props.modals.linkEvent(null, inputTextElement?.value, url.href);
              this.props.closeRichtextDialog();
              return null
            default:
              return null;
          }
        }

        const connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
        let parameters = {
          ...selectedElement,
          link: {
            name: inputTextElement?.value ?? selectedElement?.target?.name ?? selectedElement?.name
          },
          user: connection.user
        }

        runAction({ type: 'StoredProcedure', id: 'merge elements', query: mergeElementsQuery() }, parameters)
          .then((response: any) => {
            write(response.log)
              .then(
                () => {
                  switch (this.props.modals.eventType) {
                    case 'image_grid':
                      return this.props.modals.imageEvent(this.state.selectedElement);
                    case 'link_grid':
                      return this.props.modals.linkEvent(selectedElement, inputTextElement?.value, null);
                    case 'diagram_grid':
                      return this.props.modals.diagramEvent(selectedElement);
                    default:
                      return null;
                  }
                }
              )
              .finally(
                () => {
                  this.props.closeRichtextDialog();
                }
              )
          })
          .catch((error: any) => console.error("RichtextDialog:  ", error));
      }
    },
    {
      buttonModel: {
        content: 'cancel',
        cssClass: 'e-flat',
        isPrimary: false,
      },
      'click': () => {
        this.props.closeRichtextDialog();
      }
    },
  ];


  getListRender = (type, tabType) => {
    const { modals } = this.props

    switch (type) {
      case 'image_grid':
        return <ImageGrid
          params={modals.imageDialog.params}
          $this={this}
          tabType={tabType}
        />
      case 'link_grid':
        return <LinkGrid
          params={modals.imageDialog.params} // ToDo: selected phrase goes her as {name: XXX}
          $this={this}
          tabType={tabType}
        />
      case 'diagram_grid':
        return <DiagramGrid
          params={modals.imageDialog.params}
          $this={this}
          tabType={tabType}
        />
      case 'link':
        return <LinkForm
          params={modals.imageDialog.params}
          $this={this}
        />
      case 'image':
        return <ImageForm
          params={modals.imageDialog.params}
          $this={this}
        />
      default:
        return null
    }
  }

  setHeader = (type) => {
    switch (type) {
      case 'image_grid':
        return 'get linked image'
      case 'link_grid':
        return 'get link'
      case 'diagram_grid':
        return 'get diagram'
      case 'link':
        return 'set external link'
      case 'image':
        return 'set external image'
      default:
        return null
    }
  }


  public render() {
    const { modals } = this.props;

    if (!modals.imageDialog.params) return null

    const panes = [
      { menuItem: 'New', render: () => <Tab.Pane> {this.getListRender(modals.eventType, 'new')}</Tab.Pane> },
      { menuItem: 'Existing', render: () => <Tab.Pane> {this.getListRender(modals.eventType, 'existing')}</Tab.Pane> }
    ]

    return (
      <div className="App" id='container'>
        <DialogComponent
          open={modals.imageDialog.isOpen}
          id={`dialog-${!modals.imageDialog.params}`}
          className={'dialog-container'}
          width={modals.eventType === 'link' || modals.eventType === 'image' ? '500px' : '1000px'}
          height={modals.eventType === 'link' || modals.eventType === 'image' ? '240px' : '950px'}
          isModal={true}
          target='#container'
          header={this.setHeader(modals.eventType)}
          closeOnEscape={true}
          buttons={this.buttons}
          ref={dialog => this.dialogInstance = dialog!}
        >
          <div>
            {modals.eventType === 'link' || modals.eventType === 'image'
            ?
            this.getListRender(modals.eventType, 'new')
            :
            <Tab
              panes={panes}
              menu={{ text: true }}
            />
            }
          </div>
        </DialogComponent>
      </div>);
  }
}

const mapStateToProps = (state: any) => ({
  modals: state.modals
})

const mapDispatchToProps = (dispatch: any) => ({
  closeRichtextDialog: () => dispatch(closeRichtextDialog())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(RichtextDialog)))

