import React from 'react'
import { Grid, Header, Label, Message, Segment } from 'semantic-ui-react';
import { Formsy } from 'formsy-react/dist/Formsy';
import { Form } from 'formsy-semantic-ui-react';
// import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import { validateUserAnswer } from '../../services/api/user/validateUserAnswer';
import { getUserQuestion } from '../../services/api/user/getUserQuestion';
import { notifyError } from '../../components/Toasts/toasts';

class RequestPasswordPage extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      user: '',
      keyAnswer: '',
      keyQuestion: ''
    }
  }

  private formRef = React.createRef<Formsy>();

  componentDidMount(): void {
    this.setState({ user: localStorage.getItem("user") })

    getUserQuestion(localStorage.getItem("user"))
      .then((res) => {
        this.setState({ keyQuestion: res.data.data.question })
      })
  }

  handleSubmit = () => {
    validateUserAnswer(this.state.user, this.state.keyAnswer)
      .then((res) => {
        if (!res.data.data.result) {
          notifyError(" Your answer is wrong ")
          return null
        }
        this.setState({
          user: '',
          keyAnswer: '',
          keyQuestion: ''
        })
        window.location.href = process.env.PUBLIC_URL + "reset-password"
      })
  }


  render() {
    const logo = require('../../images/logo.png')
    const errorLabel = <Label color="red" pointing />

    return (
      <>
        <Grid textAlign="center" style={{ margin: '0px', padding: '0px', height: '100vh' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <img src={logo} alt="Logo" height={150} width={200} />
            <Header as="h1" textAlign="center" className="my-title">
              Password reset
            </Header>
            <Message success>Please answer the question</Message>
            <Form
              size="large"
              ref={this.formRef}
              onValidSubmit={this.handleSubmit}
            >
              <Segment>
                <Form.Input
                  name="resetPassword"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="User name"
                  key="resetPassword"
                  value={this.state.user}
                  onChange={(e) => { this.setState({ user: e.target.value }) }}
                  required
                  type=''
                  validationErrors={{ isDefaultRequiredValue: 'please fill in your user name' }}
                  errorLabel={errorLabel}
                />
                <Form.Input
                  label={this.state.keyQuestion ? this.state.keyQuestion : "Your username is wrong. Please go back and try again."}
                  name="keyQuestion"
                  fluid
                  placeholder=""
                  type=""
                  key="keyQuestion"
                  value={this.state.password}
                  onChange={(e) => { this.setState({ keyAnswer: e.target.value }) }}
                  required
                  validationErrors={{ isDefaultRequiredValue: 'please fill in your answer' }}
                  errorLabel={errorLabel}
                />
                <Form.Group>
                  <Form.Button content="Send" color="green" />
                  <Form.Button
                    type="button"
                    content="Reset"
                    onClick={() => this.formRef.current?.reset()}
                  />
                </Form.Group>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
        <ToastContainer />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordPage)
