const query = `
match (node:System:Workflow)
where id(node) = toInteger($key)

optional match (node)-[:runs|calls|gets]->(action:System:Workflow:Action)
optional match (node)-[:with]->(rule:System:Workflow:Rule)
optional match (action)-[:from]->(relationType:System:Schema:RelationType)
optional match (action)-[:from]->(nodeType:System:Schema:NodeType)

optional match path=(nodeType_start:System:Schema:NodeType)<-[:extends*0..]-(nodeType)
where not (:System:Schema:NodeType)<-[:extends]-(nodeType_start)

with distinct
  node,
  action,
  rule,
  {
    id: id(relationType),
    name: relationType.name,
    cypher: case when action.direction = 'out' then '-[relation:' + relationType.name + ']->' else '<-[relation:' + relationType.name + ']-' end,
    apoc: case when action.direction = 'out' then relationType.name + '>' else '<' + relationType.name end,
    direction: action.direction
  } as relationType,
  {
    name: nodeType.name,
    labels: apoc.text.join([] + [node in nodes(path) | node.name], ':')
  } as nodeType

with
  node,
  action,
  rule,
  relationType,
  nodeType,
  case node.type
    when 'count' then 'match (source) where id(source) = ' + toInteger($id) + ' optional match (source)' + relationType.cypher + '(node:' + nodeType.labels + ') ' + coalesce(action.filter, '') + ' with count(node) as count return {count: count} as response'
    when 'context' then 'return {source: {id: ' + case when $id is null then 'null' else toInteger($id) end + '}, relation: {type: "' + coalesce(relationType.name, "undefined") + '", direction: "' + coalesce(relationType.direction, "out") + '"}, target: {type: "' + nodeType.name + '", labels: ":' + nodeType.labels + '", filter: "' + coalesce(action.filter, '') + '"}} as response'
    when 'unfiltered' then 'return {source: {id: null}, relation: {type: "' + coalesce(relationType.name, "undefined") + '", direction: "' + coalesce(relationType.direction, "out") + '"}, target: {type: "' + nodeType.name + '", labels: ":' + nodeType.labels + '", filter: "' + coalesce(action.filter, '') + '"}} as response'
    else action.cypher
  end as query

with
  node,
  case
    when any(label in labels(action) where label = 'Parameter')
    then action{
      id: id(action),
      type: 'Parameter',
      query: query,
      params: {
          id: toInteger($id)
        }
    }
    when any(label in labels(action) where label = 'Query')
    then action{
      id: id(action),
      type: 'Query',
      query: query,
      params: {
          id: toInteger($id)
        }
    }
    when any(label in labels(action) where label = 'Service')
    then action{
      id: id(action),
      type: 'Service',
      url: action.url,
      method: coalesce(toUpper(toString(action.method)), 'GET'),
      params: case when action.params is null then null else apoc.convert.fromJsonMap(action.params) end
    }    
    else null
  end as action,
  coalesce(
    rule.cypher,
    'return "next" as case'
  ) as cypher,
  case apoc.meta.type($params)
    // when 'LIST' then apoc.map.mergeList($params)
    when 'MAP' then $params
    else {params: $params}
  end as params,
  {
    id: id(rule)
  } as rule

call apoc.cypher.run(cypher, params) yield value

with
  node,
  action,
  cypher,
  params,
  value,
  rule{.id, cypher: cypher, params: params, value: value.case}

optional match (node)-[raises:raises]->(event:System:Workflow:Event)
optional match (node)-[uses:uses]->(layout:System:UserInterface:Layout)

with distinct
  layout{
    target: coalesce(uses.target, 'self')
  },
  action,
  value,
  rule,
  case when event.type is null then node.type else event.type end as type,
  case when event.refresh is null then apoc.convert.toBoolean(node.refresh) else apoc.convert.toBoolean(event.refresh) end as refresh,
  {key: id(event), case: coalesce(raises.case, 'next')} as event
where event.case = value.case
 
with
  layout,
  action,
  rule,
  {
    key: case when event.key is null then toInteger($key) else event.key end,
    case: case when event.key is null then 'end' else event.case end
  } as next,
  true in [type, refresh] as breadcrumb
  
return
  layout,
  action,
  rule,
  next,
  breadcrumb
`;
 
export default query;
