import { BREADCRUMB_PENDING, GET_BREADCRUMBITEM_ERROR, BREADCRUMB_INIT, BREADCRUMB_ADD_PAGE, BREADCRUMB_GO_BACK, BREADCRUMB_UPDATE_PAGES, BREADCRUMB_CLEAR } from './types'
import initialState from './initialState'

let shiftHelper = []

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BREADCRUMB_PENDING:
      return {
        ...state,
        pending: true,
      }

    case GET_BREADCRUMBITEM_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
      }

    case BREADCRUMB_INIT:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        historyPages: action.data,
        currentPage: action.data[action.data.length - 1]
      }


    case BREADCRUMB_UPDATE_PAGES:
      return {
        //...state,
        pending: false,
        success: true,
        error: false,
        shiftHelper: shiftHelper,
        historyPages: action.historyPagesData,
        currentPage: action.currentPageData
      }

    case BREADCRUMB_ADD_PAGE:

      if (state.historyPages.length >= 10) {
        shiftHelper.unshift(state.historyPages.shift());
      }

      return {
        //...state,
        pending: false,
        success: true,
        error: false,
        shiftHelper: shiftHelper,
        historyPages: [...state.historyPages, action.data],
        currentPage: action.data
      }

    case BREADCRUMB_GO_BACK:

      let newHistory = state.historyPages.splice(0, state.historyPages.length - (state.historyPages.length - action.pageIndex - 1));
      let sectionIndex = state.historyPages.length - (state.historyPages.length - action.pageIndex - 1)

      if (state.historyPages.length <= 10) {
        let shiftBackArray = shiftHelper.splice(0, 10 - sectionIndex)
        for (let i = 0; i < shiftBackArray.length; i++) {
          newHistory.unshift(shiftBackArray[i])
        }
      }

      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        shiftHelper: shiftHelper,
        historyPages: newHistory,
        currentPage: newHistory[newHistory.length - 1]
      }

    case BREADCRUMB_CLEAR:
      return {
        pending: false,
        success: true,
        error: false,
        shiftHelper: shiftHelper,
        historyPages: [],
        currentPage: []
      }


    default:
      return state
  }
}

export default reducer
