import { service as decrypt } from '../../../services/api/tools/decrypt'
import neo4j, { Driver, Record, Result, Session } from "neo4j-driver";
import Store from './store.class'
import { logger } from '../../logger';

function Connection() {
  this.stores = this.getStores();
}

Connection.prototype.getStores = function () {
  let stores: any[] = Array.from(
    JSON.parse(
      decrypt(
        process.env.REACT_APP_LICENSE_TEXT,
        process.env.REACT_APP_LICENSE_KEY
      ).text
    )
  );
  return stores.map(
    (store: any) => {
      return new Store(
        store.name,
        store.host,
        store.port,
        store.database,
        store.user,
        store.password
      )
    }
  )
}

Connection.prototype.runQuery = async function (storeName: string = 'system', query: string, params: any) {
  let store = this.stores.find(store => store.name === storeName);
  let driver: Driver = neo4j.driver(
    `bolt://${store.host}:${store.port}`,
    neo4j.auth.basic(
      store.user,
      store.password
    ),
    {
      disableLosslessIntegers: true,
    }
  );
  let session: Session = driver.session({ database: store.database });
  try {
    let result: Result = session.run(query, params);
    let records: Record[] = (await result).records;
    let object = Object.fromEntries(
      new Map(
        records[0]?.keys?.map(
          (key: PropertyKey) => {
            return [key, records[0].get(key)]
          }
        )
      )
    )
    return object;
  } 
  catch(err) {
    console.log("Connection", err);
    logger.alertNeo4jErr(err)
  }
  finally {
    await session.close();
    await driver.close();
  }
}

export default Connection;