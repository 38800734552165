import React, { FC, useEffect, useState } from 'react'
import { Image } from "semantic-ui-react";

import '@syncfusion/ej2-layouts/styles/material.css';
import { serviceFilePreview } from '../../services/api/file/seviceFilePreview.api';
import notAvailablePic from '../../images/missing.png'

const ImageCard: FC<any> = ({ absolutePath, imageName }) => {
  const [imageBase64, setImageBase64] = useState(null);
  let isImage = ![null, undefined].includes(absolutePath) ? absolutePath.toLowerCase().split('.').pop().match(/^(png|jpg|jpeg|gif)$/) : false;

  useEffect(
    () => fetchBase64Image(),
    [absolutePath]
  );

  const fetchBase64Image = () => {
    if (isImage) {
      serviceFilePreview(absolutePath)
        .then((response: any) => response !== undefined ? setImageBase64('data:@file/' + (matchFileType(absolutePath)) + ';base64,' + response?.data) : setImageBase64(notAvailablePic))
        .catch((error: any) => {
        })
    }
  }

  return (
    <div>
      <div className="e-card" id="basic">
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-header-title"> {imageName}</div>
          </div>
        </div>
        <div className="e-card-content">
          {
            isImage
              ? (<Image src={imageBase64} size="large"></Image>)
              : <span className="e-icons e-image-2 image-not-found"></span>
          }
        </div>
      </div>
    </div>
  )

}

const matchFileType = (stringValue) => {
  let check_png = stringValue.toLowerCase().includes('.png');
  let check_jpg = stringValue.toLowerCase().includes('.jpg');
  let check_jpeg = stringValue.toLowerCase().includes('.jpeg');
  let check_gif = stringValue.toLowerCase().includes('.gif');
  switch (true) {
    case check_png:
      return 'png';
    case check_jpg:
      return 'jpg';
    case check_jpeg:
      return 'jpeg';
    case check_gif:
      return 'gif';
  }
}

export default ImageCard
