const initialState: any = {
    pending: false,
    success: false,
    error: false,
    data: {
        widgets: [],
        layout: {},
        oldActionsParams: {},
        oldWidgetViewType: null
    }
  }
  
  export default initialState
  