import React from 'react'
import './styles.css'

class NotFoundPage extends React.Component<any, any> {
  render() {
    return (
      <>NOT FOUND</>
    )
  }
}

export default NotFoundPage;