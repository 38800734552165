export const mouseoverNode = (cy: any) => {
  return cy.on('mouseover', 'node', async function (e) {
    const node = e.target;
    
    document.body.style.cursor = "pointer"

    node
    .union(node.incomers)
    .union(node.outgoers)
    .addClass('hover');
  });
}

export const mouseoverEdge = (cy: any) => {
  return cy.on('mouseover', 'edge', async function (e) {
    const edge = e.target;
    
    document.body.style.cursor = "pointer"

    edge
    .union(edge.incomers)
    .union(edge.outgoers)
    .addClass('hover');
  });
}

