import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { withRouterHooks } from '../../navigation/router.hooks';
import { getFormDataTemplate } from '../../services/api/Widgets/Form/getFormData.api';
import MenuForm from "../../components/MenuForm/MenuForm";
import CustomForm from './Form.list';
import './styles.css';
import { withTranslation } from 'react-i18next';
import { logger } from '../../services/logger';


class FormWidget extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      form: {},
      hidden: true,
      hiddenNew: true,
      newPassword: '',
      confirmPassword: '',
      previewBase64: null,

      formData_data: null,
      formData_pending: false,
      formData__success: false,
      formData_error: false,


      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,

      formOnSubmitFunc: null
    }
  }

  componentDidMount() {
    this.fetchFormDataData();
  }


  componentDidUpdate(prevProps) {
    const { navData } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      this.fetchFormDataData();
    }

  }

  componentWillUnmount() {
    this.setState({
      formData_data: null,
      formData_pending: false,
      formData__success: false,
      formData_error: false,

      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,
      formEdit_Files: null,

      formOnSubmitFunc: null
    })
  }


  fetchFormDataData = async () => {
    const { navData, oldActionsParams, oldWidgetViewType, runActionsState } = this.props;

    this.setState({ formData_pending: true })

    let newParams = { ...navData.params }
    newParams.source = { ...newParams.source, id: navData.widgetData.dataID }

    await getFormDataTemplate('system', process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, newParams, { ...oldActionsParams, data: runActionsState?.data?.params?.data ?? [] }, oldWidgetViewType)
      .then((response) => {
        logger.logWidget(navData, runActionsState, oldActionsParams, response, '#ff8800')

        this.initFormEditData()

        let newEditData = [];
        Object.keys(response.targets[0]).map(function (key, index) {
          navData.widgetData.sections.map((s) => {
            s.fieldSets.map((fs) => {
              fs.fields.map((field) => {
                if (field.attribute.name === key) {
                  let o = { _key: field.attribute.key ?? null }
                  o[key] = response.targets[0][key] ?? null;
                  newEditData.push(o)
                  return null
                }
                return null
              })
              return null
            })
            return null
          })
          return null
        });

        this.setState({ formData__success: true, formData_data: response.targets[0], formEdit_data: newEditData })
      })
      .catch((error: any) => this.setState({ formData__success: false, formData_data: null, formData_error: true }))
      .finally(() => this.setState({ formData_pending: false }))
  }

  initFormEditData = () => {
    this.setState({
      formEdit_data: [],
      formEdit_pending: false,
      formEdit_success: false,
      formEdit_error: false,
      formEdit_Files: null,
      formOnSubmitFunc: null
    })
  }

  setFormEditData = (item) => {
    const { formEdit_data } = this.state;
    var index = formEdit_data.findIndex(x => x._key === item._key);

    // TODO: find reason for missing entries inside formEdit_data
    if (index === -1) {
      formEdit_data.push(item);
    } else {
      formEdit_data[index] = item;
    }

    this.setState({ formEdit_data: formEdit_data });
  }

  setFormEditFile = (file) => {
    this.setState({ formEdit_Files: file })
  }

  setFormOnSubmitFunc = (func: any) => {
    this.setState({ formOnSubmitFunc: func })
  }

  handleOnSubmit = async (e) => {
    const { formOnSubmitFunc } = this.state;

    formOnSubmitFunc()
  }

  render() {
    const { navData, oldActionsParams, modals } = this.props
    const { formData__success, formData_data, formEdit_data, formEdit_Files } = this.state;

    if (!formData__success) {
      return null;
    }

    return (
      <Form
        id={`form-${navData.widgetData.key}`}
        onValidSubmit={this.handleOnSubmit}
      >
        {
          navData.widgetData.menu
            ? <MenuForm
              menu={navData.widgetData.menu}
              currentDataID={navData.widgetData.dataID}
              properties={formEdit_data}
              propertiesFiles={formEdit_Files}
              oldActionsParams={oldActionsParams}
              initFormEditData={this.initFormEditData}
              setFormOnSubmitFunc={this.setFormOnSubmitFunc}
              viewType={navData.widgetData.type}
              widgetType={navData.widgetData.widget}
            />
            : null
        }
        <h3 className={'widget-label'}> {navData.widgetData ? this.props.t(navData.widgetData.label) : ''}</h3>
        <div className="form-container">
          <Grid divided='vertically' className="form-content">
            <CustomForm
              renderData={navData.widgetData}
              data={formData_data}
              params={{key: navData.widgetData.key, id: navData.widgetData.dataID}}
              editData={formEdit_data}
              setFormEditData={this.setFormEditData}
              setFormEditFile={this.setFormEditFile}
              viewType={navData.widgetData.type}
            />
          </Grid>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = (state: any) => ({
  runActionsState: state.actions
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(withTranslation()(FormWidget)))