import store from "../../../store/store";
import { deepmerge } from "./helper";
import { get as getAction } from './get.api';
import { run as runAction } from './run.api';
// import { query as logNodesQuery } from "../log/nodes";
// import { query as logRelationsQuery } from "../log/relations";

export const loop = async (key: number, id: number, params: any, files: any = null) => {
  let layout = null;
  let action = null;
  let next = null;
  let breadcrumb: boolean = false;
  let source = deepmerge(store.getState().actions, params, 'source');
  let relation = deepmerge(store.getState().actions, params, 'relation');
  let target = deepmerge(store.getState().actions, params, 'target');
  params = deepmerge(store.getState().actions.params, params);
  let log: any = null;
  do {
    Promise.all([
      await getAction('system', key, id, params)
        .then((response: any) => {
          action = { params: {}, ...response.action };
          let rule = response !== undefined ? response.rule : null;
          if (rule.id !== null)
            console.log(`%c Rule (key: ${rule.id}) %c `, `background: #66ff66;`, 'background: transparent;', { query: rule.cypher, params: rule.params, response: rule.value });
          next = response !== undefined ? response.next : { key: key, case: 'end' };
          key = next['key'];
          layout = response.layout;
          breadcrumb = response.breadcrumb;
          params = deepmerge(params ?? {}, action.params ?? {});
          if (params.params)
            delete params.params;
        }),
      await runAction(action, params, files)
        .then((response: any) => {
          if (response !== undefined && response !== null && response.response !== undefined && response.response !== null)
            response = {
              ...response,
              ...response.response ?? {}
            };
          id = response?.id ?? id;
          log = deepmerge(log, response?.log ?? null);
          delete response?.log;
          if (response !== undefined) {
            source = deepmerge(source, response, 'source');
            relation = deepmerge(relation, response, 'relation');
            target = deepmerge(target, response, 'target');
            params = deepmerge(params, response);
          }
        })
    ]);
  } while (next.case !== 'end');

  params = {
    id: params.id,
    source: source,
    relation: relation,
    target: target,
    ...params
  };

  return {
    key: key,
    id: id,
    layout: layout,
    params: params,
    breadcrumb: breadcrumb,
    source: source,
    relation: relation,
    target: target,
    timestamp: Date.now(), //timestamp
    log: log
  };
}
