import * as React from 'react';
import { run as runQuery } from '../../../../../../../services/api/query/run.api';
import { query as getImagesQuery } from '../Queries/getImagesQuery';
import { query as getExistingImagesQuery } from '../Queries/getExistingImagesQuery';
import GridWidgetSync from '../../../../../../grid/Grid'
import { RowSelectEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-react-grids';


class ImageGrid extends React.Component<any, any>{

  constructor(props) {
    super(props);
    this.state = {
      gridData: null,
      gridRenderData: {
        columns: [
          { name: 'target.id', label: 'id', field: "target.id", hide: false, minWidth: 50 },
          { name: 'text', label: 'name', field: "text", hide: false, minWidth: 150 },
          { name: 'thumbnail', label: 'image', field: "thumbnail", hide: false, minWidth: 50 },
          { name: 'preview', label: 'preview', field: 'preview', hide: true }
        ],
        type: 'link'
      }
    }
  }

  selectionSettings: SelectionSettingsModel = {
    persistSelection: true,
    type: 'Single'
  };


  componentDidMount() {
    this.getData(this.props.tabType);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.tabType !== this.props.tabType) {
      this.getData(this.props.tabType);
    }
  }

  getData(tabType: any) {
    runQuery(
      'system',
      tabType === 'existing' ? getExistingImagesQuery({ search: '' }) : getImagesQuery({ search: '' }),
      this.props.params
    )
      .then((response) => {
        console.log(
          `%c RichtextDialog: Image grid (${tabType === 'existing' ? 'existing' : 'new'}) %c`,
          `background: #ff8800`,
          'background: transparent;',
          {
            query: tabType === 'existing' ? getExistingImagesQuery({ search: '' }) : getImagesQuery({ search: '' }),
            params: this.props.params,
            response: response.response
          }
        )
        const arr = Object.values(response.response.elements)
        const results = arr.filter(element => {
          return element !== null;
        });
        return this.setState({ gridData: results });

      });
  }


  rowSelected(args: RowSelectEventArgs) {
    this.props.$this.props.$this.setState({ selectedElement: args.data })
  }

  rowSelecting(args: any) {
    const instance: any = this
    instance.grid.clearSelection();
  }

  render() {
    const { gridRenderData, gridData } = this.state;

    if (!gridData) return null

    return (
      <GridWidgetSync
        $this={this}
        gridData={gridData}
        gridRenderData={gridRenderData}
        selectionSettings={this.selectionSettings}
        rowSelected={this.rowSelected}
        rowSelecting={this.rowSelecting}
        height={'550px'}
      />
    )
  }
};

export default ImageGrid