import { logger } from "../../../logger";
import Connection from "../../Database/connection";
import jwt from 'jsonwebtoken'

export const getFormDataTemplate = async (storeName: string, database: any, query: any, params: any, oldActionsParams: any, oldViewType: any) => {
  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);

    let parameters = {
      ...oldActionsParams,
      params: params,
      user: local_connection.user
    }

    switch (oldViewType) {
      case 'view':
        parameters.id = params.source.id !== null ? params.source.id : oldActionsParams.id;
        break;
      case 'edit':
        parameters.id = params.source.id;
        break;
      case 'create':
        //parameters.id = oldActionsParams.id !== null ? oldActionsParams.id : params.source.id;
        parameters.id = params.source.id;
        break;
      default:
        parameters.id = params.source.id;
        break;
    }
    let connection = new Connection()
    return connection.runQuery(storeName, query, parameters);
  } catch (error) {
    logger.error("API getFormDataTemplate error:  ", error)
  }
}