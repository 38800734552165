import { getGridData } from '../../../services/api/Widgets/Grid/getGridData.api';

const fetchGridDataData: any = (database: any, cypher: any, params: any, dataID: any, oldActionsParams: any) => {
  return getGridData('system', database, cypher, params, dataID, oldActionsParams)
    .then(
      (response) => {
        return response;
      }
    )
    .catch(
      (error: any) => console.log("Error fetchGridDataData", error)
    )

}

export default fetchGridDataData