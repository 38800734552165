export function query(params: any = {}) {
    return `
  match (translation:Apps:Section:Translation)
  where id(translation) = toInteger($id)
  
  match (image:Apps:Image)<-[:is]-(file:File)-[:hasType]-(:Type{code: 'thumbnail'})
  where image.name =~ '(?i).*?${ params.search }.*?'
    or image.ID =~ '(?i).*?${ params.search }.*?'
    or image.keywords =~ '(?i).*?${ params.search }.*?'
  
  match (translation)<-[:to]-(link:Apps:Link)-[:from]->(image)
    
  with
    collect(distinct {
      text: image.name,
      uuid: link.uuid,
      thumbnail: "<img style='max-height:30px' src='/api/services/file/download?absolutePath=" + file.absolutePath+ "'/>",
      image: '/api/services/link/from/image/view?type=' + $type + '&uuid=' + link.uuid,
      source: {id: id(translation), name: translation.name},
      target: {id: id(link), name: image.name}
    }) as elements
  
  return
    {
      elements: apoc.coll.sortMaps(elements, '^sequence')
    } as response
    `;
  };
  