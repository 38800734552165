const query = `
optional match (event:System:Workflow:Event)-[loads:loads]->(element:System:Workflow:Element)-[:with]->(widget:System:UserInterface:Widget)
where id(event) = toInteger($key)


optional match path=(nodeType_start:System:Schema:NodeType)-[:extends*0..]->(nodeType_end:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(widget))
  and size(nodes(path)) = size(labels(widget))
  and not (nodeType_end)-[:extends]->(:System:Schema:NodeType)

with
  event,
  loads,
  element,
  widget,
  nodeType_start as nodeType

optional match (nodeType)-[:hasStyle]->(style:Style)
optional match (style)-[:hasIcon]->(icon:Icon)
optional match (style)-[:hasColor{type: 'background'}]->(color:Color)

optional match (element)-[:hasLabel]->(label:System:UserInterface:Label)

with distinct
  apoc.coll.sortMaps(
    collect(distinct 
      {
        icon: {
          name: coalesce(icon.name, ''),
          color: coalesce(color.hex, '#000')
        },
        label: {
          name: coalesce(label.name, widget.name, labels(widget)[-1]),
          color: coalesce(color.hex, '#000')
        },
        sequence: apoc.convert.toInteger(loads.sequence),
        widget: widget
      }
    ),
    '^sequence'
  )[0] as object

  with
    object.icon as icon,
    object.label as label,
    object.widget as widget

optional match (widget)<-[:hasWidget]-(nodeType:System:Schema:NodeType)

with distinct
  icon,
  label,
  widget,
  case
    when any(
      label in labels(widget)
      where label in ['Graph', 'Gallery', 'View']
    )
    then {
      name: [label in labels(widget) where label in ['Graph', 'Gallery', 'View']][0]
    }
    else
    {
      name: nodeType.name
    }
    end as nodeType_map

optional match (:Schema:NodeType{name: nodeType_map.name})-[:hasStyle]->(style:Style)
optional match (style)-[:hasColor{type: 'background'}]->(color:Color)

optional match (node)
where id(node) = toInteger($id)
  and any(label in labels(widget) where label in ['Form', 'View'])

with distinct
  {
    icon: icon{
      .name,
      color: case when not nodeType_map.name in ['Graph', 'Gallery', 'View'] then coalesce(color.hex, '#000') else icon.color end
    },
    label: label{
      name: case when not nodeType_map.name in ['Graph', 'Gallery'] and not node is null then node.name else label.name end
    }
  } as response

return
  response
  `;
 
export default query;
