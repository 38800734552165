const query = `
with permissions, widget, widget_map, element, id, user
    
    with widget, widget_map, element, id, user
    where widget_map.widget = 'View'
        
    optional match (element)-[raises:raises]->(event:Event)
    where not raises.type in ["onLoad", "onClose"]
  
    optional match (widget)-[:hasStyle]->(style:Style)-[hasFont:hasFont]->(font:Font)
  
    with distinct
      widget{
        query: widget.cypher,
        type: coalesce(widget.type, 'text'),
        config: {
          style: {
            edge: {
              arrow: apoc.convert.toBoolean(widget.showRelationDirection),
              label: apoc.convert.toBoolean(widget.showRelationType)
            },
            font: {
              name: coalesce(font.name, "Arial"),
              size: coalesce(hasFont.size, "11pt")
            }
          }
        },
        events: collect(distinct event{
          key: id(event),
          type: raises.type
        }),
        query: widget.cypher
      } as widget_subquery
      
    return widget_subquery
`

export {query};