import { logger } from "../../logger";
import { call as callService } from "../services/call.api";
import { run as runQuery } from "../query/run.api";

export const run = async (action: any, params?: any, files: any = null, debug: boolean = true) => {
  var background = {
    Parameter: '#82E0AA',
    Query: '#87AADE',
    StoredProcedure: '#F9E79F'
  };

  if (localStorage.getItem('connection') === null || localStorage.getItem('connection') === undefined)
    return null;

  switch (action.type) {
    case 'Parameter':
    case 'Query':
    case 'StoredProcedure':
      return runQuery('system', action.query, params)
        .then((response: any) => {
          if (debug === true)
            console.log(`%c ${action.type} (key: ${action.id}) %c `, `background: ${background[action.type]};`, 'background: transparent;', { query: action.query, params: params, response: response });
          return response;
        })
        .catch((error: any) => logger.params(params).error(error))
    case 'Service':
      return callService(action.url, params, files)
        .then((response) => {
          if (debug === true)
            console.log(`%c Service (key: ${action.id}) %c `, 'background: #000000; color: #ffffff;', 'background: transparent; color: #000000;', { url: action.url, params: params, files: files, response: response });
          return response;
        })
        .catch((error: any) => logger.params(params).error(error))
  }
}
