import {
  GET_NAVIGATION_PENDING,
  GET_NAVIGATION_SUCCESS,
  GET_NAVIGATION_ERROR,
} from "./types";
import initialState from "./initialState";

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NAVIGATION_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
        data: null,
      };

    case GET_NAVIGATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data,
      };

    case GET_NAVIGATION_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
