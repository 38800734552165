import { GET_MENU_FORM_PENDING, GET_MENU_FORM_SUCCESS, GET_MENU_FORM_ERROR } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MENU_FORM_PENDING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
        MenuForms: null
      }

    case GET_MENU_FORM_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        MenuForms: action.data,
      }

    case GET_MENU_FORM_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
        MenuForms: null
      }

    default:
      return state
  }
}

export default reducer
