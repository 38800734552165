export const query = `
match (section:Apps:Section)
where id(section) = toInteger($id)

match (file:Apps:File)-[:attachedTo]->(diagram:Apps:Diagram)

optional match (diagram)-[:uses]->(template:Apps:Diagram:Template)
optional match (diagram)<-[:from]-(link:Apps:Link)-[:to]->(section)

with
  collect(distinct
    {
      id: id(diagram),
      name: diagram.name,
      template: template.name,
      image: "<img style='max-height:30px' src='/api/services/file/download?absolutePath=" + file.absolutePath + "'/>",
      preview: '/api/services/file/download?absolutePath=' + file.absolutePath,
      uuid: case when link is null then apoc.create.uuid() else link.uuid end,
      source: {id: id(diagram), name: diagram.name},
      target: {id: id(section), name: section.name}
    }
  ) as response

return
  response
  `;