const query = `
with permissions, widget, widget_map, element, id, user
  
  with widget, widget_map, element, id, user
  where widget_map.widget = 'Gallery'

  optional match (element)-[raises:raises]->(event:Event)
  where not raises.type in ["onLoad", "onClose"]

  with distinct
    widget{
      events: collect(distinct event{
        key: id(event),
        type: raises.type
      }),
      query: widget.cypher
    } as widget_subquery

  return widget_subquery
`

export {query};