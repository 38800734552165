const initialState: any = {
    pending: false,
    success: true,
    error: false,
    shiftHelper: [],
    historyPages: [],
    currentPage: null
  }
  
  export default initialState
  