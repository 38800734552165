function Store(
  name: string,
  host: string,
  port: string,
  database: string,
  user: string,
  password: string
) {
  this.name = name;
  this.host = host;
  this.port = port;
  this.database = database;
  this.user = user;
  this.password = password;

}

export default Store;