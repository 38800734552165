const initialState: any = {
  imageDialog: {
    isOpen: false,
    params: null
  },
  confirmModal: {
    isOpen: false,
  },
  title: null,
  content: null,
  imageEvent: null,
  linkEvent: null,
  eventType: null,
  selectedText: ''
}

export default initialState
