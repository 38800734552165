import React, { FC } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import FormField from "./Form.field";

// GLOBAL
const isLocked = (field) => {
  return (typeof (field) !== 'undefined' || field != null) ? field.locked : true;
}

const Form: FC<any> = ({ renderData, data, params, editData, setFormEditData, setFormEditFile, viewType }) => {
  if (!renderData)
    return null;

  return (
    <>
      <Grid className="Grid">
        {renderData.sections.map((section, i) => (
          <Grid.Row columns={section.fieldSets.length} key={"Grid.RowOuter" + i}>
            {section.fieldSets.map((set, i) => (
              <Grid.Column key={"Grid.Column" + i}>
                {set.fields.length === 0 ?
                  set.sections.map((section, i) => (
                    <Grid.Row columns={section.fieldSets.length} key={"Grid.RowOuter" + i}>
                      {section.fieldSets.map((set, i) => (
                        <Grid.Column key={"Grid.Column" + i}>
                          {set.fields.map((field, i) => (
                            <Grid.Row key={"Grid.RowOuter" + i} style={{ paddingLeft: field.label === "Imagetype" ? '25px' : null, float: 'left', width: field.label !== "Name" ? '50%' : '100%' }}>
                              {
                                <FormField
                                  field={field}
                                  fieldLocked={isLocked(field)}
                                  key={"FormField-" + field._key}
                                  data={data}
                                  params={params}
                                  editData={editData}
                                  setFormEditData={setFormEditData}
                                  setFormEditFile={setFormEditFile}
                                  viewType={viewType}
                                  renderData={renderData}
                                />
                              }
                            </Grid.Row>
                          ))}
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  )) :
                  set.fields.map((field, i) => (
                    <Grid.Row columns={1} key={"Grid.RowInner" + i}>
                      {
                        <FormField
                          field={field}
                          fieldLocked={isLocked(field)}
                          key={"FormField-" + field._key}
                          data={data}
                          params={params}
                          editData={editData}
                          setFormEditData={setFormEditData}
                          setFormEditFile={setFormEditFile}
                          viewType={viewType}
                          renderData={renderData}
                        />
                      }
                    </Grid.Row>
                  ))}
              </Grid.Column>
            ))}
            <Divider fitted className="Divider" />
          </Grid.Row>
        ))
        }</Grid>
    </>
  )
}

export default Form