import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const translationJSON = localStorage.translations;

const resources = {
  lng1: {
    translation: translationJSON ? JSON.parse(translationJSON) : null
  },
  lng2: {
    translation: translationJSON ? JSON.parse(translationJSON) : null
  }
};

// const language = localStorage?.user?.language;
// const resources = {};
// resources[language] = {translation: JSON.parse(localStorage.translations)};

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng"),
    fallbackLng: "lng1",
    detection: {
      order: ["localStorage", "i18nextLng"],
      lookupQuerystring: "i18nextLng",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"]
    },
    interpolation: {
      escapeValue: false
    },
    compatibilityJSON: "v1",
    react: { useSuspense: false }
  });

export default i18n;
