import {
    INIT_UPLOAD_FILE,
    SET_ACTIONS_UPLOAD_FILE,
    SET_PROGRESS_UPLOAD_FILE,
    UPLOAD_FILE_ERROR,
    UPLOAD_FILE_PENDING,
    UPLOAD_FILE_SUCCESS
} from './types'
import { fileServiceUpload as fileServiceUploadAPI } from '../../services/api/file/fileServiceUpload.api';
import { run as runQuery } from '../../services/api/query/run.api'
import { closeUploadModal } from '../uploadModal/action'
import store from '../store';
import { logger } from '../../services/logger';

const uploadFilePending = () => {
    return {
      type: UPLOAD_FILE_PENDING,
    }
}
  
const uploadFileReceived = (data: any) => {
    return {
      type: UPLOAD_FILE_SUCCESS,
      data
    }
}
  
const uploadFileError = () => {
    return {
      type: UPLOAD_FILE_ERROR
    }
}

export const setActionsUploadFile = (actions: any, serviceParams: any) => {
    return {
        type: SET_ACTIONS_UPLOAD_FILE,
        actions,
        serviceParams
      }
}

export const setProgressUploadFile = (progress: number) => {

    return {
        type: SET_PROGRESS_UPLOAD_FILE,
        progress
    }
}

export const initUploadFile = () => {
    return {
        type: INIT_UPLOAD_FILE
    }
}

  
export const uploadFile = (file: any) => {
  
    let pathToSaveFile = store.getState().uploadFile.actions[0].params.directory;
    let serviceToSaveFileInformation = store.getState().uploadFile.actions[1];
    let serviceParams = store.getState().uploadFile.serviceParams;


    return (dispatch) => {
        dispatch(uploadFilePending())
        
        fileServiceUploadAPI(file, pathToSaveFile)
          .then((resp) => {
            serviceParams = {
              ...serviceParams,
              properties: '{.*}',
              response: resp.data.data
            }

            dispatch(uploadFileReceived(resp.data.data))
            runQuery('system', serviceToSaveFileInformation.cypher, serviceParams)
            .then((res) => {
              setTimeout(() => dispatch(closeUploadModal()), 250);
              setTimeout(() => dispatch(initUploadFile()), 250);
            })
            .catch(() => {
              setTimeout(() => dispatch(uploadFileError()), 250);
            })
          })
          .catch((err: any) => {
            logger.params({file},{pathToSaveFile}).error("fileServiceUploadAPI error:", err)
            setTimeout(() => dispatch(uploadFileError()), 250);
          })
        
      }

}