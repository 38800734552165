import { LOGIN_PENDING, LOGIN_SUCCESS } from './types'
import { login } from '../../services/api/user/login'
import { notifyError } from '../../components/Toasts/toasts'
import { service as decrypt } from '../../services/api/tools/decrypt'
var jwt = require('jsonwebtoken')

const startLogin = () => {
  return {
    type: LOGIN_PENDING,
  }
}

const loginCompleted = (data: any) => {
  return {
    type: LOGIN_SUCCESS,
    user: data.data.user,
    translations: data.data.translations
  }
}

export const loginUser = (username: string, password: string, version: string) => {
  return (dispatch) => {
    dispatch(startLogin());
    // ToDo: implement license logic
    const license = JSON.parse(
      decrypt(
        process.env.REACT_APP_LICENSE_KEY,
        ''
      ).text
    );
    let expires = new Date(license.expires);

    //localStorage.setItem('stores', decrypt(process.env.REACT_APP_LICENSE_TEXT, process.env.REACT_APP_LICENSE_KEY).text);
    // let stores: any[] = Array.from(
    //   JSON.parse(
    //     decrypt(
    //       process.env.REACT_APP_LICENSE_TEXT,
    //       process.env.REACT_APP_LICENSE_KEY
    //     ).text
    //   )
    // );


    //let store: any = stores.find(obj => obj.name === 'data')
    // console.log(store);

    // let driver = neo4j.driver(
    //   `bolt://${store.host}:${store.port}`,
    //   neo4j.auth.basic(
    //     store.user,
    //     store.password
    //   ),
    //   {
    //     disableLosslessIntegers: true,
    //   }
    // );

    // return driver
    //   .session({ database: process.env.REACT_APP_PRIMARY_DATABASE_NAME })
    //   .run(`match (user:User{name: 'administrator'}) return id(user) as id`, null)
    //   .then(async (response) => {
    //     let obj: any = response.records[0]?.toObject();
    //     console.log(obj);
    //   });

    login(username, password, version).then(
      (res: any) => {
        localStorage.setItem('connection', JSON.stringify(res.data.data.connection));
        localStorage.setItem('translations', JSON.stringify(res.data.data.translations));

        dispatch(loginCompleted(res.data))
        window.location.href = "/app";
      }
    )
      .catch((error: any) => {
        if (error.response) {
          localStorage.removeItem('connection');
          notifyError(error.response.data.message)
        }
      })
  }
}