export function query(params: any = {}) {
    return `
  match (translation:Apps:Section:Translation)<-[:hasTranslation]-(source:Apps:Section)
  where id(translation) = toInteger($id)

  match (source)<-[:from]-(link:Apps:Link)-[:to]->(target:Apps:Section)
  where all(label in labels(target) where label in ['Apps', 'Section'])
    and not source = target
  
  with
    collect(distinct {
      uuid: link.uuid,
      text: target.name,
      source: {id: id(source), name: source.name},
      target: {id: id(link), name: target.name}
    }) as elements
  
  return
    {
      elements: apoc.coll.sortMaps(elements, '^text')
    } as response
    `;
  };