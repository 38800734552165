import React from 'react'
import { run as runActions } from '../../store/actions/run';
import { Menu, Label } from 'semantic-ui-react';
import jwt from 'jsonwebtoken';
import { checkConnection } from '../../services/api/driver';
import { useTranslation } from 'react-i18next';

const ContextItem = ({ context, currentDataID, timestamp }) => {
  const { t } = useTranslation();

  const handleIconClick = (element: any, e: any) => {
    let eventKey = element.events?.find(event => event?.type === 'onClick')?.key;
    let parameters = { id: currentDataID }

    if (!eventKey) return null

    if (e.ctrlKey === true) {
      const urlParam = {
        key: eventKey,
        id: currentDataID,
        params: parameters,
        label: null
      }
      const jwtParams = jwt.sign(urlParam, 'pdm');
      window.open(window.location.href + '/' + jwtParams);
      return null
    }
    runActions(eventKey, currentDataID, parameters)
  }

  const handleItemClick = (e: any) => {
    checkConnection(localStorage.getItem("connection"))
    let eventKey = context.events.find(event => event.type === 'onClick')?.key;
    let parameters = { id: currentDataID }

    if (!eventKey) {
      return null;
    }
    if (e.ctrlKey === true) {
      const urlParam = {
        key: eventKey,
        id: currentDataID,
        params: {},
        label: context.name
      }
      const jwtParams = jwt.sign(urlParam, 'pdm');
      window.open(window.location.href + '/' + jwtParams)
      return null
    }
    runActions(eventKey, currentDataID, parameters)
  }

  return (
    <Menu style={{ width: '100%' }}>
      <Menu.Item
        name={context.label}
        disabled={context.disabled}
        onClick={(e: any) => handleItemClick(e)}
        className={'menu-item-context'}
      >
        {context.icon.name !== '' ? <span className={`e-icons e-${context.icon.name} label-icon`} /> : null}
        {t(context.label)}
      </Menu.Item>
      <Menu.Item position="right">
        {context.menu ? context.menu.elements.map((element) => {
          return (
            <span
              key={`key-${element.id}-random:${Math.random()}`}
              className={`e-icons e-${element.icon.name} menu-item-icon ${context.disabled ? 'e-disabled' : ''}`}
              onClick={!context.disabled ? (e: any) => handleIconClick(element, e) : null}
              style={{ cursor: context.disabled ? "default" : "pointer" }}
            />
          )
        })
          : null}
        <Label style={{ float: 'right' }} className="menu-item-counter">
          {context.count}
        </Label>
      </Menu.Item>
    </Menu>
  );
}

export default ContextItem