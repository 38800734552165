import {
  SET_GLOBAL_KEY_EVENT
} from "./types";

export const setGlobalKeyEvent = (altKey: any, ctrlKey: any) => {
  return {
    type: SET_GLOBAL_KEY_EVENT,
    altKey,
    ctrlKey
  };
}
