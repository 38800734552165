import {
  GET_NAVIGATION_PENDING,
  GET_NAVIGATION_SUCCESS,
  GET_NAVIGATION_ERROR,
} from "./types";
import { getNavigation as getNavigation_API } from '../../services/api/Widgets/LeftSideMenu/Navigation/getNavigation.api'
import { logger } from "../../services/logger";

interface MyMessage {
  msg: string;
}

const getNavigationPending = () => {
  return {
    type: GET_NAVIGATION_PENDING,
  };
};

const getNavigationReceived = (data: any) => {
  return {
    type: GET_NAVIGATION_SUCCESS,
    data,
  };
};

const getNavigationError = (err: any) => {
  return {
    type: GET_NAVIGATION_ERROR,
  };
};

export const getNavigation = (key: number) => {

  return (dispatch) => {
    dispatch(getNavigationPending());
    getNavigation_API('system', Number(key))
      .then((resp) => {
        if(resp.navigation){
          dispatch(getNavigationReceived(resp.navigation))
        } else {
          dispatch(getNavigationError(null))
        }
      })
      .catch((err: any) => {
        logger.params({key}).error("getState error: ", err)
        dispatch(getNavigationError(err));
      });
  };
};
