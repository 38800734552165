import { OPEN_CONFIRM_MODAL, CLOSE_CONFIRM_MODAL, CLOSE_RICHTEXT_MODAL, OPEN_RICHTEXT_MODAL, SET_TEXT_SELECTION } from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TEXT_SELECTION:
      return {
        ...state,
        selectedText: action.selectedText
      }

    case OPEN_CONFIRM_MODAL:

      return {
        ...state,
        isConfirmModalOpen: true,
        title: action.title,
        content: action.content,
        okEvent: action.okEvent
      }

    case CLOSE_CONFIRM_MODAL:

      return {
        ...state,
        isConfirmModalOpen: false,
        title: null,
        content: null,
        okEvent: null
      }


    case OPEN_RICHTEXT_MODAL:

      return {
        ...state,
        imageDialog: {
          isOpen: true,
          params: action.params
        },
        title: action.title,
        content: action.content,
        imageEvent: action.imageEvent,
        linkEvent: action.linkEvent,
        diagramEvent: action.diagramEvent,
        eventType: action.eventType
      }

    case CLOSE_RICHTEXT_MODAL:
      return {
        ...state,
        imageDialog: {
          isOpen: false,
          params: null
        },
        title: null,
        content: null,
        imageEvent: null,
        linkEvent: null,
        eventType: null
      }

    default:
      return state
  }
}

export default reducer