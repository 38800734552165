import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Icon } from "semantic-ui-react";

const PasswordField: FC<any> = ({ field, value, isLocked, hiddenClass, setColorField }) => {
  const [isPasswordHidden, setIsPasswordHidden]: any = useState(true);
  const { t } = useTranslation();
  
  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <div
        className='form-input-element '
        // style={{ backgroundColor: setColorField() }}
      >
        <Input
          fluid
          name="password"
          value={value}
          icon={
            <Icon
              name='lock'
              onClick={() => setIsPasswordHidden(!isPasswordHidden)}
              inverted
              circular
              link
            />
          }
          type={isPasswordHidden ? 'password' : 'text'}
          style={{ height: '37px' }}
          disabled={isLocked}
        />
      </div>
    </div>
  )
}

export default PasswordField;