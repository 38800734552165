import React from 'react'
import { Grid, Header, Message, Segment } from 'semantic-ui-react';
import { Formsy } from 'formsy-react/dist/Formsy';
import { Form } from 'formsy-semantic-ui-react';
// import 'semantic-ui-css/semantic.min.css'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';
import { notifyError } from '../../components/Toasts/toasts';
import { setNewPassword } from '../../services/api/user/setNewPassword';

class SetNewPasswordPage extends React.Component<any, any> {
    constructor(props) {
        super(props)

        this.state = {
            passwordFirst: '',
            passwordSecond: ''
        }
    }

    private formRef = React.createRef<Formsy>();

    handleSubmit = () => {
        const { passwordFirst, passwordSecond } = this.state

        if (passwordSecond.length < 5) {
            notifyError("Your password must have at least 5 characters ")
        } else if (passwordFirst !== passwordSecond) {
            notifyError("Passwords do not match ")
        } else {
            setNewPassword(this.state.passwordSecond, localStorage.getItem("user"))
                .then((res) => {
                    if (!res.data.data.result) {
                        notifyError(" Something went wrong ")
                        return null
                    }
                    this.setState({
                        passwordFirst: '',
                        passwordSecond: ''
                    })
                    window.location.href = process.env.PUBLIC_URL + "login"
                }
                )
        }
    }


    render() {
        const logo = require('../../images/logo.png')

        return (
            <>
                <Grid textAlign="center" style={{ margin: '0px', padding: '0px', height: '100vh' }} verticalAlign="middle">
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <img src={logo} alt="Logo" height={150} width={200} />
                        <Header as="h1" textAlign="center" className="my-title">
                            Password reset
                        </Header>
                        <Message success>Please enter your new password</Message>
                        <Form
                            size="large"
                            ref={this.formRef}
                            onValidSubmit={this.handleSubmit}
                        >
                            <Segment>
                                <Form.Input
                                    name="newPasswordFirst"
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="new password"
                                    key="newPasswordFirst"
                                    value={this.state.passwordFirst}
                                    onChange={(e) => { this.setState({ passwordFirst: e.target.value }) }}
                                    required
                                    validationErrors={{ isDefaultRequiredValue: 'please fill in your password' }}
                                    type=''
                                />
                                <PasswordStrengthBar password={this.state.passwordFirst} />
                                <div style={{ marginTop: "20px" }}>
                                    <Form.Input
                                        name="newPasswordSecond"
                                        fluid
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder="confirm new password"
                                        type=""
                                        key="newPasswordSecond"
                                        value={this.state.passwordSecond}
                                        onChange={(e) => { this.setState({ passwordSecond: e.target.value }) }}
                                        required
                                        validationErrors={{ isDefaultRequiredValue: 'please fill in your password' }}
                                    />
                                </div>
                                <div style={{ marginTop: "25px" }}>
                                    <Form.Group>
                                        <Form.Button content="Confirm" color="green" />
                                        <Form.Button
                                            type="button"
                                            content="Reset"
                                            onClick={() => this.formRef.current?.reset()}
                                        />
                                    </Form.Group>
                                </div>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
                <ToastContainer />
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetNewPasswordPage)
