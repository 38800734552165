
/**
 * Custom logging class that shows additional debug information
 * Example of usage: ```
 * import Logger from "logger.tsx";
 * function foo(a = 1, b = 2) {
 *  const logger = new Logger();
 *  //Basic usage without parameters
 *  logger.log("Log example");  //logs: log example at Class.method (link)
 *  //With parameters
 *  Logger.params({a}, {b}).log("Log example") //logs: log example at Class.method (link) with parameters a: 1 b: 2
 * }
 * ```
 */
class Logger {
  private development: boolean;
  private showStack: boolean;
  private parameters: Array<Object>;
  private styles: Array<String>;

  constructor({ showStack } = { showStack: false }) {
    if (process.env.NODE_ENV === undefined) this.development = true;
    else this.development = process.env.NODE_ENV === "development";

    this.showStack = showStack;
    this.parameters = [];
    this.styles = [];
  }

  /**
   * Get any string of characters followed by ':'Numbers':'Numbers<EOL>
   * like: at Routes.render (routes.tsx:34)
   */
  private getLineInformation(e: Error) {
    try {
      if (e !== undefined && e.stack) {
        const regex = /\(?(.*):\d+:\d+\)?$/;
        const match = regex.exec(e.stack.split("\n")[2]);
        if (match && match.input) return match.input.trim();
        else return "";
      }
    } catch (error) {
      return "";
    }
  }

  private getParameters(): Array<any> {
    try {
      if (!this.parameters || this.parameters.length === 0) {
        return [];
      }
      let pairs: Array<any> = [];

      for (let param of this.parameters) {
        if (typeof param !== "object") param = { param };
        let key: any;
        let value: any;
        if (typeof param !== "string") {
          let tmp = Object.entries(param)[0];
          key = tmp[0];
          value = tmp[1];
          pairs.push(...[`[${key}:<${typeof value}>]:`, value]);
        } else {
          pairs.push(value);
        }
      }
      return ["with parent function parameters: ", ...pairs];
    } catch (error) {
      return [];
    }
  }

  private getStyles(): Array<any> {
    try {
      if (!this.styles || this.styles.length === 0) {
        return [];
      } else {
        return this.styles;
      }
    } catch (error) {
      return [];
    }
  }

  private getMessage(msg: any, otherMessages: any[], e: Error): Array<any> {
    if (this.showStack && e && e.stack)
      return [
        msg,
        ...this.getStyles(),
        otherMessages.join(" "),
        ...this.getParameters(),
        "\n" + e.stack.replace("Error\n", ""),
      ];
    else
      return [
        ...msg,
        ...this.getStyles(),
        ...otherMessages,
        this.getLineInformation(e),
        ...this.getParameters(),
      ];
  }

  private convert(message: any, messages: any[]): any {
    let msg = [];
    let msgs = [];
    if (!Array.isArray(message)) msg = [message];
    else msg = message;
    for (let m of messages) {
      if (Array.isArray(m)) {
        msgs.push(m);
      } else msgs.push([m]);
    }
    return [msg, msgs];
  }

  logWidget = (navData: any, runActionsState: any, oldActionsParams: any, response: any, color: any) => {
    console.log(
      `%c ${navData.widgetData.widget}.${navData.widgetData.type} (key: ${navData.widgetData.key}) %c`,
      `background: ${color}`,
      'background: transparent;',
      {
        widgetData: navData.widgetData,
        query: navData.query,
        params: {
          ...navData.params,
          ...runActionsState?.data?.params,
          ...oldActionsParams
        },
        response: response
      }
    )
  };

  alertNeo4jErr = (error: any) =>{
    if (process.env.NODE_ENV !== "production" && error.name === "Neo4jError") {
      console.error(error.message)
    }
  }

  /**
   * Setter for constructor parameter {showStack}
   * @param show Show stack trace? defaults to false
   */
  setShowStack(show: boolean) {
    this.showStack = show;
  }

  /**
   * Adds function parameters when calling log/warn/error
   * @param params callee function parameters, if passed as objects parameter name is preserved
   */
  params(...params: Array<Object>): Logger {
    this.parameters = params;
    return this;
  }

  /**
   * Adds function parameters when calling log/warn/error
   * @param css callee function styles, for formatting the log output with %c
   */
  css(...styles: Array<String>): Logger {
    this.styles = styles;
    return this;
  }
  /**
   * Wrapper around console.log, shown only in development, with aditional information like parent function name and line number
   * @param message to show in console
   * @param otherMessages to show in console
   */
  log(message: any = undefined, ...otherMessages: any[]) {
    if (!this.development) return;
    try {
      const e = new Error();
      let [m, ms] = this.convert(message, otherMessages);
      console.log(...this.getMessage(m, ms, e));
    } catch (err) {
      console.error(message, ...otherMessages);
    }
  }

  /**
   * Wrapper around console.warn, shown only in development, with aditional information like parent function name and line number
   * @param message to show in console
   * @param otherMessages to show in console
   */
  warn(message: any, ...otherMessages: any[]) {
    if (!this.development) return;
    try {
      const e = new Error();
      let [m, ms] = this.convert(message, otherMessages);
      console.warn(...this.getMessage(m, ms, e));
    } catch (err) {
      console.error(message, ...otherMessages);
    }
  }

  /**
   * Wrapper around console.error, always shown, with aditional information like parent function name and line number
   * @param message to show in console
   * @param otherMessages to show in console
   */
  error(message: any, ...otherMessages: any[]) {
    const e = new Error();
    try {
      let tmp = otherMessages.map((x) => (x.stack ? x.stack : x));
      let [m, ms] = this.convert(message, tmp);
      console.error(...this.getMessage(m, ms, e));
    } catch (err) {
      console.error(message, ...otherMessages);
    }
  }
}

export const logger = new Logger();

export default Logger;
