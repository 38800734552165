import {
  GET_STATE_PENDING,
  GET_STATE_SUCCESS,
  GET_STATE_ERROR,
  GET_STATE_REMOVE_MODAL_WIDGET
} from "./types";
import { getState } from '../../services/api/GetState/getState.api'
import store from "../store";
import { logger } from "../../services/logger";

const getStatePending = () => {
  return {
    type: GET_STATE_PENDING,
  };
};

const getStateReceived = (widgets: any, layout: any, oldActionsParams: any, oldWidgetViewType: any) => {
  return {
    type: GET_STATE_SUCCESS,
    widgets,
    layout,
    oldActionsParams,
    oldWidgetViewType
  };
};

export const getStateRemoveModalWidget = (key: number, id: string) => {
  return {
    type: GET_STATE_REMOVE_MODAL_WIDGET,
    key: key,
    id: id
  }
}

const getStateError = (err: any) => {
  return {
    type: GET_STATE_ERROR,
  };
};

export const fetchStateAction = (key: number, id: number, params: any, label: any, oldWidgetViewType: string, breadcrumb: any, relation: any, source: any, target: any) => {
  store.dispatch(getStatePending());

  logger.setShowStack(false);

  getState('system', key, id)
    .then(async (response: any) => {
      const widgets = response?.widgets
      const layout = response?.layout

      if (widgets !== undefined) {
        console.log(`%c refresh (key: ${key}) %c`, 'background: #800080; color: #ffffff;', 'background: transparent; color: #000000;', { id: id, params: params, response: { layout: layout, widgets: widgets } });

        const widgetAndLayoutResp = await loadLayoutAndWidgets(id, layout, widgets)

        store.dispatch(getStateReceived(widgetAndLayoutResp.newWidgets, widgetAndLayoutResp.layout, { relation: relation, source: source, target: target, params: params }, oldWidgetViewType));
      }
    })
    .catch((error: any) => {
      console.error('fetchStateAction error ', error);
      logger.alertNeo4jErr(error)
      store.dispatch(getStateError(error));
    })
}

const loadLayoutAndWidgets = (id: any, layout: any, widgets: any) => {
  /* widgets helper */
  let newWidgets = [];
  const oldWidgets: any = store.getState().getState.data.widgets;

  if (oldWidgets.length === 0 || layout) {
    newWidgets = widgets.map((widget) => {
      widget['dataID'] = id;
      return widget;
    })

  } else {

    widgets.map((newWidget) => {
      newWidgets = oldWidgets.filter((oldWidget) => {
        return !(newWidget.position.column === oldWidget.position.column && newWidget.position.row === oldWidget.position.row && newWidget.modal === oldWidget.modal)
      })
      return null
    })

    widgets.map((newWidget) => {
      newWidgets = oldWidgets.filter((oldNewWidget) => {
        return !(newWidget.position.column === oldNewWidget.position.column && newWidget.position.row === oldNewWidget.position.row && newWidget.modal === oldNewWidget.modal)
      })
      return null
    })

    let newModifiedWidgets = widgets.map((widget) => {
      widget['dataID'] = id;
      return widget;
    })

    newWidgets = [...newWidgets, ...newModifiedWidgets];
  }

  return { newWidgets, layout };
}
