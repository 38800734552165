import getActionQuery from "../GetStateAction/getStateActions.query";
import Connection from "../Database/connection";
import { logger } from "../../logger";
import jwt from "jsonwebtoken";
const merge = require('deepmerge');

export const get = async (storeName: string, key: number, id: number, params?: any) => {
  try {
    const local_connection = jwt.verify(JSON.parse(localStorage.getItem('connection')), process.env.REACT_APP_LICENSE_KEY);
    const parameters = { key: key, id: id, params: merge(params, { id: id }), user: local_connection.user };
    let connection = new Connection()
    return connection.runQuery(storeName, getActionQuery, parameters);
  } catch (error) {
    logger.error("API get error:  ", error)
  }
}