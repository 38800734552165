import { 
    GET_CONTEXT_MENU_PENDING,
    GET_CONTEXT_MENU_SUCCESS, 
    GET_CONTEXT_MENU_ERROR,
    INIT_GET_CONTEXT_MENU
} from './types'
import initialState from './initialState'

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
    case GET_CONTEXT_MENU_PENDING:
        return {
        ...state,
        pending: true,
        success: false,
        error: false,
        data: []
        }

    case GET_CONTEXT_MENU_SUCCESS:
        return {
        ...state,
        pending: false,
        success: true,
        error: false,
        data: action.data,
        }

    case GET_CONTEXT_MENU_ERROR:
        return {
        ...state,
        pending: false,
        success: false,
        error: true,
        data: []
        }

    case INIT_GET_CONTEXT_MENU:
        return {
        ...state,
        pending: false,
        success: false,
        error: false,
        data: []
        }

    default:
        return state
    }
}

export default reducer
