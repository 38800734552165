import { Record } from 'neo4j-driver';
import { getBreadcrumbItem, } from '../../services/api/breadcrumb/getBreadcrumbItem.api'
import store from '../store';
import { /* BREADCRUMB_PENDING, BREADCRUMB__ERROR, */ BREADCRUMB_INIT, BREADCRUMB_ADD_PAGE, BREADCRUMB_GO_BACK, GET_BREADCRUMBITEM_ERROR, BREADCRUMB_UPDATE_PAGES, BREADCRUMB_CLEAR } from './types';

export const initBreadcrumbAction = (moduleLabel, subMenuLabel, pageLabel, pageKey, pageDataID, pageParams, config) => {
  console.log(`%c Breadcrumb (key: ${pageDataID}) %c`, 'background: #634C2A; color: white;', 'background: transparent; color: black;', {params: pageParams, response: {source: null, relation: null, target: null, config: config}});
  let data = []

  // if (moduleLabel) {
  //   data.push({
  //     label: moduleLabel,
  //     key: null,
  //     dataID: null,
  //     params: null,
  //     config: null,
  //     type: 'module'
  //   })
  // }

  // if (subMenuLabel) {
  //   data.push({
  //     label: subMenuLabel,
  //     key: null,
  //     dataID: null,
  //     params: null,
  //     config: null,
  //     type: 'subMenu'
  //   })
  // }

  // data.push({
  //   label: pageLabel,
  //   key: pageKey,
  //   dataID: pageDataID,
  //   params: pageParams,
  //   config: null,
  //   type: 'subMenu'
  // })

  return {
    type: BREADCRUMB_INIT,
    data: data
  }
}

export const addPageBreadcrumbAction = (pageLabel, pageKey, pageDataID, pageParams, source, relation, target, config) => {
  return {
    type: BREADCRUMB_ADD_PAGE,
    data: {
      label: pageLabel,
      key: pageKey,
      dataID: pageDataID,
      params: pageParams,
      source: source,
      relation: relation,
      target: target,
      config: config,
      type: 'page'
    },
  }
}

export const setBreadcrumbItems = async (key: any, id: any, params: any, source: any, relation: any, target: any) => {
  getBreadcrumbItem('system', key, id)
    .then(async (object) => {
      let config = object.response;
      console.log(`%c Breadcrumb (key: ${id}) %c`, 'background: #634C2A; color: white;', 'background: transparent; color: black;', {params: params, response: {source: source, relation: relation, target: target, config: config}});
      store.dispatch(addPageBreadcrumbAction(config?.label.name, key, id, params, source, relation, target, config))
    })
    .catch((error: any) => {
      console.error('getBreadcrumbError ', error);
      store.dispatch(getBreadcrumbError(error));
    })
}

export const updateBreadcrumbItems = async (ids: any) => {
  let historyPagesData = store.getState().breadcrumb.historyPages.filter(x => !ids.includes(x.dataID));
  let currentPageData = historyPagesData[historyPagesData.length - 1];

  return {
    type: BREADCRUMB_UPDATE_PAGES,
    historyPagesData: historyPagesData,
    currentPageData: currentPageData
  }
}

export const goBackBreadcrumbAction = (pageIndex: number) => {
  return {
    type: BREADCRUMB_GO_BACK,
    pageIndex: pageIndex
  }
}

export const clearBreadcrumb = () => {
  return {
    type: BREADCRUMB_CLEAR,
    historyPagesData: [],
    currentPageData: []
  }
}


const getBreadcrumbError = (err: any) => {
  return {
    type: GET_BREADCRUMBITEM_ERROR,
  };
};
