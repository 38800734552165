export function query(params: any = {}) {
  return `
match (translation:Apps:Section:Translation)<-[:hasTranslation]-(source:Apps:Section)
where id(translation) = toInteger($id)

match (target:Apps:Section)
where not source = target
  and all(label in labels(target) where label in ['Apps', 'Section'])

optional match (source)<-[:from]-(link:Apps:Link)-[:to]->(target)

with
  collect(distinct {
    uuid: case when link is null then apoc.create.uuid() else link.uuid end,
    text: target.name,
    source: {id: id(source), name: source.name},
    target: {id: id(target), name: target.name}
  }) as elements

return
  {
    elements: apoc.coll.sortMaps(elements, '^text')
  } as response
  `;
};