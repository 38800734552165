const query = `
match (user:User)
where user.uuid = $user.uuid

match (user)-[:plays]->(:System:AccessControl:Role)-[:includes*0..]->(:System:AccessControl:Role)-[hasAccessTo:hasAccessTo]->(module:System:Module)

optional match (module)-[raises:raises{type: 'onLoad'}]->(event:System:Workflow:Event)-[:loads]->(:System:Workflow:Element)

with distinct
module,
  collect(distinct event{
    key: id(event),
    type: raises.type
  }) as events,
  apoc.coll.sort(collect(distinct coalesce(toInteger(hasAccessTo.sequence), 100)))[0] as sequence

optional match (module)-[:hasLabel]->(label:Label)
optional match (label)-[:withName]->(term:Apps:Term)
optional match (label_placeholder:System:UserInterface:Label)-[:withName]->(term_placeholder:Apps:Term)
where label_placeholder.name = 'select module'

with distinct
  coalesce(case when not term_placeholder is null then label_placeholder.ID else label_placeholder.name end, "") as placeholder,
  apoc.coll.sortMaps(
    collect(distinct module{
      key: id(module),
      value: id(module),
      type: coalesce(module.type, 'consumer'),
      label: coalesce(case when not term is null then label.ID else label.name end, module.name),
      name: module.name,
      sequence: sequence,
      events: events
    }),
    '^sequence'
  ) as modules


// ToDo: implement corporate design and licence

return {
  modules: modules,
  placeholder: placeholder
} as response
`;

export default query;