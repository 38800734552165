import React from 'react'
import { connect } from 'react-redux'
import { ContextMenu, Edit, Filter, GanttComponent, Inject, Resize, RowDD, Selection, Sort, SplitterSettingsModel, TimelineSettingsModel, Toolbar } from '@syncfusion/ej2-react-gantt';
import data from './services/data';
import MenuForm from '../../components/MenuForm/MenuForm';
import { run as runActions } from '../../store/actions/run';
import { withTranslation } from 'react-i18next';
import './styles.css'
import { logger } from '../../services/logger';


class GanttWidget extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      ganttData_Data: null
    }
  }
  ganttInstance: GanttComponent | null;
  projectStartDate: Date
  projectEndDate: Date
  toolbar: any = ['ZoomIn', 'ZoomOut', 'ZoomToFit'];
  labelSettings = {
    taskLabel: '${progress}%'
  };
  splitterSettings: SplitterSettingsModel = {
    position: "40%",
    separatorSize: 5,
    view: 'Default'
  };
  timelineSettings: TimelineSettingsModel = {
    timelineViewMode: 'Week',
    weekStartDay: 3
  };
  contextMenuItems: any = [
    this.props.navData.widgetData.menu?.context?.elements.map(
      (o) => {
        return {
          text: this.props.t(o.label),
          target: '.e-content',
          id: o.events.find(event => event.type === 'onClick')?.key.toString()
        }
      }
    )
  ];

  componentDidMount() {
    const { navData, oldActionsParams, runActionsState } = this.props;
    data(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.widgetData.query, { ...navData.params, ...runActionsState?.data?.params, ...oldActionsParams, id: navData.widgetData.dataID })
      .then((response) => {
        this.setState({ ganttData_Data: response });
        logger.logWidget(navData, null, null, response, '#f7f414')
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { navData, oldActionsParams, runActionsState } = this.props;

    if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
      data(this, process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.widgetData.query, { ...navData.params, ...runActionsState?.data?.params, ...oldActionsParams, id: navData.widgetData.dataID })
        .then((response) => {
          this.setState({ ganttData_Data: response });
          logger.logWidget(navData, null, null, response, '#f7f414')
        })
    }
  }

  getCurrentGanttData = () => {
    const ganttEditData = this.ganttInstance ? this.ganttInstance.currentViewData.map((element: any) => {
      return {
        id: element.id,
        // dependency: element.dependency,
        duration: element.ganttProperties.duration,
        durationUnit: element.ganttProperties.durationUnit,
        startDate: element.startDate.toISOString(),
        endDate: element.endDate.toISOString(),
        fixed: element.taskData.fixed,
        name: element.name,
        progress: element.progress,
        sequence: element.taskData.sequence,
        predecessors: element.ganttProperties.predecessor
      }
    }) : null
    return ganttEditData
  }

  taskBarSelect(args: any) {
    const { navData } = this.props
    const eventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null)
  }

  // taskbarTemplate(props): any {
  //     return (
  //         <div className="e-gantt-child-taskbar gantt-task" style={{ height: "100%", borderRadius: "5px" }}>
  //             <span className="e-task-label">{props.taskData.name}</span>
  //         </div>
  //     )
  // }

  milestoneTemplate(props) {
    return (
      <div className="e-gantt-milestone" style={{ position: "absolute", marginTop: "-7px", marginLeft: "-7px" }}>
        <div className="e-milestone-top"></div>
        <div className="e-milestone-bottom"></div>
      </div>
    );
  }

  actionBegin = (args: any) => {
    if (args.data?.taskData?.fixed) {
      args.cancel = true
    }
  }

  contextMenuClick(args: any) {
    const eventKey = args.item.properties.id
    const itemID = args.rowData.id
    runActions(eventKey, itemID, { ...this.props.oldActionsParams })
  }

  contextMenuOpen(args: any) {
    let record = args.rowData;
    // if (!record.hasChildRecords) {
    //     args.hideItems.push('Hide Column');
    // }
  }

  dataBound() {
    this.ganttInstance.fitToProject();
  }

  render() {
    const { navData, oldActionsParams } = this.props
    const { ganttData_Data } = this.state;

    if (!ganttData_Data) return null;

    return (
      <div className='widget-container'>
        {
          navData.widgetData.menu
            ? <MenuForm
              key={`menu-${navData.widgetData.menu.id}`}
              menu={navData.widgetData.menu}
              currentDataID={navData.widgetData.dataID}
              oldActionsParams={oldActionsParams}
              getWidgetEditData={this.getCurrentGanttData.bind(this)}
              viewType={"edit"}
              widgetType={navData.widgetData.widget}
            />
            : null
        }
        <h3 className='widget-label'>{this.props.t(navData.label)} </h3>
        <div className="widget-content" id="syncfusionGanttView">
          <GanttComponent
            id='TaskbarTemplate'
            ref={gantt => this.ganttInstance = gantt}
            height='100%'
            dateFormat={'yyyy-MM-dd HH:mm'}
            highlightWeekends={true}
            // workWeek={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]}
            includeWeekend={true}
            dayWorkingTime={[{ from: 0, to: 23 }]}
            dataSource={ganttData_Data}
            taskFields={navData.widgetData.taskFields}
            labelSettings={this.labelSettings}
            timelineSettings={this.timelineSettings}
            editSettings={navData.widgetData.editOptions}
            gridLines='Both'
            splitterSettings={this.splitterSettings}
            toolbar={this.toolbar}
            allowSelection={true}
            allowSorting={true}
            allowFiltering={true}
            allowResizing={true}
            enableContextMenu={true}
            allowRowDragAndDrop={true}
            //taskbarTemplate={this.taskbarTemplate.bind(this)}
            onTaskbarClick={this.taskBarSelect.bind(this)}
            milestoneTemplate={this.milestoneTemplate.bind(this)}
            dataBound={this.dataBound.bind(this)}
            actionBegin={this.actionBegin.bind(this)}
            contextMenuClick={this.contextMenuClick.bind(this)}
            contextMenuOpen={this.contextMenuOpen.bind(this)}
            contextMenuItems={this.contextMenuItems[0]}
          // taskMode='Auto' // important for calculating durations
          >
            <Inject services={[Selection, Toolbar, Filter, Sort, Resize, Edit, ContextMenu, RowDD]} />
          </GanttComponent>
        </div>
      </div >
    )
  }
}


const mapStateToProps = (state: any) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()((GanttWidget)));
