import { logger } from "../../../logger";
import { run as runAction } from '../../action/run.api';
import { query as getStyleQuery } from '../../../queries/getStyleQuery';

export const getGraphData = async (database: any, query: any, params: any, id: any) => {
  try {
    return Promise.resolve()
      .then(() => {
        return runAction({ type: 'Query', query: query }, { id: id, data: params.data, params: params }, null, false);
      })
      .then((response) => {
        return runAction({ type: 'StoredProcedure', id: 'get style', query: getStyleQuery }, { ...response.response, id: id });
      })
      .catch((error: any) => logger.params({}).error("getGraphData error: ", error))
  } catch (error) {
    logger.error("API getGraphData error:   ", error)
  }
}