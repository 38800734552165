import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'

const SubMenuItem = ({ label }) => {
    return (
        <Breadcrumb.Section key={`key-${label}`}>
            {label}
            <Breadcrumb.Divider icon="right chevron" />
        </Breadcrumb.Section>
    )
}

export default SubMenuItem