import React from "react";
import GridWidgetSync from "./Grid";
import { withRouterHooks } from "../../navigation/router.hooks";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { Column, IEditCell } from "@syncfusion/ej2-react-grids";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import fetchGridDataData from "./services/fetchGridData";
import './styles.css'
import { logger } from "../../services/logger";

class GridWidgetUpdate extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            gridData: null,
            gridEditData: []
        }
    }

    editSettings: any = {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Dialog'
    };
    
    /*dropdown elements*/
    elem
    dropdownTempObj = []
    dropdownElement = {
        create: (args) => {
            let elem = document.createElement('input');
            elem.id = 'dropedit' + args.column.field;
            return elem;
        },
        destroy: () => {
            this.dropdownTempObj[0]?.ej2_instances[0]?.destroy();
            this.dropdownTempObj?.shift();
        },
        read: args => {
            var value = args.ej2_instances[0]?.value;
            this.dropdownTempObj?.push(args);
            return value;
        },
        write: (args) => {
            let dropDownData = null
            if (this.props.navData.widgetData.columns !== undefined) {
                this.props.navData.widgetData.columns.forEach(element => {
                    if (element.name === args.column.field && element.type === 'list') {
                        dropDownData = element.dropDownData
                    }
                });
            }
            let dropdownTempObj = new DropDownList({
                dataSource: dropDownData.sort(),
                fields: { text: args.column.field, value: args.column.field },
                value: args.rowData[args.column.field],
                floatLabelType: "Auto",
                popupHeight: "220px",
                placeholder: args.column.field.charAt(0).toUpperCase() + args.column.field.slice(1)
            });
            dropdownTempObj.appendTo(args.element);
        }
    };

    /* numeric cell*/
    numericCell: IEditCell = {
        params: { decimals: 1 }
    };

    /* boolean cell */
    booleanEditObject: CheckBoxComponent
    booleanEditCell: IEditCell = {
        create: (args: { rowData: object, column: Column }) => {
            this.elem = document.createElement('input');
            return this.elem;
        },
        destroy: () => {
            let element: any = this.elem
            let checkedElement = element.checked
            this.state.gridData.forEach(element => {
                if (checkedElement !== element.selected) {
                    element.selected = checkedElement
                    element.test_boolean = checkedElement
                }
            });
        },
        read: () => {
            let element: any = this.elem
            return element.checked;
        },
        write: (args: { rowData: object, column: Column }) => {
            const _args: any = args
            this.booleanEditObject = new CheckBoxComponent({
                label: _args.column.field,
                checked: _args.rowData.test_boolean
            });
            this.booleanEditObject.appendTo(this.elem);
        }
    };

    componentDidMount() {
        const { navData, oldActionsParams, runActionsState, $this } = this.props

        fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
            .then((response) => {
                this.setState({ gridData: response.targets })
                logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
            }
            );
    }

    componentDidUpdate(prevProps) {
        const { navData, oldActionsParams, runActionsState, $this } = this.props;

        if (prevProps.navData.renderID !== navData.renderID || prevProps.navData.widgetData.dataID !== navData.widgetData.dataID || prevProps.navData.widgetData.timestamp !== navData.widgetData.timestamp) {
            this.clearData()
            this.setState({ gridRenderData: navData.widgetData })
            fetchGridDataData(process.env.REACT_APP_PRIMARY_DATABASE_NAME, navData.query, { ...navData.params, ...runActionsState?.data?.params }, navData.widgetData.dataID, oldActionsParams)
                .then((response) => {
                    this.setState({ gridData: response.targets })
                    logger.logWidget(navData, runActionsState, oldActionsParams, response.targets, '#ff8800')
                })
        }
    }

    clearData() {
        this.setState({
            gridData: null,
            gridEditData: []
        })
    }

    getEditTypeCell = (column: any, batchEditCell: any, numericCell: any) => {
        if (column.editable === true) {
            switch (column.type) {
                case 'list':
                    return batchEditCell;
                case 'string':
                    return null;
                case 'textarea':
                    return null;
                case 'integer':
                    return numericCell;
                default:
                    return null;
            }
        } else {
            return null
        }
    }

    getEditType = (column: any) => {
        if (Array.from(column.dropDownData).length > 0) {
            return 'dropdownedit'
        } else {
            switch (column.type) {
                case 'integer':
                    return 'numericedit';
                case 'textarea':
                    return null;
                default:
                    return null;
            }
        }
    }

    getEditSelectable = (column: any) => {
        if (column.editable === true) return true
        return false
    }

    render() {
        const { navData, runActionsState, oldActionsParams } = this.props;

        return (
            <GridWidgetSync
                $this={this}
                navData={navData}
                runActionsState={runActionsState}
                oldActionsParams={oldActionsParams}
                editSettings={this.editSettings}
                // getBatchEditData={this.getBatchEditData}
                dropdownElement={this.dropdownElement}
                numericCell={this.numericCell}
                getEditTypeCell={this.getEditTypeCell}
                getEditType={this.getEditType}
                getEditSelectable={this.getEditSelectable}
                gridData={this.state.gridData}
                gridRenderData={navData.widgetData}
            />
        );
    }
}

export default withRouterHooks((GridWidgetUpdate))
