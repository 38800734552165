import React from 'react'
import { connect } from 'react-redux'
import { Button, Progress, Modal, Input } from 'semantic-ui-react'
import { closeUploadModal as closeUploadModalAction} from '../../store/uploadModal/action';
import { uploadFile as uploadFileAction } from '../../store/uploadFile/action';

class UploadModal extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
        selectFile: null
    }
  }

  render() {
    const { modal, uploadFile, closeUploadModal, postUploadFile } = this.props
    const { selectFile } = this.state;

    return (
        <Modal
            open={modal.isOpen}
            dimmer={'blurring'}
            size={'tiny'}
        >
            <Modal.Header>Datei hochladen</Modal.Header>
            <Modal.Content>
            <Modal.Description>
                {
                    uploadFile.pending || uploadFile.success || uploadFile.error
                    ? (
                        <Progress 
                            percent={uploadFile.progress}
                            indicating size='tiny'
                            error={uploadFile.error}
                            success={uploadFile.success}
                        />
                    )
                    :
                    null
                }
                <Input 
                    type='file' 
                    placeholder='Search...' 
                    onChange={(e) => this.setState({ selectFile: e.target.files[0] })} 
                    disabled={uploadFile.pending}
                />
            </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
            <Button 
                content="Schießen"
                color='red'
                labelPosition='right'
                icon='remove'
                onClick={closeUploadModal}
            />
            { uploadFile.pending
            ? (<Button positive loading>Loading</Button>) 
            :  (
                <Button
                    content="Hochladen"
                    labelPosition='right'
                    icon='upload'
                    positive
                    disabled={!selectFile}
                    onClick={() => postUploadFile(selectFile)}
                />
                )
            }
            </Modal.Actions>
        </Modal>
    )
  }
}


const mapStateToProps = (state: any) => ({
  modal: state.uploadModal,
  uploadFile: state.uploadFile
})

const mapDispatchToProps = (dispatch: any) => ({
  closeUploadModal: () => dispatch(closeUploadModalAction()),
  postUploadFile: (file: any) => dispatch(uploadFileAction(file))
})

export default connect( mapStateToProps, mapDispatchToProps)(UploadModal);
