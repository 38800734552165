import { run as runAction } from '../action/run.api';
import { query as logCreatedNodesQuery } from "./nodes/created.query";
import { query as logUpdatedNodesQuery } from "./nodes/updated.query";
import { query as logDeletedNodesQuery } from "./nodes/deleted.query";
import { query as logImportedNodesQuery } from "./nodes/imported.query";
import { query as logExportedNodesQuery } from "./nodes/exported.query";
import { query as logCreatedRelationsQuery } from "./relations/created.query";
import { query as logDeletedRelationsQuery } from "./relations/deleted.query";
import store from '../../../store/store';
import { updateBreadcrumbItems } from '../../../store/breadcrumb/action';

export const write = async (log: any = null) => {
  Promise.resolve(log)
    .then(() => {
      var objects = log?.nodes?.created ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes created', query: logCreatedNodesQuery }, { objects: objects });
      }
    })
    .then(() => {
      var objects = log?.nodes?.updated ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes updated', query: logUpdatedNodesQuery }, { objects: objects });
      }
    })
    .then(async() => {
      var objects = log?.nodes?.deleted ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        store.dispatch(await updateBreadcrumbItems(objects))
        runAction({ type: 'StoredProcedure', id: 'nodes deleted', query: logDeletedNodesQuery }, { objects: objects })
          .then((response) => {
            if (response !== undefined && response.records !== undefined) {
              let obj: any = response?.records[0]?.toObject();
              log.relations.deleted = { ...log.relations.deleted, ...obj?.log?.relations?.deleted ?? null };
            }
          });
      }
    })
    .then(() => {
      var objects = log?.nodes?.imported ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes imported', query: logImportedNodesQuery }, { objects: objects, module: log?.response?.module, version: log?.response?.version });
      }
    })
    .then(() => {
      var objects = log?.nodes?.exported ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes exported', query: logExportedNodesQuery }, { objects: objects, module: log?.response?.module, version: log?.response?.version });
      }
    })
    .then(() => {
      var objects = log?.relations?.created ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes linked', query: logCreatedRelationsQuery }, { objects: objects });
      }
    })
    .then(() => {
      var objects = log?.relations?.deleted ?? null;
      if ((Array.isArray(objects) && objects.length !== 0) || (!Array.isArray(objects) && objects !== null)) {
        runAction({ type: 'StoredProcedure', id: 'nodes unlinked', query: logDeletedRelationsQuery }, { objects: objects });
      }
    })
    .catch((err) => {
      console.error('writeLog', err);
    });
}
