const query = `
unwind [] + [
  object in apoc.coll.flatten([$nodes]) |
  case apoc.meta.type(object)
    when 'NODE' then {id: id(object), position: {x: coalesce(object.position.x, object.x, null), y: coalesce(object.position.y, object.y, null)}, classes: []}
    when 'MAP' then {id: toInteger(object.id), position: {x: coalesce(object.position.x, object.x, null), y: coalesce(object.position.y, object.y, null)}, classes: coalesce(object.classes, []), infoblock: object.infoblock}
    when 'FLOAT' then {id: toInteger(object), position: {x: null, y: null}, classes: []}
    when 'INTEGER' then {id: object, position: {x: null, y: null}, classes: []}
    else null
  end
] as node_map

optional match (node)
where id(node) = node_map.id

optional match path=(nodeType_start:System:Schema:NodeType)-[:extends*0..]->(nodeType_end:System:Schema:NodeType)
where all(nodeType in nodes(path) where nodeType.name in labels(node))
  and size(nodes(path)) = size(labels(node))
  and not (nodeType_end)-[:extends]->(:System:Schema:NodeType)

with
  node,
  node_map,
  nodeType_start as nodeType

optional match (nodeType)-[:hasStyle]->(style:Style)

optional match (style)-[hasShape:hasShape]->(shape:Shape)
optional match (style)-[:hasFont]->(font:Font)
optional match (style)-[:hasTitle]->(attribute:Schema:Attribute)
optional match (style)-[:hasColor{type: 'background'}]->(color_shape:Color)
optional match (style)-[:hasColor{type: 'border'}]->(color_border:Color)
optional match (style)-[:hasColor{type: 'font'}]->(color_font:Color)
optional match (style)-[:hasIcon]->(icon:Icon)
optional match (style)-[:hasColor{type: 'icon'}]->(color_icon:Color)

with
  collect(distinct node_map{
    group: 'nodes',
    position: node_map.position,
    data: {
      id: id(node),
      label: coalesce(node[coalesce(attribute.name, 'name')], nodeType.name),
      shape: coalesce(case when shape.name = 'circle' then 'ellipse' else shape.name end, 'round-rectangle'),
      backgroundColor: coalesce(color_shape.hex, '#fff'),
      width: coalesce(toInteger(hasShape.width), 100),
      height: coalesce(toInteger(hasShape.height), 100),
      borderColor: coalesce(color_border.hex, '#000'),
      borderWidth: coalesce(toInteger(style.borderWidth), 1),
      color: coalesce(color_font.hex, '#000'),
      classes: node_map.classes + case when id(node) = $id then ['selected'] else [] end,
      infoblock: node_map.infoblock
    }
  }) as nodes

unwind [] + [
  object in apoc.coll.flatten([$relations]) |
  case apoc.meta.type(object)
    when 'RELATIONSHIP' then {id: id(object), classes: []}
    when 'MAP' then {id: toInteger(object.id), classes: coalesce(object.classes, []), classes: []}
    when 'FLOAT' then {id: toInteger(object), classes: []}
    when 'INTEGER' then {id: object, classes: []}
    else null
  end
] as relation_map

optional match (source)-[relation]->(target)
where id(relation) = relation_map.id

with
  nodes,
  relation_map,
  case
    when source = startNode(relation)
    then source
    else target
  end as source,
  relation,
  case
    when target = endNode(relation)
    then target
    else source
  end as target

optional match (nodeType_source:Schema:NodeType)<-[:startsAt]-(relationType:System:Schema:Relationtype)-[:endsAt]->(nodeType_target:Schema:NodeType)
where nodeType_source.name in labels(source)
  and nodeType_target.name in labels(target)
  and relationType.name = type(relation)

optional match (relationType:Schema:NodeType)-[:hasStyle]->(style:Style)

optional match (style)-[:hasColor]->(color:Color)

with
  nodes,
  collect(distinct relation_map{
    group: 'edges',
    source: id(source),
    target: id(target),
    data: {
      id: id(relation),
      label: type(relation),
      source: id(source),
      target: id(target),
      lineColor: coalesce(color.hex, '#999'),
      targetArrowColor: coalesce(color.hex, '#999'),
      targetArrowShape: 'triangle',
      classes: relation_map.classes + case when id(relation) = $id then ['selected'] else [] end
    }
  }) as relations

  return
    {
      nodes: nodes,
      relations: relations,
      objects: nodes + relations
    } as response
`;

export { query };
