const merge = require('deepmerge');

const deepmerge = (first: any, second: any, property: string = null) => {
  let responseValue: any = null;
  if (property == null) {
    if (first !== null && first !== undefined)
      responseValue = first;
    if (second !== null && second !== undefined)
      responseValue = merge(responseValue, second);
  } else {
    if (first !== null && first !== undefined && first[property] !== null && first[property] !== undefined)
      responseValue = first[property];
    if (second !== null && second !== undefined && second[property] !== null && second[property] !== undefined)
      responseValue = merge(responseValue, second[property]);
  }
  return responseValue;
}

export { deepmerge };