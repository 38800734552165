const query = `
optional match (link:Apps:Link{uuid: $uuid})-[:to]->(target)

return
  {
    id: id(target),
    uuid: target.uuid
  } as response
  `;

export default query;