export function query(params: any = null) {
  return `
  match (language:System:UserInterface:Language)

  match (source)
  where id(source) = toInteger($source.id)

  match (target)
  where id(target) = toInteger($target.id)

  // optional match (source)<-[:hasTranslation]-(page:Apps:Section)

  with
    collect(distinct language.name) as language_names,
    // case
    //   when all(label in labels(target) where label in ['Apps', 'Section'])
    //   then page
    //   else source
    // end as source,
    source,
    target

  optional match path=(nodeType_start:System:Schema:NodeType)-[:extends*0..]->(nodeType_end:System:Schema:NodeType)
  where all(nodeType in nodes(path) where nodeType.name in [label in labels(source) where not label in language_names])
    and size(nodes(path)) = size([label in labels(source) where not label in language_names])
    and not (nodeType_end)-[:extends]->(:System:Schema:NodeType)

  with
    language_names,
    source,
    nodeType_start as nodeType_source,
    target

  match (target)
  where id(target) = toInteger($target.id)

  optional match path=(nodeType_start:System:Schema:NodeType)-[:extends*0..]->(nodeType_end:System:Schema:NodeType)
  where all(nodeType in nodes(path) where nodeType.name in [label in labels(target) where not label in language_names])
    and size(nodes(path)) = size([label in labels(target) where not label in language_names])
    and not (nodeType_end)-[:extends]->(:System:Schema:NodeType)

  with
    source,
    target,
    nodeType_source,
    nodeType_start as nodeType_target
  
  optional match (nodeType_source)<-[:endsAt]-(:System:Schema:RelationType{name: 'from'})-[:startsAt]->(nodeType_from:System:Schema:NodeType)<-[:startsAt]-(:System:Schema:RelationType{name: 'to'})-[:endsAt]->(nodeType_target)
  optional match (nodeType_source)<-[:endsAt]-(:System:Schema:RelationType{name: 'to'})-[:startsAt]->(nodeType_to:System:Schema:NodeType)<-[:startsAt]-(:System:Schema:RelationType{name: 'from'})-[:endsAt]->(nodeType_target)
  
  with distinct
    source,
    target,
    exists(
      (source)<-[:from|to]-(:Apps:Link{uuid: $uuid})-[:to|from]->(target)
    ) as exists,
    nodeType_source,
    nodeType_target,
    {
      source:
        case
          when not nodeType_from is null
            and not nodeType_from = nodeType_target
          then 'from'
          else 'to'
        end,
      target:
        case
          when not nodeType_from is null
            and not nodeType_from = nodeType_target
          then 'to'
          else 'from'
        end
    } as relationType_map,
    case
      when not nodeType_from is null
      then nodeType_from
      else nodeType_to
    end as nodeType_link
  
  optional match path=(nodeType_start:System:Schema:NodeType)<-[:extends*0..]-(nodeType_link)
  where not (nodeType_start)-[:extends]->(:System:Schema:NodeType)
  
  with distinct
    source,
    target,
    exists,
    nodeType_source,
    nodeType_target,
    relationType_map,
    {
      labels:
        [
          nodeType in apoc.coll.flatten(collect(distinct nodes(path))) |
          nodeType.name
        ],
      properties:
        {
          uuid: $uuid,
          name: $link.name,
          createdOn: datetime(),
          createdBy: $user.uuid
        }
    } as link_map
  
  call apoc.merge.node(link_map.labels, {uuid: link_map.properties.uuid}, link_map.properties, {})
  yield node as link
  
  call apoc.merge.relationship(link, relationType_map.source, {}, {}, source, {})
  yield rel as relation_source
  
  call apoc.merge.relationship(link, relationType_map.target, {}, {}, target, {})
  yield rel as relation_target
  
  with
    {
      uuid: link.uuid
    } as response,
    {
      nodes: {
        created: case when exists then [] else [id(link)] end
      },
      relations: {
        created: case when exists then [] else [id(relation_source), id(relation_target)] end
      }
    } as log
  
  return
    response,
    log
  `;
 };