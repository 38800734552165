import axios from "axios";
import { logger } from '../../../logger';

export const service = async (url: string, params: any) => {
  return axios.post(
    url,
    params,
    {
      headers: {
        'Content-Type': 'application/json'
      },
    }
  ).then(
    (response) => {
      return response.data.data
    }
  ).catch(
    (error) => {
      logger.params({ params }).error('failed to call service: file/metadata/get', error);
    }
  )
}